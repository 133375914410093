@charset 'utf-8';
.contents {
	// @include media-breakpoint-up(sm) {
	// }
	// @include media-breakpoint-up(md) {
	// }
	// @include media-breakpoint-up(lg) {
	// }
	// @include media-breakpoint-up(xl) {
	// }
	width: 100%;
	@include media-breakpoint-up(lg) {
		min-width: $content-inner;
		margin-left: auto;
		margin-right: auto;
	}
	main {
		padding: $block-gutter-vertical 0;
		@include safe-area-spacing-l-r;
	}
}
// ----------------------------------------------
// .header_category
// ----------------------------------------------
.header_category {
	padding: $grid-gutter-width $grid-gutter-width / 2;
	background-color: map-get($grays, '100');
	text-align: center;
	&__wrapper {
		@extend .inner;
		@include safe-area-spacing-l-r;
	}
	&__in {
	}
	&__en {
	}
	&__ttl {
	}
	@include media-breakpoint-up(md) {
	}
}
