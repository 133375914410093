// ---------------------------------------------
//  Tab-nav
// ---------------------------------------------
.tabnav {
	.nav-tabs {
		display: none;
	}
	@include media-breakpoint-up(md) {
		.nav-tabs {
			display: flex;
		}
	}
	.selecton {
		position: relative;
		z-index: 10;
		background-color: #fff;
		border: 1px map-get($grays,'500') solid;
		&::after {
			content: '\f078';
			font-family: 'FontAwesome';
			position: absolute;
			display: block;
			width: 0;
			height: 0;
			top: 50%;
			right: 10px;
			margin-top: -.5em;
			width: 1em;
			height: 1em;
			line-height: 1;
			z-index: -1;
			color: $color-black;
		}
		select {
			appearance: none;
			display: block;
			width: 100%;
			padding: 10px;
			background-color: transparent;
			border: 0;
			option {
				background-color: transparent;
			}
		}
		@include media-breakpoint-up(md) {
			display: none;
		}
	}
}
