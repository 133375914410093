// ---------------------------------------------
// ページ内ナビゲーション
// ---------------------------------------------
.pagelink {
	&_style1 {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin: 0;
		padding: 4rem 0 8rem;
		list-style: none;
		&-item {
			@include media-breakpoint-up(md) {
				flex: 1 0 0%;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			a {
				position: relative;
				display: block;
				padding: 1.6rem 4rem 1.6rem 2rem;
				background-color: $color-main;
				border: 2px solid transparent;
				border-radius: 2px;
				color: $color-white !important;
				font-size: 1.8rem;
				font-weight: bold;
				letter-spacing: normal;
				line-height: 1.5;
				white-space: nowrap;
				@include media-breakpoint-down(sm) {
					padding: 1.2rem 4rem 1.2rem 2rem;
				}
				&::after {
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translateY(-50%);
					transition: all .2s;
					content: "\f0ab";
					display: inline-block;
					color: inherit;
					font-size: 2.6rem;
					font-family: 'Line Awesome Free';
					font-weight: 900;
				}
				&.-border {
					background-color: $color-white;
					border: 2px solid $color-main;
					color: $color-main !important;
					&.active{
						background-color: $color-main;
						color: $color-white !important;
					}
				}
			}
		}
	}
	&_style2 {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		padding: 4rem 0 6rem;
		list-style: none;
		@include media-breakpoint-down(sm) {
			padding: 2rem 0 4rem;
		}
		&-item {
			@include media-breakpoint-up(md) {
				flex: 1 0 0%;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			a {
				position: relative;
				display: block;
				padding: 1.6rem 4rem 1.6rem 2rem;
				background-color: $color-white;
				border: 2px solid $color-main;
				border-radius: 2px;
				color: $color-main !important;
				font-size: 1.8rem;
				font-weight: bold;
				letter-spacing: normal;
				line-height: 1.5;
				text-align: center;
				white-space: nowrap;
				@include media-breakpoint-down(sm) {
					padding: 1.2rem 4rem 1.2rem 2rem;
				}
				&::after {
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translateY(-50%);
					transition: all .2s;
					content: "\f0ab";
					display: inline-block;
					color: inherit;
					font-size: 2.6rem;
					font-family: 'Line Awesome Free';
					font-weight: 900;
				}
			}
		}
	}
	&_style3 {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		margin: 0;
		padding: 4rem 0 8rem;
		list-style: none;
		box-sizing: border-box;
		&-item {
			width: calc(33.3% - 14px);
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
			a {
				position: relative;
				display: block;
				padding: 1.6rem 4rem 1.6rem 2rem;
				background-color: $color-main;
				border: 2px solid transparent;
				border-radius: 2px;
				color: $color-white !important;
				font-size: 1.8rem;
				font-weight: bold;
				letter-spacing: normal;
				line-height: 1.5;
				white-space: nowrap;
				@include media-breakpoint-down(sm) {
					padding: 1.2rem 4rem 1.2rem 2rem;
				}
				&::after {
					position: absolute;
					right: 1rem;
					top: 50%;
					transform: translateY(-50%);
					transition: all .2s;
					content: "\f0ab";
					display: inline-block;
					color: inherit;
					font-size: 2.6rem;
					font-family: 'Line Awesome Free';
					font-weight: 900;
				}
				&.-border {
					background-color: $color-white;
					border: 2px solid $color-main;
					color: $color-main !important;
					&.active{
						background-color: $color-main;
						color: $color-white !important;
					}
				}
			}
		}
	}
}