@charset 'utf-8';
// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }

.gfooter {
	padding: 10rem 0 5rem;
	@include media-breakpoint-down(sm) {
		padding: 0 0 6rem;
	}

	&__sns {
		display: flex;
		justify-content: center;
		margin-top: 6rem;
		padding: 0;
		list-style: none;
		&-item {
			margin: 0 1.5rem;
			a::after {
				content: none !important;
			}
		}
	}

	&__logo {
		width: 266px;
		margin: 3rem auto 0;
		img {
			width: 100%;
		}
	}

	&__copy {
		margin: 6rem 0 0;
		font-size: 1.4rem;
		line-height: 1.4;
		text-align: center;
	}
}

.fnav {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-column-gap: 60px;
	grid-row-gap: 60px;
	@include media-breakpoint-down(sm) {
		display: none;
	}
	&__category {
		margin-bottom: 2.5rem;
		padding-bottom: 1rem;
		line-height: 1.5;
		border-bottom: 1px solid $color-black-dark;
		&_en {
			display: block;
			color: $color-main;
			font-size: 1.8rem;
			font-family: $font-family-en;
			font-weight: 400;
		}
		&_ja {
			display: block;
			font-size: 2rem;
		}
	}
	&__list {
		padding: 0;
		list-style: none;
		&-item {
			margin-bottom: .5em;
			font-size: 1.8rem;
			line-height: 1.7;
		}
		&-link {
			position: relative;
			display: inline-block;
			padding-left: 2rem;
			&::before {
				position: absolute;
				left: 0;
				top: 2px;
				content: "\f105";
				display: inline-block;
				color: $color-main;
				font-size: 1.6rem;
				font-family: 'Line Awesome Free';
				font-weight: 900;
			}
		}
	}
}

.pagetop {
	margin: 0;
	@include media-breakpoint-down(sm) {
		display: block !important;
	}
	a {
		display: block;
		padding: 1rem;
		text-align: center;
		background-color: $color-main;
		color: #fff;
		font-size: 1.4rem;
		font-weight: bold;
		line-height: 1.4;
	}
	@include media-breakpoint-up(md) {
		position: fixed;
		bottom: 2rem;
		right: 3rem;
		z-index: 77;
		width: 65px;
		height: 65px;
		overflow: hidden;
		a {
			position: relative;
			color: $color-main;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			&::before {
				content: '\f106';
				font-family: 'Line Awesome Free';
				color: $color-main3;
				display: inline-block;
				width: 1em;
				height: 1em;
				position: absolute;
				top: 50%;
				left: 50%;
				margin-top: -.5em;
				margin-left: -.5em;
				font-size: 2.6rem;
				font-weight: 900;
				line-height: 1;
			}
		}
	}
}
