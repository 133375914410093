@charset 'utf-8';
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-down(sm) {
// }

/* 卒業生紹介・内定者の声 */
.learning {
	&__block{
		&-list{
			.slick-slide img{
				width: 100%;
				object-fit: cover;
				aspect-ratio: 332/186;
			}
		}
	}
	&__section {
		position: relative;
		z-index: 0;
		margin-bottom: 12rem;
		&::before {
			position: absolute;
			left: 50%;
			top: 156px;
			transform: translateX(-100%);
			z-index: -1;
			content: "";
			display: block;
			width: 50vw;
			height: calc(100% - 157px);
		}

		&-header {
			@include media-breakpoint-up(md) {
				display: flex;
			}
			@include media-breakpoint-down(sm) {
				margin: 0 -2rem;
			}
			&_image {
				margin: 0;
				@include media-breakpoint-up(md) {
					width: 50%;
				}
				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}
			&_content {
				padding: 2.5rem 2rem 3rem;
				color: $color-white;
				@include media-breakpoint-up(md) {
					width: 50%;
					padding: 2.5rem 3rem 3rem;
				}
			}
			&_heading {
				margin-bottom: 2rem;
				padding-bottom: 1.5rem;
				border-bottom: 1px solid $color-white;
			}
			&_title {
				margin-bottom: .3em;
				font-size: 2.6rem;
				font-weight: bold;
			}
			&_button {
				margin: 3rem 0 0;
				text-align: right;
			}
		}

		&-main {
			padding: 6rem;
			background: $color-white;
			@include media-breakpoint-down(sm) {
				padding: 4rem 2rem;
			}
		}

		&-block {
			position: relative;
			&:not(:last-child) {
				margin-bottom: 6rem;
			}
		}

		&-title {
			display: flex;
			align-items: flex-start;
			margin-bottom: 2.5rem;
			font-size: 2.6rem;
			font-weight: bold;
			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}
			& > span {
				display: inline-block;
				width: 38px;
				min-width: 38px;
				margin-right: 10px;
				line-height: 1;
				@include media-breakpoint-down(sm) {
					width: 30px;
					min-width: 30px;
				}
			}
		}

		&-button {
			margin-top: 1.5rem;
			text-align: right;
			@include media-breakpoint-up(md) {
				margin: 0;
				position: absolute;
				right: 0;
				top: -2px;
			}
		}

		&.-reverse {
			&::before {
				right: 50%;
				transform: translateX(0);
			}
			@include media-breakpoint-up(md) {
				.learning__section-header {
					flex-direction: row-reverse;
				}
				.learning__section-title {
					justify-content: flex-end;
					text-align: right;
				}
				.learning__section-button {
					left: 0;
					right: auto;
				}
			}
		}
		&.-law {
			&::before {
				background-color: $color-sub1;
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub1;
			}
		}
		&.-eco {
			&::before {
				background-color: $color-sub2;
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub2;
			}
		}
		&.-let {
			&::before {
				background-color: $color-sub3;
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub3;
			}
		}
		&.-sci {
			&::before {
				background-color: $color-sub4;
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub4;
			}
		}
		&.-iss {
			&::before {
				background-color: $color-sub5;
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub5;
			}
		}
		&.-icr {
			&::before {
				background-color: $color-sub6;
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub6;
			}
		}

	}
	.facultyselect{
		label {
			position: relative;
			display: block;
			width: auto;
			border: 2px solid $color-main;/* ボックスの境界線を実線で指定する */
			border-radius: 5px;
			background: #fff;
			&::before {
				position: absolute;
				content: "\f0ab";
				font-family: 'Line Awesome Free';
				font-weight: 900;
				font-size: 2.6rem;
				pointer-events: none;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
				color: $color-main;
				opacity: 1;
				}
				select {
					appearance: none;
					display: block; 
					width: 100%;
					padding: 10px 15px;
					border: none;
					border-radius: 5px;
					}
			}
			
	}
}

/* 学生がおすすめする授業 */
.recommend {
	&__section {
		position: relative;
		z-index: 0;
		margin-bottom: 12rem;
		&::before {
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(-100%);
			z-index: -1;
			content: "";
			display: block;
			width: 50vw;
			height: 100%;
		}

		&-inner {
			padding: 0 6rem;
			background: $color-white;
			@include media-breakpoint-down(sm) {
				padding: 0 2rem;
			}
		}

		&-title {
			margin-bottom: 8rem;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.3;
			text-align: center;
			@include media-breakpoint-down(sm) {
				margin-bottom: 6rem;
				font-size: 2.4rem;
			}
		}

		&-main {
			margin-bottom: 6rem;
			padding-bottom: 6rem;
			border-bottom: 1px solid $color-black;
		}

		&-container {
			&:not(:last-child) {
				margin-bottom: 10rem;
				@include media-breakpoint-down(sm) {
					margin-bottom: 8rem;
				}
			}
			@include media-breakpoint-up(md) {
				display: flex;
				margin: 0 -30px;
				&.-reverse {
					flex-direction: row-reverse;
				}
			}
		}

		&-image {
			margin-bottom: 3rem;
			@include media-breakpoint-up(md) {
				position: relative;
				width: 50%;
				margin: 0;
				padding: 0 30px;
			}
		}

		&-content {
			@include media-breakpoint-up(md) {
				width: 50%;
				padding: 0 30px;
			}
		}

		&-point {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			font-weight: bold;
			line-height: 1.4;
			text-align: center;
			@include media-breakpoint-up(md) {
				position: absolute;
				top: 0;
				left: 0;
				transform: translateY(-100%);
				width: 100%;
				margin: 0;
				padding-bottom: 1.2rem;
			}
			&::before, &::after {
				content: "";
				display: inline-block;
				width: 20px;
				height: 1px;
				background-color: $color-black;
			}
			&::before {
				transform: rotate(45deg) translateY(-5px);
				transform-origin: right center;
				margin-right: 10px;
			}
			&::after {
				transform: rotate(-45deg) translateY(-5px);
				transform-origin: left center;
				margin-left: 10px;
			}
		}

		&-heading {
			@include media-breakpoint-up(md) {
				display: flex;
				justify-content: space-between;
			}
			&_title {
				display: flex;
				align-items: flex-start;
				margin: 0;
				font-size: 2.2rem;
				font-weight: bold;
				& > span {
					display: inline-block;
					width: 24px;
					min-width: 24px;
					margin-right: 10px;
					padding-top: 2px;
					line-height: 1;
				}
			}
			&_name {
				margin: 0;
				font-weight: bold;
				@include media-breakpoint-down(sm) {
					margin-top: .5rem;
					padding-left: 36px;
				}
			}
		}

		&-message {
			margin-top: 2rem;
			padding: 2rem;
			border: 1px solid $color-gray;
			border-radius: 20px;
			@include media-breakpoint-up(md) {
				margin-top: 3rem;
				padding: 2.5rem 3rem;
			}
			&_name {
				margin: 1.5rem 0 0;
				padding-top: 1rem;
				border-top: 1px dotted $color-black;
			}
		}

		&.-reverse {
			&::before {
				right: 50%;
				transform: translateX(0);
			}
		}
		&.-law {
			&::before {
				background-color: $color-sub1;
			}
			.recommend__section-point {
				color: $color-sub1;
				&::before, &::after {
					background-color: $color-sub1;
				}
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub1;
			}
		}
		&.-eco {
			&::before {
				background-color: $color-sub2;
			}
			.recommend__section-point {
				color: $color-sub2;
				&::before, &::after {
					background-color: $color-sub2;
				}
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub2;
			}
		}
		&.-let {
			&::before {
				background-color: $color-sub3;
			}
			.recommend__section-point {
				color: $color-sub3;
				&::before, &::after {
					background-color: $color-sub3;
				}
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub3;
			}
		}
		&.-sci {
			&::before {
				background-color: $color-sub4;
			}
			.recommend__section-point {
				color: $color-sub4;
				&::before, &::after {
					background-color: $color-sub4;
				}
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub4;
			}
		}
		&.-iss {
			&::before {
				background-color: $color-sub5;
			}
			.recommend__section-point {
				color: $color-sub5;
				&::before, &::after {
					background-color: $color-sub5;
				}
			}
			.slick-prev:before, .slick-next:before,
			.card_style1-image::after {
				color: $color-sub5;
			}
		}
	}
}
