@charset 'utf-8';
// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }

.gheader {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 88;
	width: 100%;
	height: $height-header;
	padding: 2rem 3rem 0;
	background-color: transparent;
	@include media-breakpoint-down(sm) {
		position: relative;
		padding: 2rem 2rem 0;
		background-color: $color-white;
	}

	&__logo {
		width: 218px;
		margin: 0;
		@include media-breakpoint-down(sm) {
			width: 170px;
			max-width: calc(100% - 140px);
		}
	}

	&__hamburger {
		position: fixed;
		right: 30px;
		top: 20px;
		z-index: 99;
		display: block;
		margin: 0;
		width: 65px;
		height: 65px;
		@include media-breakpoint-down(sm) {
			right: 20px;
			top: 10px;
			width: 60px;
			height: 60px;
		}
	}

	&__request {
		position: fixed;
		right: 30px;
		top: 100px;
		z-index: 99;
		display: block;
		margin: 0;
		width: 65px;
		height: 65px;
		color: $color-main;
		@include media-breakpoint-down(sm) {
			right: 93px;
			top: 10px;
			width: 60px;
			height: 60px;
		}
		&-link {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			padding-top: 2px;
			background-color: $color-main3;
			border: 1px solid $color-white;
			border-radius: 50%;
			box-shadow: 5px 5px 6px 0 rgba(28, 69, 110, 0.25);
			font-size: 2rem;
			font-weight: bold;
			line-height: 1.2;
			@include media-breakpoint-down(sm) {
				font-size: 1.8rem;
			}
		}
	}
}

/* hamburger */
.hamburger {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;
	appearance: none;
	background-color: $color-main;
	border: 1px solid $color-white;
	border-radius: 50%;
	box-shadow: 5px 5px 6px 0 rgba(28, 69, 110, 0.25);
	outline: none;
	cursor: pointer;
	transition: all 0.4s;

	&__line {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 25px;
		height: 2px;
		background-color: $color-main3;
		border-radius: 1px;
		-webkit-transition: inherit;
		transition: inherit;
		@include media-breakpoint-down(sm) {
			width: 23.5px;
			height: 2px;
		}
		&::before, &::after {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			background-color: inherit;
			border-radius: inherit;
			content: "";
			-webkit-transition: inherit;
			transition: inherit;
		}
		&::before {
			top: -9px;
			@include media-breakpoint-down(sm) {
				top: -8px;
			}
		}
		&::after {
			top: 9px;
			@include media-breakpoint-down(sm) {
				top: 8px;
			}
		}
	}

	&__text {
		position: absolute;
		white-space: nowrap;
		width: 1px;
		height: 1px;
		overflow: hidden;
		border: 0;
		padding: 0;
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		margin: -1px;
	}

	&.-close .hamburger__line {
		background-color: transparent;
		&::before, &::after {
			top: 0;
			background-color: $color-main3;
		}
		&::before {
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
	}
}
