// ---------------------------------------------
//  buttons
// ---------------------------------------------

.btn_block {
  margin-top: 4rem;
  text-align: center;
}

.btn_default {
  position: relative;
  display: inline-block;
  padding: 1.6rem 4rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  font-size: 2rem;
  background-color: $color-main;
  border-radius: 4px;
  color: $color-white !important;
  @include media-breakpoint-up(md) {
    min-width: 420px;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  &::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    content: "\f138" !important;
    display: inline-block;
    color: inherit;
    font-size: 2.6rem;
    font-family: 'Line Awesome Free';
    font-weight: 900;
    @include media-breakpoint-down(sm) {
    }
  }
  &.-white {
    background-color: $color-white;
    color: $color-main !important;
  }
  &.-full {
    width: 100%;
  }
}

.btn_more {
  position: relative;
  display: inline-block;
  padding: .8rem 6rem .8rem 2rem;
  border: 1px solid $color-black;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4;
  &::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    content: "\f138" !important;
    display: inline-block;
    color: inherit;
    font-size: 2rem;
    font-family: 'Line Awesome Free';
    font-weight: 900;
  }
  &.-white {
    border-color: $color-white;
    color: $color-white !important;
  }
}