@charset 'utf-8';
// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }

.gnav {
	opacity: 0;
	visibility: hidden;
  transition: all 0.6s;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 88;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	height: 100%;
	padding: 16rem 0 9rem 4rem;
	background-color: $color-main-light2;
	@include media-breakpoint-down(sm) {
		padding: 14rem 0 5rem 3.4rem;
	}
	&.is-open {
		opacity: 1;
		visibility: visible;
	}
	&:not(.is-open) * {
		display: none;
	}

	&__inner {
		position: relative;
	}

	&__logo {
		position: absolute;
		left: 3rem;
		top: 2rem;
		z-index: 99;
		@include media-breakpoint-down(sm) {
			left: 2rem;
		}
	}

	&__list {
		position: relative;
		margin: 0;
		padding: 0;
		list-style: none;
		line-height: 1.75;
		&-item {
			margin-bottom: 4rem;
			@include media-breakpoint-down(sm) {
				position: relative;
				&::before, &::after {
					position: absolute;
					top: 11px;
					right: 2rem;
					content:'';
					display: inline-block;
					width: 15px;
					height: 2px;
					background-color: $color-main;
				}
				&::before{
					transform: rotate(0deg);
				}
				&::after {    
					transform: rotate(90deg);
				}
			}
			&.is-active {
				.gnav__list-title::after {
					opacity: 1;
					visibility: visible;
				}
				@include media-breakpoint-up(md) {
					.gnav__child {
						opacity: 1;
						visibility: visible;
					}
				}
				@include media-breakpoint-down(sm) {
					&::after {
						opacity: 0;
					}
					.gnav__child {
						opacity: 1;
						visibility: visible;
						height: auto;
						padding-top: 2rem;
					}
				}
			}
		}
		&-title {
			position: relative;
			appearance: none;
			display: inline-block;
			margin: 0;
			padding: 0;
			background-color: transparent;
			border: none;
			color: $color-main;
			font-size: 3rem;
			font-weight: bold;
			line-height: 1.2;
			text-align: left;
			@include media-breakpoint-down(sm) {
				display: block;
				width: 100%;
				font-size: 2rem;
			}
			&::after {
				opacity: 0;
				visibility: hidden;
				transition: all .2s linear;
				position: absolute;
				left: -10px;
				top: 0;
				transform: translateX(-100%);
				content: "\f138";
				display: inline-block;
				color: $color-main;
				font-size: 3rem;
				font-family: 'Line Awesome Free';
				font-weight: 900;
				line-height: 1.2;
				@include media-breakpoint-down(sm) {
					top: -3px;
					font-size: 2.4rem;
				}
			}
			&:hover {
				@include media-breakpoint-up(md) {
					text-decoration: underline;
				}
			}
		}
	}

	&__child {
		opacity: 0;
		visibility: hidden;
		transition: all .2s linear;
		@include media-breakpoint-up(md) {
			position: absolute;
			right: 0;
			top: 0;
			width: 45%;
			min-width: 500px;
		}
		@include media-breakpoint-down(sm) {
			overflow: hidden;
			height: 0;
			padding: 0 5.5rem 0 0;
		}
		&-image {
			margin-bottom: 4rem;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		&-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 0;
			padding: 0;
			list-style: none;
			line-height: 1.75;
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
		&-item {
			width: 45%;
			margin-bottom: 3rem;
			color: $color-main;
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 1.5;
			@include media-breakpoint-down(sm) {
				width: 100%;
				margin-bottom: 1.2rem;
				font-size: 1.6rem;
			}
		}
		&-link {
			position: relative;
			display: block;
			padding: 0 2rem 1rem 0;
			border-bottom: 1px dashed $color-main;
			&::after {
				position: absolute;
				right: 0;
				top: 0;
				content: "\f138";
				display: inline-block;
				color: inherit;
				font-size: 2rem;
				font-family: 'Line Awesome Free';
				font-weight: 900;
			}
		}
	}

	&__sub {
		margin-top: 6rem;
		padding: 0;
		list-style: none;
		&-item {
			margin-bottom: 2.5rem;
			font-size: 1.8rem;
			font-weight: bold;
			line-height: 1.2;
		}
	}

	&__sns {
		display: flex;
		margin-top: 4rem;
		padding: 0;
		list-style: none;
		&-item {
			margin: 0 3rem 0 0;
			a::after {
				content: none !important;
			}
		}
	}
}

body.nonscroll {
	@include media-breakpoint-down(sm) {
		height: 100%;
		overflow: hidden;
	}
}
