@charset 'utf-8';
// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }

a[class]{
  color: currentColor;
}
a{
  &:hover{
    text-decoration: none;
  }
}

hr{
	border: 1px dashed $color-main;
	margin: 20px 0;
}


.main {
	overflow: hidden;
}


// インナーコンテンツ幅
.inner {
	padding-left: 2rem;
	padding-right: 2rem;
	@include media-breakpoint-up(lg) {
		width: 100%;
		max-width: $width-inner;
		padding-left: 10vw;
		padding-right: 10vw;
	}
}

.section {
	padding-bottom: 12rem;
}

//パンクズ
.breadcrumb {
	margin: 0;
	padding: 4rem 0 6rem;
	font-size: 1.6rem;
	line-height: 1.5;
  &-item{
    &.active{
      color: $color-black;
    }
    a{
      color: $color-main;
    }
    & + .breadcrumb-item::before {
      content: '>';
			float: none;
			color: $color-black;
    }
  }
}

// 背景色
.bg {
	&__main {
		background-color: $color-main !important;
	}
	&__sub1 {
		background-color: $color-sub1 !important;
		&.active{
			background-color: $color-white !important;
			color: $color-sub1 !important;
			border: 2px solid $color-sub1 !important;;
		}
	}
	&__sub2 {
		background-color: $color-sub2 !important;
		&.active{
			background-color: $color-white !important;
			color: $color-sub2 !important;
			border: 2px solid $color-sub2 !important;;
		}
	}
	&__sub3 {
		background-color: $color-sub3 !important;
		&.active{
			background-color: $color-white !important;
			color: $color-sub3 !important;
			border: 2px solid $color-sub3 !important;;
		}
	}
	&__sub4 {
		background-color: $color-sub4 !important;
		&.active{
			background-color: $color-white !important;
			color: $color-sub4 !important;
			border: 2px solid $color-sub4 !important;;
		}
	}
	&__sub5 {
		background-color: $color-sub5 !important;
		&.active{
			background-color: $color-white !important;
			color: $color-sub5 !important;
			border: 2px solid $color-sub5 !important;;
		}
	}
	&__sub6 {
		background-color: $color-sub6 !important;
		&.active{
			background-color: $color-white !important;
			color: $color-sub6 !important;
			border: 2px solid $color-sub6 !important;;
		}
	}
	&__red {
		background-color: $color-red !important;
	}
	&__white {
		background-color: $color-white !important;
	}
	&__black {
		background-color: $color-black !important;
	}
}

// 区切り線
.hr_default {
	margin: 3.5rem 0;
	border-top: 1px solid $color-gray;
}


//MTパーツ
.mt-ttl_style{
	&1{
		color: $color-main;
		font-size: 2.8rem;
		font-weight: bold;
	}
	&2{
		font-size: 2.2rem;
		border-bottom: 1px solid $color-black;
		padding-bottom: 5px;
		font-weight: bold;
	}
	&3{
		font-weight: bold;
		border-bottom: 1px solid $color-main;
	}
	&-en{
		font-size: 2.4rem;
		font-family: $font-family-en;
		font-weight: bold;
	}
}

.box_style1{
	border: 1px solid $color-gray;
	padding: 30px;
}

.box_style2{
	border: 2px solid $color-main;
	padding: 30px;
	border-radius: 10px;
}

table{
	border-color: $color-gray;
	th,td{
		padding: 5px 10px;
	}
	th{
		color: $color-main;
		@include media-breakpoint-down(sm) {
			width: 30%!important;
		}
		p{
			margin-bottom: 0;
		}
	}
}


//カラム
.mt-be-columns{
	color: var(--dark-theme-white , $color-black);
	width: 100%;
	display: flex;
	gap: 60px;
	
	& + .mt-be-columns{
    margin-top: 40px !important;
    @include media-breakpoint-down(sm) {
      margin-top: 20px!important;
    }
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		gap: 0px;
	}

	&.sp-column-block{
		.mt-be-column{
			margin-top: 0px!important;
		}
	}

	.mt-be-column{
		width: 100%;
		& + .mt-be-column{
			@include media-breakpoint-down(sm) {
				margin-top: 20px;
			}
		}
		& + .box_style1,& + .box_style2{
			@include media-breakpoint-up(md) {
				height: 100%;
			}
		}
		// &:first-of-type{
		// 	.box_style2{
		// 		height: 100%;
		// 	}
		// }
	}
	
	&.sp-column-block{
		@include media-breakpoint-down(sm) {
			display: flex!important;
			flex-direction: row!important;
		}
	}


}

.row-custom{
	&.column-6-4{
	  .mt-be-column{
		&:nth-child(1){
		  flex: 0 0 60%;
		}
	  }
	}
	&.column-4-6{
	  .mt-be-column{
		&:nth-child(1){
		  flex: 0 0 40%;
		}
	  }
	}
	&.column-7-3{
		.mt-be-column{
		  &:nth-child(1){
			flex: 0 0 70%;
		  }
		}
	  }
	  &.column-3-7{
		.mt-be-column{
		  &:nth-child(1){
			flex: 0 0 30%;
		  }
		}
	  }
	&.reverse{
		@include media-breakpoint-down(sm) {
			flex-direction: column-reverse!important;
		}
	}
  }


  .fukidashi{
	position: relative;
	display: block;
	align-items: center;
	gap: 10px;
	margin: 10px auto;
	padding: 7px 10px;
	width: 100%;
	max-width: 350px;
	color: $color-main;
	font-size: 2rem;
	background: #FFF;
	border: solid 3px $color-main;
	box-sizing: border-box;
	font-weight: bold;
	position: relative;
	border-radius: 50px;
	&::before{
		content: "";
		position: absolute;
		bottom: -24px;
		left: 50%;
		margin-left: -15px;
		border: 12px solid transparent;
		border-top: 12px solid #FFF;
		z-index: 2;
	}
	&::after{
		content: "";
		position: absolute;
		bottom: -30px;
		left: 50%;
		margin-left: -17px;
		border: 14px solid transparent;
		border-top: 14px solid $color-main;
		z-index: 1;
	}
	&-icon{
		background-color: $color-main;
		color: $color-white;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 60px;
		height: 60px;
		border-radius: 100%;
		text-align: center;
		line-height: 1.2;
		font-size: 1.6rem;
		&.color{
			&-1{
				background-color: #003d87d1
			}
			&-2{
				background-color: #003d873e;
				color: $color-main;
			}
		}
	}
	&-text{
		position: absolute;
		width: calc(100% - 120px);
		top: 50%;
		left: 53%;
		transform: translate(-50%,-50%);
		text-align: center;
	}
  }

  .day_parts{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 10px;
	padding-left: 0;
	&-text{
		flex: 1;
		background: $color-main;
		color: $color-white;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		line-height: 1.2;
		width: 100%;
		height: 60px;
		border-radius: 10px;
		font-weight: 500;
		&.end{
			color: $color-white;
			background: $color-gray;
		}
	}

  }


//ボタン
  .btn_style{
	a{
		position: relative;
		display: block;
		padding: 1.6rem 4rem;
		font-weight: bold;
		line-height: 1.4;
		font-size: 2rem;
		background-color: $color-main;
		border-radius: 4px;
		color: $color-white !important;
		width: 100%;
		@include media-breakpoint-down(sm) {
			font-size: 1.8rem;
		}
		&::after {
		  position: absolute;
		  right: 1rem;
		  top: 50%;
		  transform: translateY(-50%);
		  transition: all .2s;
		  content: "\f138" !important;
		  display: inline-block;
		  color: inherit;
		  font-size: 2.6rem;
		  font-family: 'Line Awesome Free';
		  font-weight: 900;

		}
	}
	&.-half {
		a{
			width: 50%;
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	  }
	&.-white {
		a{
			background-color: $color-white;
			color: $color-main !important;
			border: 2px solid $color-main;
		}
	}
  }

  .btn_style_issg{
	a{
		border: 2px solid $color-sub6;
		border-radius: 5px;
		color: $color-sub6;
		display: block;
		margin: auto;
		max-width: 420px;
		position: relative;
		padding: 1rem 4rem 1rem 1rem;
		text-align: center;
		font-size: 2rem;
		font-weight: bold;
		&::after{
			content: "\f138";
			font-family: 'Line Awesome Free';
			font-weight: 900;
			line-height: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 50%;
			right: 1rem;
			transform: translate(0,-50%);
		}
	}
  }

//アコーディオン
summary {
	display: block;
	list-style: none;
  }
  
  /* 一部ブラウザで消えなかった場合は以下も追記 */
  summary::-webkit-details-marker {
	display:none;
  }
  
.accordion{
	border: 2px solid $color-main;;
	& + .accordion{
		border-top: none;
		border-bottom: 2px solid $color-main;
	}
	&__inner{
		border-top: 1px dashed $color-main;
		padding-top: 20px;
	}
	&__trigger{
		list-style: none;
		font-weight: bold;
		padding: 20px 50px 20px 20px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 2.2rem;
		position: relative;
		@include media-breakpoint-down(sm) {
			font-size: 1.8rem;
		}
	}
	&__target{
		padding: 0 20px 20px 20px;
	}
	&__icon{
		position: absolute;
		display: block;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		padding:2px;
		min-width: 30px;
		margin-left: 10px;
		right: 20px;
		&::before, &::after{
			display: flex;
			justify-content: center;
			align-items: center;
			content: "";
			position: absolute;
			background: $color-main;
			transition: all 0.25s ease-in-out;
			transform-origin: center center;
			top: 50%;
			left: 50%;
			width: 25px;
			height: 2px;
		}
		&::before {
			transform: translateX(-50%) rotate(90deg);
		}
		&::after {
			transform: translateX(-50%);
		}
	}

	&[open]{
		.accordion__trigger{
			color: $color-main;
			.accordion__icon{
				&::before, &::after {
					background: $color-main;
				}
				&::before {
					transform: translateX(-50%) rotate(135deg);
				}
				&::after {
					transform: translateX(-50%) rotate(45deg);
				}
			}
		}
	}


	&.faq{
		.accordion__trigger{
			&::before{
				content: "Q";
				background-color: $color-main;
				color: $color-white;
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 100%;
				margin-right: 10px;
			}
		}
		.accordion__inner{
			display: flex;
			align-items: flex-start;
			&::before{
				content: "A";
				background-color: $color-black;
				color: $color-white;
				flex: 0 0 40px;
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 100%;
				margin-right: 10px;
				font-size: 2.2rem;
				font-weight: bold;
			}
		}
	}

	hr{
		border: 1px solid $color-gray!important;
	}
}


.page.briefing{
	.accordion__wrap{
		.mt-be-column{
			& + .mt-be-column{
				@include media-breakpoint-down(sm) {
					margin-top: 0px!important;
				}
			}
		}
	}
}