@charset 'utf-8';
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-down(sm) {
// }



/* 学習院大学を知る */
.about {
	&__history {
		padding-bottom: 10rem;

		&-container {
			@include media-breakpoint-up(md) {
				display: flex;
				flex-direction: row-reverse;
				align-items: flex-start;
			}
		}
		&-image {
			position: relative;
			z-index: 0;
			margin-bottom: 3rem;
			@include media-breakpoint-up(md) {
				flex: 1 0 0%;
				margin: 0 0 0 -80px;
			}
		}
		&-content {
			position: relative;
			z-index: 1;
			background-color: $color-white;
			@include media-breakpoint-up(md) {
				width: 47%;
				padding: 3rem 4rem 3.5rem 0;
			}
		}
	}

	// color setting
	$color-kenkyu: #1567aa;
	$color-kenkyu-dark: #0c385d;
	$color-tougou: #76bab2;
	$color-tougou-dark: #30757a;
	

	.color__kenkyu{
		color: $color-kenkyu;
	}
	
	.color__tougou{
		color: $color-tougou;
	}
	
	.color__kenkyu-dark{
		color: $color-kenkyu-dark;
	}
	
	.color__tougou-dark{
		color: $color-tougou-dark;
	}

	&__features {
		padding: 7rem 0 0;
		background-color: $color-main-light;

		&-subtitle {
			margin-bottom: 3.5rem;
			font-size: 2.8rem;
			font-weight: bold;
			text-align: center;
			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}
			& > span {
				display: inline-block;
			}
			&_num {
				color: $color-main;
				font-size: 3.8rem;
				@include media-breakpoint-down(sm) {
					font-size: 2.8rem;
				}
			}
		}

		&-nav {
			&_inner {
				padding: 6rem 6rem 4rem;
				background-color: $color-white;
				@include media-breakpoint-down(sm) {
					padding: 4rem 2rem;
				}
			}
			&_list {
				padding: 0;
				list-style: none;
				@include media-breakpoint-up(md) {
					display: flex;
					justify-content: center;
				}
			}
			&_item {
				color: $color-white;
				&.-kenkyu {
					@include media-breakpoint-up(md) {
						margin-right: -30px;
					}
				}
				&.-tougou {
					margin-top: -20px;
					@include media-breakpoint-up(md) {
						margin-top: 0;
						margin-left: -30px;
					}
					.about__features-nav_link {
						background-image: linear-gradient(to bottom, $color-tougou, $color-tougou-dark);
					}
				}
			}
			&_title {
				font-size: 3.6rem;
				font-family: $font-family-serif;
				font-weight: 600;
			}
			&_link {
				display: block;
				opacity: .9;
				width: 340px;
				height: 340px;
				padding: 5rem 3rem;
				background-image: linear-gradient(to bottom, #4c84c4, #264262);
				border-radius: 50%;
				color: $color-white;
				line-height: 1.56;
				text-align: center;
				@include media-breakpoint-down(sm) {
					width: 295px;
					max-width: 100%;
					height: 295px;
					padding: 3.5rem 3rem;
				}
			}
			&_more {
				position: relative;
				margin: 3rem 0 0;
				font-size: 1.8rem;
				font-family: $font-family-en;
				font-weight: 900;
				&::after {
					content: "\f13a";
					display: inline-block;
					margin-left: 1rem;
					color: inherit;
					font-family: 'Line Awesome Free';
					font-weight: 900;
				}
			}
		}

		&-section {
			position: relative;
			z-index: 0;
			&::before {
				position: absolute;
				left: 50%;
				top: 0;
				transform: translateX(-100%);
				z-index: -1;
				content: "";
				display: block;
				width: 50vw;
				height: 100%;
			}
			&_inner {
				padding: 10rem 6rem;
				background: $color-white;
				@include media-breakpoint-down(sm) {
					padding: 4rem 2rem 10rem;
				}
			}
			&_header {
				margin-bottom: 4rem;
				@include media-breakpoint-up(md) {
					display: flex;
					align-items: flex-end;
					margin-bottom: 6rem;
				}
			}
			&_title {
				color: $color-kenkyu;
				font-size: 4.6rem;
				font-family: $font-family-serif;
				font-weight: 600;
				line-height: 1;
				@include media-breakpoint-up(md) {
					margin: 0 6rem 0 0;
					font-size: 6.4rem;
					writing-mode: vertical-rl;
				}
			}

			&_lead{
				color: $color-kenkyu;
			}

			&.-kenkyu {
				&::before {
					background-image: linear-gradient(to bottom, $color-kenkyu, $color-kenkyu-dark);
				}
				.btn_default {
					background-image: linear-gradient(98deg, $color-kenkyu, $color-kenkyu-dark);
					border: solid 1px rgba(255, 255, 255, 0.29);
				}
			}

			&.-tougou {
				&::before {
					right: 50%;
					transform: translateX(0);
					background-image: linear-gradient(to bottom, $color-tougou, $color-tougou-dark);
				}
				.about__features-section_header {
					flex-direction: row-reverse;
					@include media-breakpoint-up(md) {
						text-align: right;
					}
				}
				.about__features-section_title {
					color: $color-tougou;
					text-align: right;
					@include media-breakpoint-up(md) {
						margin: 0 0 0 6rem;
					}
				}
				.about__features-block_title {
					background-color: $color-tougou-dark;
				}
				.about__features-section_lead{
					color: $color-tougou-dark;
				}
				.card_style1-image::after {
					color: $color-tougou;
				}
				.slick-prev:before, .slick-next:before {
					color: $color-tougou;
				}
				.btn_default {
					background-image: linear-gradient(98deg, $color-tougou, $color-tougou-dark);
					border: solid 1px rgba(255, 255, 255, 0.29);
				}
			}
		}

		&-block {
			margin-top: 8rem;
			&_title {
				margin-bottom: 3.5rem;
				padding: 8px;
				background-color: $color-kenkyu-dark;
				color: $color-white;
				font-size: 2.2rem;
				line-height: 1.5;
				text-align: center;
				@include media-breakpoint-down(sm) {
					font-size: 1.8rem;
				}
			}
		}

		&-list {
			.card_style1-image::after {
				color: $color-kenkyu;
			}
			.slick-prev:before, .slick-next:before {
				color: $color-kenkyu;
			}
		}

		&-system {
			margin-top: 4rem;
			@include media-breakpoint-up(md) {
				display: flex;
			}
			&_item {
				position: relative;
				z-index: 0;
				@include media-breakpoint-up(md) {
					width: 50%;
				}
				&::before {
					position: absolute;
					left: 0;
					top: 0;
					z-index: -1;
					content: "";
					display: block;
					opacity: .2;
					width: 100%;
					height: 100%;
					@include media-breakpoint-up(md) {
						width: calc(100% + 3rem);
					}
				}
				&:nth-child(1) {
					padding: 0 2rem 12rem;
					@include media-breakpoint-up(md) {
						padding: 6rem 6rem 6rem 3rem;
					}
					&::before {
  					background-image: linear-gradient(to top, #6eb2ab, $color-white);
						border-radius: 0 0 200px 200px;
						@include media-breakpoint-up(md) {
							margin-right: -3rem;
							background-image: linear-gradient(to left, #6eb2ab, $color-white);
							border-radius: 0 400px 400px 0;
						}
					}
				}
				&:nth-child(2) {
					margin-top: -6rem;
					padding: 10rem 2rem 0;
					@include media-breakpoint-up(md) {
						margin: 0;
						padding: 6rem 2rem 6rem 7rem;
					}
					&::before {
  					background-image: linear-gradient(to bottom, #6eb2ab, $color-white);
						border-radius: 200px 200px 0 0;
						@include media-breakpoint-up(md) {
							margin-left: -3rem;
							background-image: linear-gradient(to right, #6eb2ab, $color-white);
							border-radius: 400px 0  0 400px;
						}
					}
				}
			}
			&_list {
				display: flex;
				flex-wrap: wrap;
				margin: 3rem -5px -5px;
				padding: 0;
				list-style: none;
				@include media-breakpoint-down(sm) {
					margin: 2rem 0 -5px;
				}
				li {
					margin: 5px;
					@include media-breakpoint-down(sm) {
						width: 100%;
						margin: 5px 0;
					}
					a {
						position: relative;
						display: block;
						padding: 10px 40px 10px 15px;
						color: $color-white;
						font-size: 1.6rem;
						line-height: 1.2;
						background-color: $color-tougou;
						&::after {
							position: absolute;
							right: 7px;
							top: 50%;
							transform: translateY(-50%);
							transition: all .2s;
							content: "\f138";
							display: inline-block;
							color: inherit;
							font-size: 2rem;
							font-family: 'Line Awesome Free';
							font-weight: 900;
							@include media-breakpoint-down(sm) {
							}
						}
					}
				}
			}
		}

		&-global {
			position: relative;
			z-index: 0;
			max-width: 295px;
			margin: 0 auto;
			&_image {
				margin: 0;
				img {
					width: 100%;
				}
			}
			&_content {
				position: absolute;
				left: 50%;
				top: 46%;
				transform: translateX(-50%);
				z-index: 1;
				width: 80%;
			}
			&_text {
				color: $color-tougou-dark;
				font-size: clamp(1.2rem, 1.25vw, 1.5rem);
				font-weight: bold;
				font-feature-settings: 'palt';
				line-height: 1.53;
				text-align: center;
				white-space: nowrap;
				@include media-breakpoint-down(lg) {
					font-size: clamp(1.2rem, 1vw, 1.4rem);
				}
				@include media-breakpoint-down(md) {
					font-size: clamp(1rem, 1.2vw, 1.3rem);
				}
				@include media-breakpoint-down(sm) {
					font-size: 4.27vw;
				}
				.-number {
					margin-right: .03em;
					font-size: 313%;
					font-family: $font-family-en;
					line-height: 1;
				}
				.-large {
					font-size: 127%;
				}
			}
		}
	}

	&__career {
		position: relative;
		z-index: 0;
		padding: 18rem 0 12rem;
		@include media-breakpoint-down(sm) {
			padding: 12rem 0 12rem;
		}
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			z-index: -1;
			content: "";
			display: block;
			width: 100%;
			height: 400px;
			background: url(../about/img/career_bg.jpg) no-repeat center center / cover;
		}
		
		&-inner {
			padding: 4rem 6rem 0;
			background-color: $color-white;
			@include media-breakpoint-down(sm) {
				padding: 4rem 2rem;
			}
		}

		&-title {
			margin-bottom: 3rem;
			position: relative;
			&_en {
				position: absolute;
				top: -3rem;
				transform: translateY(-100%);
				display: block;
				color: $color-white;
				font-size: 8rem;
				font-family: $font-family-en;
				font-weight: 700;
				line-height: 1;
				white-space: nowrap;
				@include media-breakpoint-down(sm) {
					left: -2rem;
					font-size: 6rem;
				}
			}
		}

		&-subtitle {
			margin-bottom: 1rem;
			font-size: 2.2rem;
			font-weight: bold;
		}

		&-number {
			color: $color-main;
			font-size: 10rem;
			font-family: $font-family-en;
			font-weight: 700;
			font-weight: bold;
			line-height: 1;
			small {
				font-size: 60%;
			}
		}

		&-notes {
			font-size: 1.4rem;
		}
	}

	&__campuslife {
		padding: 19rem 0;
		background: url(../about/img/campuslife_bg.jpg) no-repeat center center / cover;
		color: $color-white;
		@include media-breakpoint-down(sm) {
			padding: 17rem 0;
		}

		&-inner {
			max-width: 800px;
			margin: 0 auto;
		}

		&-title {
			margin-bottom: 3rem;
			color: $color-white;
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
			&_en {
				display: block;
				margin-bottom: -.3em;
				opacity: 0.4;
				font-size: 12rem;
				font-family: $font-family-en;
				font-weight: 700;
				line-height: 1;
				white-space: nowrap;
				@include media-breakpoint-down(sm) {
					font-size: 5.2rem;
				}
			}
		}
	}

}


/* 国際交流 */
.overseas{
	section{
		margin-bottom: 80px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__ryugaku{
		background: $color-main-light;
		padding: 60px 0;
		position: relative;
		margin-top: 50px;
		@include media-breakpoint-down(sm) {
			padding: 20px 0;
		}
		&::before{
			content: "";
			position: absolute;
			top: -30px;
			left: 50%;
			transform: translateX(-50%);
			border-style: solid;
			border-right: 30px solid transparent;
			border-left: 30px solid transparent;
			border-bottom: 40px solid $color-main-light;
			border-top: 0;
		}
		&-inner{
			background-color: $color-white;
			padding: 60px;
			margin-bottom: 60px;
			@include media-breakpoint-down(sm) {
				padding: 20px;
			}
		}
		&-title{
			text-align: center;
			span{
				background:linear-gradient(transparent 60%, $color-main-light 60%);
			}
		}
		&-btnbox{
			display: flex;
			justify-content: space-between;
			gap: 80px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
			.btn_block{
				width: 100%!important;
			}
			.btn_default{
				display: block;
				width: 100%!important;
				min-width: auto!important;
			}
		}
		&-box{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 80px;
			.item{
				flex: 1;
				@include media-breakpoint-down(sm) {
					margin-bottom: 30px;
				}
				.btn_block{
					width: 100%!important;
				}
				.btn_default{
					display: block;
					width: 100%!important;
					min-width: auto!important;
				}
			}
			&.--border{
				border-top: 1px solid $color-gray;
				margin-top: 60px;
				padding-top: 60px;
				@include media-breakpoint-down(sm) {
					margin-top: 50px;
					padding-top: 30px;
				}
			}
			&.--card{
				.item{
					flex: 0 0 calc(50% - 40px);
					padding: 20px;
					border-top: 5px solid $color-main;
					border-right: 1px solid $color-gray;
					border-left: 1px solid $color-gray;
					border-bottom: 1px solid $color-gray;
					background-color: $color-white;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
			}
			@include media-breakpoint-down(sm) {
				display: block;
			}
		}
	}
	section.campus{
		background: $color-main-light;
		padding: 60px 0;
	}
}

/* さくまさん */
.sakumasan{
	&__content{
		background: #FFF2F3;
		margin-top: 120px;
		padding: 60px 0;
		&-title{
			color: $color-main2;
			text-align: center;
		}
		&-lead{
			text-align: center;
		}
		.sakumasan-img{
			max-width: 800px;
			margin: 60px auto;
		}
	}
	&__box{
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 80px;
		@include media-breakpoint-down(sm) {
			display: block;
		}
		.item{
			background-color: $color-white;
			padding: 20px;
			border-top: 5px solid $color-main2;
			border-right: 1px solid $color-gray;
			border-left: 1px solid $color-gray;
			border-bottom: 1px solid $color-gray;
			@include media-breakpoint-down(sm) {
				margin-bottom: 30px;
			}
			.ttl_style5{
				color: $color-main2;
				text-align: center;
			}
			p{
				text-align: center;
			}
		}
	}
	.tweet{
		max-width: 700px;
		margin: 40px auto;
	}
	&__sns{
		&-box{
			display: flex;
			gap: 30px;
			justify-content: center;
			margin-top: 100px;
		}
		&-item{
			text-align: center;
			max-width: 235px;
			flex: 1;
			img{
				width: 60px;
				margin-top: 20px;
			}
			.text{
				padding: 20px;
				font-weight: bold;
				font-size: 2rem;
				border-radius: 10px;
				line-height: 1.2;
				min-height: 100px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include media-breakpoint-down(sm) {
					font-size: 1.4rem;
				}
				&.x{
					border: 3px solid $color-black;
					position: relative;
					&::before {
						content: "";
						position: absolute;
						bottom: -24px;
						left: 50%;
						margin-left: -15px;
						border: 12px solid transparent;
						border-top: 12px solid #FFF;
						z-index: 2;
					  }
					&::after{
						content: "";
						position: absolute;
						bottom: -30px;
						left: 50%;
						margin-left: -17px;
						border: 14px solid transparent;
						border-top: 14px solid $color-black;
						z-index: 1;
					}
				}
				&.line{
					border: 3px solid #4CC764;
					position: relative;
					&::before {
						content: "";
						position: absolute;
						bottom: -24px;
						left: 50%;
						margin-left: -15px;
						border: 12px solid transparent;
						border-top: 12px solid #FFF;
						z-index: 2;
					  }
					&::after{
						content: "";
						position: absolute;
						bottom: -30px;
						left: 50%;
						margin-left: -17px;
						border: 14px solid transparent;
						border-top: 14px solid #4CC764;
						z-index: 1;
					}
				}
			}

		}
	}
}

.c-sp {
	display: none!important
  }

.mfp-bg, .mfp-wrap {
	z-index: 999999
  }
  
  .mfp-container {
	padding-top: 10px;
	padding-bottom: 10px;
	overflow: auto
  }
  
  .c-modal {
	position: relative;
	max-width: 1000px;
	margin: 0 auto;
	padding: 30px 50px;
	background-color: #fff
  }
  
  .c-modal .mfp-close, .c-modal .popup-modal-dismiss {
	position: absolute;
	top: 0;
	right: 0;
	width: 50px;
	height: 50px;
	background: url(/about/img//modal_close.svg) 50% no-repeat;
	background-size: contain;
	color: transparent;
	opacity: 1
  }
  
  .c-modal__title {
	font-weight: 700;
	font-size: 22px;
	line-height: 1.5;
	text-align: center
  }
  
  .c-modal__title span {
	display: inline-block;
	color: #2da396;
	font-size: 28px
  }
  
  .c-modal__text {
	margin-top: 20px;
	font-size: 16px;
	line-height: 1.75
  }
  
  .c-modal-basic {
	display: flex;
	margin-top: 50px
  }
  
  .c-modal-basic__image {
	flex-shrink: 0;
	margin-right: 20px;
	width:25%;
  }
  
  .c-modal-basic__textarea .title {
	margin-bottom: 16px;
	font-weight: 700;
	font-size: 18px
  }
  
  .c-modal-basic__textarea ul {
	display: -ms-grid;
	display: grid;
	grid-template-rows: repeat(5, 1fr);
	grid-auto-flow: column;
	justify-content: space-between;
	font-size: 16px;
	line-height: 2;
	list-style:none;
  }
  
  .c-modal-basic__textarea ul li.has_icon span {
	position: relative;
	border-bottom: 2px solid #2da396
  }
  
  .c-modal-basic__textarea ul li.has_icon span img {
	position: absolute;
	left: 105%
  }
  
  .c-modal-basic__textarea ul li.has_icon span img.icon1 {
	bottom: 0
  }
  
  .c-modal-info {
	margin-top: 30px
  }
  
  .c-modal-info__list {
	-ms-grid-columns: (1fr)[3];
	display: -ms-grid;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-right: -30px;
	margin-left: -30px;
	list-style: none;
  }
  
  .c-modal-info__list li {
	padding: 0 30px
  }
  
  .c-modal-info__list li+li {
	border-left: 1px solid #2da396
  }
  
  .c-modal-info__list li .title {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	color: #2da396;
	font-weight: 700;
	font-size: 12px
  }
  
  .c-modal-info__list li .title .num {
	margin-left: 3px;
	transform: translateY(-5px);
	font-size: 50px
  }
  
  .c-modal-info__list li .stitle {
	margin-bottom: 20px;
	font-weight: 700;
	font-size: 18px;
	line-height: 2
  }
  
  .c-modal-info__list li p {
	font-size: 18px;
	line-height: 1.5555555556
  }
  
  .c-modal-lang {
	margin-top: 40px
  }
  
  .c-modal-lang__box {
	padding: 12px 20px 20px;
	background: rgba(221, 234, 241, .25)
  }
  
  .c-modal-lang__box .title {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 7px;
	gap: 26px;
	font-weight: 700;
	font-size: 22px
  }
  
  .c-modal-lang__box .title span {
	flex-shrink: 0
  }
  
  .c-modal-lang__box .title:after, .c-modal-lang__box .title:before {
	width: 100%;
	height: 1px;
	background: #333;
	content: ""
  }
  
  .c-modal-lang__box .cap {
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.2;
	text-align: right
  }
  
  .c-modal-lang__box .flex {
	display: flex;
	align-items: center
  }
  
  .c-modal-lang__box .flex .text {
	flex-shrink: 0;
	margin-right: 20px;
	color: #2da396;
	font-weight: 700;
	font-size: 18px;
	line-height: 1.7777777778
  }
  
  .c-modal-lang__box .flex .text img {
	margin-left: 10px;
	transform: translateY(-2px);
	vertical-align: middle
  }
  
  .c-modal-lang__box .flex .lineup {
	display: flex;
	flex-wrap: wrap;
	gap: 15px
  }
  
  .c-modal-lang__box .flex .lineup li {
	padding: 14px 15px;
	background: #2da396;
	color: #fff;
	font-weight: 700;
	font-size: 18px;
	list-style: none;
  }
  
  .c-modal-lang__box .flex .lineup li sup {
	font-size: 10px;
	vertical-align: bottom
  }
  
  .c-modal-lang__box .flex .lineup li small {
	font-size: 14px
  }
  
  .c-modal-lang__text {
	margin-top: 30px;
	font-size: 18px;
	line-height: 1.5555555556;
	text-align: center
  }
  
  .c-modal-sports {
	-ms-grid-columns: (1fr)[2];
	display: -ms-grid;
	display: grid;
	position: relative;
	grid-template-columns: repeat(2, 1fr);
	margin-top: 25px;
	gap: 10px
  }
  
  .c-modal-sports__icon {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto
  }
  
  .c-modal-sports__image {
	text-align: center
  }
  
  .c-modal-new {
	margin-top: 40px;
  }
  
  .c-modal-new__list {
	-ms-grid-columns: (1fr)[3];
	display: -ms-grid;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	margin-right: -25px;
	margin-left: -25px;
  }
  
  .c-modal-new__list li {
	padding: 0 25px;
	list-style: none;
  }
  
  .c-modal-new__list li+li {
	border-left: 1px dashed #999
  }
  
  .c-modal-new__list li:first-child .c-modal-new__title {
	background: #c6e4dd
  }
  
  .c-modal-new__list li:nth-child(2) .c-modal-new__title {
	background: #2da396;
	color: #fff
  }
  
  .c-modal-new__list li:nth-child(3) .c-modal-new__title {
	background: #146f6f;
	color: #fff
  }
  
  .c-modal-new__title {
	margin-bottom: 15px;
	padding: 7px 10px;
	background: #000;
	font-weight: 700;
	font-size: 18px;
	line-height: 1.2;
	text-align: center
  }
  
  .c-modal-new__text {
	font-size: 16px;
	line-height: 1.75
  }
  
  .c-modal-new__comment {
	position: relative;
	min-height: 297px;
	margin-top: 20px;
	padding: 15px;
	border-radius: 10px;
	background: #ddeaf1
  }
  
  .c-modal-new__comment:after {
	position: absolute;
	right: 0;
	bottom: 100%;
	left: 0;
	width: 0;
	height: 0;
	margin: 0 auto;
	border-width: 0 7px 17px;
	border-style: solid;
	border-color: transparent transparent #ddeaf1;
	content: ""
  }
  
  .c-modal-new__stitle {
	display: flex;
	align-items: center;
	margin-bottom: 13px;
	padding-bottom: 13px;
	border-bottom: 2px solid #fff;
	font-weight: 700;
	font-size: 16px;
	line-height: 1.375
  }
  
  .c-modal-new__stitle small {
	font-size: 14px
  }
  
  .c-modal-new__stitle img {
	flex-shrink: 0;
	margin-right: 10px;
	margin-left: -25px
  }
  
  .c-modal-new__text2 {
	font-size: 14px;
	line-height: 1.8571428571
  }
  
  
  @media screen and (max-width:768px) {
	.c-sp {
		display: block!important
	  }

	.c-modal {
	  padding: 50px 20px 20px
	}
	.c-modal .mfp-close, .c-modal .popup-modal-dismiss {
	  position: fixed;
	  top: 10px;
	  right: 6px
	}
	.c-modal__title {
	  font-size: 18px
	}
	.c-modal__title span {
	  font-size: 22px
	}
	.c-modal__text {
	  margin-top: 10px;
	  font-size: 14px
	}
	.c-modal-basic {
	  display: block;
	  margin-top: 30px
	}
	.c-modal-basic__image {
	  margin-right: 0;
	  margin-bottom: 20px;
	  text-align: center;
	  width:100%;
	}
	.c-modal-basic__textarea {
	  max-width: 500px;
	  margin: 0 auto
	}
	.c-modal-basic__textarea .title {
	  margin-bottom: 10px
	}
	.c-modal-basic__textarea ul {
	  -ms-grid-rows: (auto)[8];
	  grid-template-rows: repeat(8, auto);
	  font-size: 14px
	}
	.c-modal-basic__textarea ul li.has_icon span img.icon1 {
	  bottom: 10px;
	  left: 80%
	}
	.c-modal-info__list {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	  margin-right: 0;
	  margin-left: 0
	}
	.c-modal-info__list li {
	  padding: 0
	}
	.c-modal-info__list li+li {
	  margin-top: 20px;
	  padding-top: 20px;
	  border-top: 1px solid #2da396;
	  border-left-width: 0
	}
	.c-modal-info__list li .title {
	  margin-bottom: 5px
	}
	.c-modal-info__list li .title .num {
	  transform: translateY(-2px);
	  font-size: 30px
	}
	.c-modal-info__list li .stitle {
	  margin-bottom: 10px
	}
	.c-modal-info__list li p {
	  font-size: 16px
	}
	.c-modal-lang {
	  margin-top: 30px
	}
	.c-modal-lang__box .title {
	  gap: 15px;
	  font-size: 20px
	}
	.c-modal-lang__box .cap {
	  font-size: 12px
	}
	.c-modal-lang__box .flex {
	  display: block
	}
	.c-modal-lang__box .flex .text {
	  display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;
	  margin-right: 0;
	  margin-bottom: 20px
	}
	.c-modal-lang__box .flex .text img {
	  margin-left: 0;
	  transform: rotate(90deg)
	}
	.c-modal-lang__box .flex .lineup {
	  gap: 10px
	}
	.c-modal-lang__box .flex .lineup li {
	  padding: 10px;
	  font-size: 14px
	}
	.c-modal-lang__box .flex .lineup li small {
	  font-size: 12px
	}
	.c-modal-lang__text {
	  font-size: 14px
	}
	.c-modal-sports {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr
	}
	.c-modal-new {
	  margin-top: 30px
	}
	.c-modal-new__list {
	  -ms-grid-columns: 1fr;
	  grid-template-columns: 1fr;
	  margin-right: 0;
	  margin-left: 0
	}
	.c-modal-new__list li {
	  padding: 0;
	  list-style: none;
	}
	.c-modal-new__list li+li {
	  margin-top: 20px;
	  padding-top: 20px;
	  border-top: 1px dashed #999;
	  border-left-width: 0
	}
	.c-modal-new__text {
	  font-size: 14px
	}
	.c-modal-new__comment {
	  min-height: auto
	}
	.c-modal-new__stitle img {
	  margin-left: 0
	}
	.c-modal-new__text2 {
	  font-size: 13px
	}
  }
  
  
  @media print, screen and (min-width:1081px) {
	.c-tab {
	  display: none!important
	}
  }
  
