@charset 'utf-8';
.finance{
	&__tab{
		.tab-content{
			background-color: $color-main-light;
			padding: 80px 0;
		}
		.nav{
			gap: 40px;
			@include media-breakpoint-down(sm) {
				gap: 10px;
			}
		}
		.nav-item{
			flex: 1;
			border: 2px solid $color-main;
			border-radius: 5px 5px 0 0 ;

			a{
				display: block;
				color: $color-main;
				text-align: center;
				padding: 10px 0;
				font-size: 2.0rem;
				font-weight: bold;
				position: relative;
				@include media-breakpoint-down(sm) {
					font-size: 1.6rem;
					display: flex;
					height: 100%;
					flex-direction: column;
					justify-content: center;
					padding: 10px 30px 10px 10px;
					line-height: 1.4;
				}
				&:hover{
					opacity: 1;
				}
				&::after{
					content: "\f13a" !important;
					display: inline-block;
					color: inherit;
					font-size: 2.6rem;
					font-family: 'Line Awesome Free';
					font-weight: 900;
					position: absolute;
					right: 10px;
					top: 50%;
					transform: translateY(-50%);
					@include media-breakpoint-down(sm) {
						right: 5px;
					}
				}
			}
			.active{
				background: $color-main;
				color: $color-white;
			}
		}
		.box{
			margin-bottom: 120px;
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		.box2{
			background: $color-white;
			padding: 50px 30px;
			margin-bottom: 120px;
			@include media-breakpoint-down(sm) {
				margin-bottom: 80px;
				padding: 50px 20px;
			}
			&:last-of-type{
				margin-bottom: 0;
			}
		}
		.label{
			flex: 1;
			border: 2px solid $color-main;
			color: $color-main;
			font-weight: bold;
			text-align: center;
			line-height: 1.4;
			padding: 5px;
			&.active{
				color: $color-white;
				background-color: $color-main;
			}
			&-box{
				margin-top: 30px;
			}
			&-wrap{
				display: flex;
				align-items: center;
				gap: 20px;
				margin-top: 20px;
			}
		}
	}
	&__point{
		display: flex;
		align-items: flex-start;
		gap: 30px;
		background-color: $color-white;
		padding: 50px 30px;
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			padding: 50px 20px;

		}
		.item{
			list-style: none;
			border-right: 1px dashed $color-gray;
			padding-right: 30px;
			text-align: justify;
			flex: 1;
			@include media-breakpoint-down(sm) {
				border-right: none;
				border-bottom: 1px dashed $color-gray;
				padding-right: 0px;
				padding-bottom: 30px;
			}
			&:last-of-type{
				border-right: none;
				padding-right: 0;
			}
			.ttl_style4{
				margin-bottom: 20px;
				&::after{
					content: "";
					width: 30px;
					height: 3px;
					background-color: $color-black;
					display: block;
					margin: 5px auto 0;
				}
			}
			.icon{
				text-align: center;
				min-height: 120px;
				margin: 20px 0;
				@include media-breakpoint-down(sm) {
					min-height: none;
				}
			}
		}
	}

	&__scholarship{
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		padding: 50px 30px;
		@include media-breakpoint-down(sm) {
			display: block;
			padding: 30px 0px;
		}
		.item{
			flex: 0 0 calc(100% / 3 - 20px);
			list-style: none;
			background-color: $color-white;
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			@include media-breakpoint-down(sm) {
				margin-bottom: 20px;
			}
			.ttl_style4{
				min-height: 80px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}
		}
	}

	&__sumai{
		background-color: $color-white;
		padding: 40px 50px;
		@include media-breakpoint-down(sm) {
			padding: 30px 20px;

		}
		&-box{
			display: flex;
			gap: 50px;
			margin-top: 40px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
			.wrap{
				flex: 1;
				@include media-breakpoint-down(sm) {
					margin-bottom: 20px;
				}
			}
		}
	}

	&__community{
		&-box{
			margin: 30px 0 60px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
			.item{
				flex: 1;
				@include media-breakpoint-down(sm) {
					margin-bottom: 20px;
				}
				&.map{
					display: flex;
					align-items: center;
					margin-top: 30px;
					gap: 40px;
					@include media-breakpoint-down(sm) {
						display: block;
					}
					span{
						display: block;
					}
					.images{
						position: relative;
						flex: 1;
						.all{
							position: absolute;
							top: 30px;
							border: 1px solid $color-gray-light;
							border-radius: 9999px;
							width: 180px;
							height: 180px;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-direction: column;
							@include media-breakpoint-down(md) {
								width: 150px;
								height: 150px;
							}
							@include media-breakpoint-down(sm) {
								top: 0;
								width: 130px;
								height: 130px;
							}
							&-text{
								font-size: 1.6rem;
								color: $color-main;
								font-weight: bold;
								text-align: center;
								@include media-breakpoint-down(sm) {
									font-size: 1.4rem;
								}
								&2{
									font-size: 4rem;
									color: $color-main;
									font-weight: bold;	
									font-family: $font-family-en;
									text-align: center;
									line-height: 1.2;
									@include media-breakpoint-down(md) {
										font-size: 3.5rem;
									}
									@include media-breakpoint-down(sm) {
										font-size: 3rem;
									}
									span{
									font-size: 2rem;
									display: inline;
									}
								}
							}
						}
					}
					.local{
						&-list{
							flex: 1;
							list-style: none;
							padding-left: 0;
							display: grid;
							grid-template-columns: repeat(3, 1fr);
							gap: 10px;
							@include media-breakpoint-down(sm) {
								margin-top: 30px;
							}
							.place{
								font-size: 1.6rem;
								border-radius: 30px;
								width: 100%;
								max-width: 140px;
								text-align: center;
								color: $color-white;
								font-weight: bold;
								margin: 0 auto;
								@include media-breakpoint-down(md) {
									font-size: 1.2rem;
								}
								&.hokkaido{
									background: #57B3DB;
								}
								&.tohoku{
									background: #3BB3AA;
								}
								&.kanto{
									background: #64BC6E;
								}
								&.chubu{
									background: #D9DE58;
								}
								&.kinki{
									background: #F4BB65;
								}
								&.chugoku{
									background: #E97951;
								}
								&.kyusyu{
									background: #FDAEBF;
								}
							}
							.text{
								font-size: 4rem;
								color: $color-black;
								font-weight: bold;	
								font-family: $font-family-en;
								text-align: center;
								@include media-breakpoint-down(md) {
									font-size: 3.5rem;
								}
								@include media-breakpoint-down(sm) {
									font-size: 2.6rem;
								}
								span{
									font-size: 2rem;
									display: inline;
									@include media-breakpoint-down(sm) {
										font-size: 1.6rem;
									}
								}
							}
						}
						&-item2{
							border-top: 1px dashed $color-gray;
							border-left: 1px dashed $color-gray;
							grid-area: 3 / 2 / 4 / 4;
							padding: 10px;
							.place2{
								text-align: right;
								font-size: 1.6rem;
								font-weight: bold;
								@include media-breakpoint-down(sm) {
									font-size: 1rem;
								}
							}
							.text2{
								text-align: right;
								font-size: 3.5rem;
								font-weight: bold;
								font-family: $font-family-en;
								line-height: 1;
								@include media-breakpoint-down(sm) {
									font-size: 2.6rem;
								}
								span{
									font-size: 2rem;
									display: inline;
									@include media-breakpoint-down(sm) {
										font-size: 1.6rem;
									}
								}
							}
						}
					}
				}
			}
		}
		&-card{
			display: flex;
			gap: 40px;
			margin-bottom: 60px;
			&:last-child{
				margin-bottom:0;
			}
			@include media-breakpoint-down(sm) {
				display: block;
			}
			&:nth-child(odd){
				flex-direction: row-reverse;
			}
			.item-img{
				flex: 0 0 30%;
				@include media-breakpoint-down(sm) {
					margin-bottom: 20px;
				}
			}
			.btn_block{
				&.point3{
					display: flex;
					justify-content: space-between;
					@include media-breakpoint-down(sm) {
						display: block;
					}
					.btn_default{
						min-width: auto;
						@include media-breakpoint-down(sm) {
							margin-bottom: 20px;
						}
						&:first-child{
							flex: 0 0 42%;
							display: flex;
							align-items: center;
							@include media-breakpoint-down(sm) {
								display: block;
							}
						}
						&:nth-child(2){
							flex: 0 0 55%;
						}
					}
				}
			}
		}
	}

	&__local{
		&-box{
			display: flex;
			gap: 60px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
			.item{
				border: 1px solid $color-gray;
				padding: 30px;
				@include media-breakpoint-down(sm) {
					padding: 30px 15px;
					margin-bottom: 30px;
				}
			}
		}
		&-table{
			margin-top: 40px;
			th{
				background-color: #EEEEEE;
				color: $color-black;
				width: 30%;
				padding: 0 20px;
			}
			th,td{
				border: 1px solid $color-gray;
			}
		}
	}
}