// ---------------------------------------------
//  margin
// ---------------------------------------------
body {
	.mrg__btm0 {
		margin-bottom: 0 !important;
	}
	.mrg__btm05 {
		margin-bottom: .5em !important;
	}
	.mrg__btm1 {
		margin-bottom: 1em !important;
	}
	.mrg__btm2 {
		margin-bottom: 2em !important;
	}
	.mrg__btm3 {
		margin-bottom: 3em !important;
	}
	.mrg__btm4 {
		margin-bottom: 4em !important;
	}
	.mrg__btm5 {
		margin-bottom: 5em !important;
	}
	.mrg__btm6 {
		margin-bottom: 6em !important;
	}
	.mrg__btm_gutter {
		margin-bottom: $grid-gutter-width !important;
	}
	.mrg__top0 {
		margin-top: 0 !important;
	}
	.mrg__top05 {
		margin-top: .5em !important;
	}
	.mrg__top1 {
		margin-top: 1em !important;
	}
	.mrg__top2 {
		margin-top: 2em !important;
	}
	.mrg__top3 {
		margin-top: 3em !important;
	}
	.mrg__top_gutter {
		margin-top: $grid-gutter-width !important;
	}
	.mrg__left0 {
		margin-left: 0 !important;
	}
	.mrg__left1 {
		margin-left: 1em !important;
	}
	.mrg__left2 {
		margin-left: 2em !important;
	}
	.mrg__left3 {
		margin-left: 3em !important;
	}
	.mrg__left_gutter {
		margin-left: $grid-gutter-width !important;
	}
	.mrg__right0 {
		margin-right: 0 !important;
	}
	.mrg__right1 {
		margin-right: 1em !important;
	}
	.mrg__right2 {
		margin-right: 2em !important;
	}
	.mrg__right3 {
		margin-right: 3em !important;
	}
	.mrg__right_gutter {
		margin-right: $grid-gutter-width !important;
	}
	.pad__top0 {
		padding-top: 0 !important;
	}
	.pad__top1 {
		padding-top: 1em !important;
	}
	.pad__top2 {
		padding-top: 2em !important;
	}
	.pad__top3 {
		padding-top: 3em !important;
	}
	.pad__top_gutter {
		padding-top: $grid-gutter-width !important;
	}
	.pad__btm0 {
		padding-bottom: 0 !important;
	}
	.pad__btm1 {
		padding-bottom: 1em !important;
	}
	.pad__btm2 {
		padding-bottom: 2em !important;
	}
	.pad__btm3 {
		padding-bottom: 3em !important;
	}
	.pad__btm_gutter {
		padding-bottom: $grid-gutter-width !important;
	}
	.pad__left0 {
		padding-left: 0 !important;
	}
	.pad__left1 {
		padding-left: 1em !important;
	}
	.pad__left2 {
		padding-left: 2em !important;
	}
	.pad__left3 {
		padding-left: 3em !important;
	}
	.pad__left_gutter {
		padding-left: $grid-gutter-width !important;
	}
	.pad__right0 {
		padding-right: 0 !important;
	}
	.pad__right1 {
		padding-right: 1em !important;
	}
	.pad__right2 {
		padding-right: 2em !important;
	}
	.pad__right3 {
		padding-right: 3em !important;
	}
	.pad__right_gutter {
		padding-right: $grid-gutter-width !important;
	}
	.mrg_btm_sm_0 {
		@include media-breakpoint-up(sm) {
			margin-bottom: 0 !important;
		}
	}
	.mrg_btm_md_0 {
		@include media-breakpoint-up(md) {
			margin-bottom: 0 !important;
		}
	}
	.mrg_btm_lg_0 {
		@include media-breakpoint-up(lg) {
			margin-bottom: 0 !important;
		}
	}
	.mrg_btm_xl_0 {
		@include media-breakpoint-up(xl) {
			margin-bottom: 0 !important;
		}
	}
	.pad_btm_sm_0 {
		@include media-breakpoint-up(sm) {
			padding-bottom: 0 !important;
		}
	}
	.pad_btm_md_0 {
		@include media-breakpoint-up(md) {
			padding-bottom: 0 !important;
		}
	}
	.pad_btm_lg_0 {
		@include media-breakpoint-up(lg) {
			padding-bottom: 0 !important;
		}
	}
	.pad_btm_xl_0 {
		@include media-breakpoint-up(xl) {
			padding-bottom: 0 !important;
		}
	}
}
