@charset 'utf-8';

.page {

	footer{
		padding: 10rem 0 6rem;
	}
	&.header-static {
		header {
			position: static;
		}
	}

	&.career {
		&.graduate {
			.pagelink_style1-item a {
				&::after {
					content: "\f0a9" !important;
				}
			}
		}
	}

	&__fv {}

	&__mv {
		position: relative;
		z-index: 0;

		&-title {
			position: absolute;
			bottom: 6rem;
			z-index: 2;
			margin: 0;
			font-size: 4.6rem;
			font-weight: bold;
			line-height: 1.1;

			@include media-breakpoint-down(sm) {
				font-size: 3.6rem;
			}

			@include media-breakpoint-down(xs) {
				font-size: 9.6vw;
			}

			&::before {
				position: absolute;
				left: 0;
				top: -6rem;
				content: "";
				display: block;
				width: 100px;
				height: 2px;
				background-color: $color-main;

				@include media-breakpoint-down(sm) {
					top: -4rem;
					width: 40px;
				}
			}

			&_inner {
				display: inline-block;
				padding: 1.3rem 1.6rem 1.1rem 1.8rem;
				background-color: $color-main;
				color: $color-white;

				@include media-breakpoint-down(sm) {
					padding: 1rem 1.6rem 1rem 1.8rem;
				}

				@include media-breakpoint-down(xs) {
					padding: 1rem 4.2vw 1rem 4.8vw;
				}

				&.-learning {
					background-image: linear-gradient(to right, #99a3ec, #8194e8);
				}

				&.-campuslife {
					background-image: linear-gradient(to right, #a0ebff, #73e4e1);
				}

				&.-experience {
					background-image: linear-gradient(to right, #849ae8, #5676e8);
				}

				&.-career {
					background-image: linear-gradient(to right, #1f2d96, #5513af);
				}
			}

			small {
				font-size: 56.5%;
				font-weight: inherit;
			}
		}

		&-bg {
			margin-right: calc(50% - 50vw);
			padding-left: 28rem;

			@include media-breakpoint-down(sm) {
				padding-left: 5.5rem;
			}

			img {
				width: 100%;
				height: 380px;
				-o-object-fit: cover;
				object-fit: cover;

				@include media-breakpoint-down(sm) {
					height: 300px;
				}
			}
		}
	}

	&__lead {
		padding-bottom: 6rem;
		text-align: center;

		@include media-breakpoint-down(sm) {
			padding-bottom: 4rem;
			text-align: left;
		}

		&-title {
			margin-bottom: 3rem;
			@extend .ttl_style1;
			line-height: 1.7;
			color: $color-black;
		}

		&-text {}
	}
}


.post {
	&__pagelink {
		@include media-breakpoint-up(md) {
			padding-left: 0px;
			flex-wrap: wrap;

			&:nth-child(1) {
				padding-left: 0;
			}

			li {
				width: 100%;
				flex: 0;

				a {
					display: inline-block;
					width: 100%;
				}
			}
		}
	}

	.inner-post {
		max-width: 1150px;
		margin: 0 auto;

		@include media-breakpoint-down(sm) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}

	.post_btnblock{
		@include media-breakpoint-down(sm) {
			margin: 40px 0;
		}
		.graduate__pagelink-sp{
			width: 100%;
			padding: 15px 10px;
			margin-bottom: 10px;
			border: 2px solid $color-main;
			-webkit-appearance: none;
			appearance: none;
			background-color: $color-white;
			color: $color-main;
			font-weight: bold;
			&:focus {
				outline: 0;
			  }
		}
	}

	&.detail {

		.post-detail {
			display: flex;
			gap: 20px;

			.day {
				font-weight: bold;
			}
		}

		.name {
			color: $color-main;
			font-weight: bold;
			font-size: 1.8rem;
			margin-top: 20px;
		}

		.editor {
			margin: 80px 0;
		}

		.post-detail {
			&__title {
				z-index: 2;
				margin-top: 3rem;
				font-size: 3.6rem;
				font-weight: bold;
				line-height: 1.1;
				position: relative;

				@include media-breakpoint-down(sm) {
					font-size: 2.6rem;
				}

				&::before {
					position: absolute;
					left: 0;
					top: -3rem;
					content: "";
					display: block;
					width: 100px;
					height: 2px;
					background-color: $color-main;

					@include media-breakpoint-down(sm) {
						top: -4rem;
						width: 40px;
					}
				}
			}

			&__mv {
				margin: 60px 0;
			}
		}

		.related-news {
			background: $color-main-light;
			padding: 60px 20px;
			margin-top: 60px;

			&__title {
				font-size: 2.6rem;

				@include media-breakpoint-down(sm) {
					font-size: 2.6rem;
				}
			}
		}


	}
}

.research {
	.ttl_style1 {
		font-size: 2.2rem;
	}

	.ttl_style2 {
		font-size: 2.8rem;
	}

	.breadcrumb {
		margin-top: 0px !important;

		@include media-breakpoint-down(sm) {
			margin-top: 10px;
		}
	}

	&-detail {
		&__profiel {
			display: flex;
			gap: 30px;

			@include media-breakpoint-down(sm) {
				display: block;
			}

			.title {
				font-size: 3.6rem;
				margin-bottom: 30px;

				@include media-breakpoint-down(sm) {
					font-size: 2.4rem;
				}
			}

			.img {
				flex: 0 0 250px;

				@include media-breakpoint-down(sm) {
					margin-bottom: 20px;
				}
			}

			.day {
				font-size: 1.6rem;
				margin-bottom: 30px;
			}

			.box-name {
				display: flex;
				align-items: center;
				gap: 10px;
				margin-bottom: 30px;
			}

			.name {
				font-size: 1.8rem;
				margin: 0 !important;

				&-en {
					font-size: 1.6rem;
					font-weight: normal;
				}
			}

			.text {
				font-size: 1.6rem;
				font-weight: normal;
			}
		}

		&__movie {
			background: $color-main-light;
			padding: 60px 20px;
			margin: 60px calc(50% - 50vw);
			width: 100vw;

			.title {
				font-size: 2.6rem;
				font-family: $font-family-en;
				font-weight: bold;
				text-align: center;
			}

			.box {
				margin: 0 auto;
				max-width: 560px;
			}

			iframe[src^="https://www.youtube.com/"] {
				width: 560px;

				@include media-breakpoint-down(sm) {
					width: 100%;
					height: auto;
					aspect-ratio: 16/9;
				}
			}
		}
	}
}

@import './page_about';
@import './page_learning';
@import './page_campuslife';
@import './page_experience';
@import './page_career';
@import './page_admissions';
@import './page_data';