// ---------------------------------------------
//  heading
// ---------------------------------------------
.ttl {
	&_style1 {
		font-size: 3.6rem;
		font-weight: bold;
		line-height: 1.3;
		@include media-breakpoint-down(sm) {
			font-size: 2.6rem;
		}
	}
	
	&_style2 {
		font-size: 2.8rem;
		font-weight: bold;
		line-height: 1.3;
		@include media-breakpoint-down(sm) {
			font-size: 2.4rem;
		}
	}

	&_style3 {
		padding-bottom: .2em;
		border-bottom: 1px solid $color-black;
		font-size: 2.8rem;
		font-weight: bold;
		line-height: 1.3;
		@include media-breakpoint-down(sm) {
			font-size: 2rem;
		}
	}
	
	&_style4 {
		font-size: 2.2rem;
		font-weight: bold;
		line-height: 1.5;
		@include media-breakpoint-down(sm) {
			font-size: 1.8rem;
		}
	}

	&_style5 {
		font-weight: bold;
		font-size: 2.2rem;
		color: $color-main;
		@include media-breakpoint-down(sm) {
			font-size: 1.8rem;
		}
	}
}
