@charset 'utf-8';

// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-down(sm) {
// }
.data {
	$color-data: #1C456E;
	$color-bg-t: #A0EBFF;
	$color-bg-b: #8AFAED;
	$data-02: #F59B21;
	$data-03: #07831F;
	$data-04: #B1A275;
	$data-05: #FD8080;
	$data-06: #26A4AF;
	$data-pink: #FFB5B5;
	$data-be: #E3D8B3;
	$width-data-inner: calc(#{$content-inner} + (#{$spacing} * 4));

	&-mv {
		background-image: url(/campuslife/img/data/mv-wave-sp.svg);
		background-repeat: no-repeat;
		background-position: bottom;

		@include media-breakpoint-up(md) {
			background-size: cover;
			background-image: url(/campuslife/img/data/mv-wave.svg);
		}

		@include media-breakpoint-up(xl) {
			background-size: auto 372px;
			background-repeat: repeat-x;
		}

		&__inner {
			position: relative;
			padding-bottom: 265px;

			@include media-breakpoint-up(md) {
				padding-bottom: 85px;
				max-width: $width-data-inner !important;
				margin: auto;
			}
		}

		&__ttl {
			text-align: center;
			margin-bottom: 30px;

			&_black {
				font-size: 2.1rem;
				display: block;
				font-weight: bold;

				@include media-breakpoint-up(md) {
					font-size: 2.8rem;
				}

				&::before {
					display: inline-block;
					content: '';
					width: 20px;
					height: 17px;
					position: relative;
					background-image: url(/campuslife/img/data/line-left.svg);
					background-size: contain;
					background-repeat: no-repeat;
					margin-bottom: -5px;

					@include media-breakpoint-up(md) {
						width: 30px;
						height: 25px;
					}
				}

				&::after {
					display: inline-block;
					content: '';
					width: 20px;
					height: 17px;
					position: relative;
					background-image: url(/campuslife/img/data/line-right.svg);
					background-size: contain;
					background-repeat: no-repeat;
					margin-bottom: -5px;

					@include media-breakpoint-up(md) {
						width: 30px;
						height: 25px;
					}
				}
			}

			&_big {
				font-size: 2.8rem;
				display: block;
				font-weight: bold;

				@include media-breakpoint-up(md) {
					font-size: 3.7rem;
				}
			}

			&_en {
				font-size: 6.2rem;
				font-family: $font-family-en;
				color: $color-white;
				-webkit-text-stroke: 2px $color-data;

				@include media-breakpoint-up(md) {
					font-size: 8.2rem;
				}
			}

			&_blue {
				font-size: 3.7rem;
				color: $color-main;

				@include media-breakpoint-up(md) {
					font-size: 4.8rem;
				}
			}
		}

		&__sub {
			text-align: center;
		}

		&__deco {
			position: absolute;

			&_01 {
				bottom: 0;
				display: none;

				@include media-breakpoint-up(md) {
					display: block;
				}
			}

			&_02 {
				bottom: 0;
				width: 80px;
				right: 0;

				@include media-breakpoint-up(md) {
					right: calc(50% - 540px);
					width: auto;
				}
			}

			&_03 {
				bottom: 0;
				width: 50%;

				@include media-breakpoint-up(sm) {
					width: 35%;
				}

				@include media-breakpoint-up(md) {
					width: auto;
					left: calc(50% - 590px);
				}
			}

			&_04 {
				width: 50%;
				bottom: 0;
				right: 20px;

				@include media-breakpoint-up(sm) {
					width: 35%;
				}

				@include media-breakpoint-up(md) {
					width: auto;
					right: calc(50% - 550px);
				}
			}
		}
	}

	&-body {
		background: linear-gradient(to bottom right, $color-bg-t, $color-bg-b);
		padding: 60px 0 140px 0;

		&__bnr {
			margin-bottom: 40px;

			img {
				margin: auto;
			}
		}
	}

	&-anchor {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		@include media-breakpoint-up(md) {
			margin-top: 40px;
		}

		&__box {
			width: 100%;
			background-color: $color-white;
			margin-top: 20px;
			text-align: center;
			position: relative;
			border-radius: 5px;
			border: 5px solid $color-main;

			@include media-breakpoint-up(sm) {
				width: 48%;
			}

			@include media-breakpoint-up(md) {
				width: 31%;
			}

			a {
				display: block;
				height: 100%;
				padding: 20px 20px 40px 20px;
			}

			&:nth-child(1) {
				.data-anchor__data {
					color: $color-main;
				}
			}

			&:nth-child(2) {
				.data-anchor__data {
					color: $data-02;
				}
			}

			&:nth-child(3) {
				.data-anchor__data {
					color: $data-03;
				}
			}

			&:nth-child(4) {
				.data-anchor__data {
					color: $data-04;
				}
			}

			&:nth-child(5) {
				.data-anchor__data {
					color: $data-05;
				}
			}

			&:nth-child(6) {
				.data-anchor__data {
					color: $data-06;
				}
			}
		}

		&__data {
			font-weight: bold;
			font-family: $font-family-en;
		}

		&__q {
			color: $color-main;
			font-weight: bold;
			font-size: 2.0rem;
			line-height: 30px;
		}

		&__arrow {
			font-size: 2.6rem;
			color: $color-main;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&-box {
		background-color: $color-white;
		margin-top: 60px;
		border-radius: 30px 0 30px 0;
		box-shadow: 6px 6px 4px rgba($color: #2828A0, $alpha: 0.1);

		@include media-breakpoint-up(md) {
			margin-top: 120px;
			position: relative;
		}

		&__top {
			padding: 0 20px 30px 20px;
			text-align: center;

			@include media-breakpoint-up(md) {
				padding: 0 60px 250px 60px;
			}
		}

		img {
			margin-top: 40px;
		}

		&__in {
			@include media-breakpoint-up(md) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}

		&__pink {
			position: relative;
			margin: 0 auto 60px auto;
			width: 306px;

			@include media-breakpoint-up(lg) {
				margin-bottom: 0;
				width: calc(50% - 30px);
			}

			&__ttl {
				font-weight: bold;
				font-size: 1.8rem;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				top: 100px;
				width: 100%;

				@include media-breakpoint-up(md) {
					font-size: 2.1rem;
					top: 100px;
				}

				@include media-breakpoint-up(lg) {
					font-size: 3.0rem;
					top: 90px;
				}

				@include media-breakpoint-up(xl) {
					top: 100px;
				}
			}

			&__txt {
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				background-color: $data-pink;
				border-radius: 10px;
				color: $color-white;
				top: 150px;
				width: 260px;
				padding: 20px;
				text-align: left;

				@include media-breakpoint-up(md) {
					width: 60%;
					top: 130px;
					border-radius: unset;
					color: $color-black;
					background-color: unset;
					padding: 0;
				}

				@include media-breakpoint-up(lg) {
					top: 150px;
				}

				@include media-breakpoint-up(xl) {
					top: 160px;
				}
			}

			&__vote {
				text-align: center;
				font-size: 2.2rem;
				background-color: $color-white;
				color: $data-pink;
				width: fit-content;
				padding: 0 10px;
				border-radius: 10px;
				margin: auto;

				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			&:first-child {
				width: calc(100% + 40px);
				margin-left: -20px;
				margin-bottom: 100px;

				.data-box__pink__txt {
					background-color: $color-white;
					color: $color-black;
					border: 2px solid $data-pink;

					@include media-breakpoint-up(md) {
						border: unset;
						background-color: unset;
					}
				}

				@include media-breakpoint-up(md) {
					width: calc(50% + 30px);
					margin-left: auto;
				}

				.data-box__pink__vote {
					background-color: $data-pink;
					color: $color-white;
				}
			}

		}

		&__d4 {
			@include media-breakpoint-up(lg) {
				display: flex;
				justify-content: space-between;
			}
		}

		&__item {
			padding: 0 20px 0 20px;
			width: 300px;
			margin: auto;

			@include media-breakpoint-up(md) {
				width: 300px;
				padding: 0 50px 0 30px;
			}

			@include media-breakpoint-up(lg) {
				width: calc(50% - 30px);
			}

			&:first-child {
				background-image: url(/campuslife/img/data/data04-1-sp.svg);
				background-size: contain;
				background-repeat: no-repeat;
				height: 870px;
				padding-top: 180px;

				@include media-breakpoint-up(md) {
					padding-top: 180px;
				}

				@include media-breakpoint-up(lg) {
					background-image: url(/campuslife/img/data/data04-1.svg);
					height: 1141px;
					padding-top: 20vw;
				}

				@include media-breakpoint-up(xl) {
					padding-top: 270px;
				}
			}

			&:nth-child(2) {
				background-image: url(/campuslife/img/data/data04-2-sp.svg);
				background-size: contain;
				background-repeat: no-repeat;
				height: 570px;
				padding-top: 160px;

				@include media-breakpoint-up(lg) {
					background-image: url(/campuslife/img/data/data04-2.svg);
					padding-top: 35vw;
					height: 1141px;
				}

				@include media-breakpoint-up(xl) {
					padding-top: 450px;
				}
			}
		}

		&__no {
			color: $data-be;
			font-size: 2.1rem;
			font-weight: bold;
			font-family: $font-family-en;

			@include media-breakpoint-up(md) {
				font-size: 2.8rem;
			}
		}

		&__num {
			color: $data-be;
			font-size: 4rem;
			font-weight: bold;
			font-family: $font-family-en;

			@include media-breakpoint-up(md) {
				font-size: 6rem;
			}
		}

		&__main {
			font-size: 1.8rem;
			font-weight: bold;
			margin-left: 10px;

			@include media-breakpoint-up(md) {
				font-size: 2.1rem;
			}

			@include media-breakpoint-up(lg) {
				font-size: 3rem;
				margin-left: 20px;
			}
		}

		&__v {
			text-align: left;
			line-height: 1;
			border-bottom: 2px dashed $data-be;
			margin-bottom: 20px;
			padding-bottom: 10px;
			display: flex;
			align-items: flex-end;

			@include media-breakpoint-up(md) {
				margin-bottom: 10px;
			}

			@include media-breakpoint-up(lg) {
				margin-bottom: 20px;
			}

			@include media-breakpoint-up(xl) {
				margin-bottom: 40px;
			}
		}

		&__small {
			text-align: left;
		}
	}

	&-tag {
		margin: auto;
		font-family: $font-family-en;
		font-size: 2.2rem;
		font-weight: bold;
		padding: 10px 60px;
		color: $color-white;
		width: fit-content;

		@include media-breakpoint-up(md) {
			padding: 5px 50px;
			font-size: 2.8rem;
		}
	}

	&-p {
		font-weight: bold;
		font-size: 2.2rem;
		text-align: center;
		margin-top: 20px;

		@include media-breakpoint-up(md) {
			font-size: 2.8rem;
		}
	}

	&-sub {
		font-size: 1.8rem;
		font-weight: bold;

		@include media-breakpoint-up(md) {
			font-size: 2rem;
		}
	}

	&-btm {
		position: relative;
		display: inline-block;
		margin: 1.5em 0 0 0;
		padding: 30px 20px;
		min-width: 120px;
		width: 100%;
		color: $color-white;
		font-size: 16px;
		background: $color-main;
		border-radius: 0 0 30px 0;

		@include media-breakpoint-up(md) {
			padding: 45px 75px;
			position: absolute;
			bottom: -40px;
			max-width: 700px;
			left: 50%;
			transform: translateX(-50%);
		}

		&::before {
			content: "";
			position: absolute;
			top: -30px;
			left: 50%;
			margin-left: -15px;
			border: 15px solid transparent;
			border-bottom: 15px solid $color-main;
		}

		&__ttl {
			font-size: 2.4rem;
			font-weight: bold;
			text-align: center;
			line-height: 38px;

			@include media-breakpoint-up(md) {
				font-size: 2.8rem;
			}
		}

		&__btn {
			background-color: $color-main;
			color: $color-white;
			font-size: 1.8rem;
			font-weight: bold;
			padding: 10px 30px;
			text-align: center;
			margin: 30px auto 0 auto;
			display: block;
			width: fit-content;
			position: relative;

			@include media-breakpoint-up(md) {
				padding: 17px 85px;
				font-size: 2rem;
			}

			&::after {
				position: absolute;
				content: "\f138";
				font-family: 'Line Awesome Free';
				font-weight: 900;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&-01 {
		.data-tag {
			background-color: $color-main;
		}
	}

	&-02 {
		.data-box__top {
			@include media-breakpoint-up(md) {
				padding-bottom: 190px;
			}
		}

		.data-tag {
			background-color: $data-02;
		}

		.data-btm {
			background-color: $data-02;

			&::before {
				border-bottom: 15px solid $data-02;
			}
		}

	}

	&-03 {
		.data-box__top {
			@include media-breakpoint-up(md) {
				padding-bottom: 300px;
			}
		}

		.data-tag {
			background-color: $data-03;
		}

		.data-btm {
			background-color: $data-03;

			&::before {
				border-bottom: 15px solid $data-03;
			}
		}
	}

	&-04 {
		.data-box__top {
			@include media-breakpoint-up(md) {
				padding-bottom: 320px;
			}

			@include media-breakpoint-up(lg) {
				padding-bottom: 280px;
			}
		}

		.data-tag {
			background-color: $data-04;
		}

		.data-btm {
			background-color: $data-04;

			&::before {
				border-bottom: 15px solid $data-04;
			}
		}
	}

	&-05 {
		.data-box__top {
			padding-bottom: 40px;

			@include media-breakpoint-up(md) {
				padding-bottom: 50px;
			}
		}

		.data-tag {
			background-color: $data-05;
		}

		.data-btm {
			background-color: $data-05;

			&::before {
				border-bottom: 15px solid $data-05;
			}
		}
	}

	&-06 {
		.data-box__top {
			@include media-breakpoint-up(md) {
				padding-bottom: 420px;
			}
		}

		.data-tag {
			background-color: $data-06;
		}

		.data-btm {
			background-color: $data-06;

			&::before {
				border-bottom: 15px solid $data-06;
			}
		}
	}
}