@charset 'utf-8';
@import '../_sass/utility/setting';
@import '../_sass/utility/mixin-extends';
@import '../_sass/utility/bootstrap';
@import '../_sass/utility/modules';

// -----------------------------------------------------
//  debug with breakpoint
// -----------------------------------------------------
// body:after {
// 	position: fixed;
// 	z-index: 9999;
// 	right: 0;
// 	bottom: 0;
// 	display: block;
// 	padding: 1em;
// 	content: 'min';
// 	background-color: rgba(#ccc, .8);
// 	@include media-breakpoint-up(sm) {
// 		content: 'sm 576->767';
// 	}
// 	@include media-breakpoint-up(md) {
// 		content: 'md 768->1023';
// 	}
// 	@include media-breakpoint-up(lg) {
// 		content: 'lg 1024->1366';
// 	}
// 	@include media-breakpoint-up(xl) {
// 		content: 'xl 1367->';
// 	}
// }
// --------------------------------------------
//	IE11でスクロールバーがコンテンツにかぶる問題
// http://xn--lcki7of.jp/762/
//---------------------------------------------
@-ms-viewport {
	width: auto;
	initial-scale: 1;
}

// --------------------------------------------
//	chromeの縮小画像ぼやけ問題
// --------------------------------------------
img {
	-webkit-backface-visibility: hidden;
}

// --------------------------------------------
//	全体
//---------------------------------------------
html {
	font-size: 62.5%;
}

body {
	width: 100%;
	// letter-spacing: .06em;
	color: $color-black;
	background-color: $color-body;
	// font-feature-settings: 'palt';
	font-size: $font-size-base;
	font-weight: 500;

	@include media-breakpoint-up(md) {
		font-size: $font-size-base;
	}

	&.ie11 {
		font-family: $font-family-sans-serif-ie11;
	}
}

img {
	width: auto;
	max-width: 100%;
	height: auto;
}

a {
	transition: all .2s linear;
	opacity: 1;

	&:hover {
		opacity: .6;
	}
}

ol,
ul {
	padding-left: 1em;

	li {
		padding-left: 0;
	}
}

// numberタイプの上下ボタンを非表示
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	margin: 0;
	-webkit-appearance: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.h1,
h1 {
	font-size: $h1-font-size * .8;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 1.5em;

	@include media-breakpoint-up(md) {
		font-size: $h1-font-size;
	}
}

.h2,
h2 {
	font-size: $h2-font-size *.8;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: .8em;

	@include media-breakpoint-up(md) {
		font-size: $h2-font-size;
		margin-bottom: 1em;
	}
}

.h3,
h3 {
	font-size: $h3-font-size * .8;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: .8em;

	@include media-breakpoint-up(md) {
		font-size: $h3-font-size;
		margin-bottom: 1em;
	}
}

.h4,
h4 {
	font-size: $h4-font-size * .85;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: .8em;

	@include media-breakpoint-up(md) {
		font-size: $h4-font-size;
		margin-bottom: 1em;
	}
}

.h5,
h5 {
	font-size: $h5-font-size *.85;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: .8em;

	@include media-breakpoint-up(md) {
		font-size: $h5-font-size;
		margin-bottom: 1em;
	}
}

figcaption {
	font-size: 1.1rem;
	display: block;
	padding-top: .2em;
	text-align: center;
	color: #999;
}

*:last-child {
	margin-bottom: 0;
}

.fa-file-pdf-o {
	color: red;
}

.fa-file-word-o {
	color: blue;
}

.fa-file-excel-o {
	color: green;
}

// --------------------------------------------
//	IMPORTS
// --------------------------------------------
@import "../_sass/base_parts";
@import '../_sass/ol_header';
@import '../_sass/ol_footer';
@import '../_sass/ol_nav';
@import '../_sass/ol_contents';
@import '../_sass/home';
@import '../_sass/page';
@import '../_sass/search';
@import '../_sass/guideline';
@import '../_sass/print';