@charset 'utf-8';
//------------------------------------------
//  Original
//------------------------------------------
$content-inner: 1080px;
$spacing: 10vw;
$width-inner: calc(#{$content-inner} + (#{$spacing} * 2));
$height-header: 80px;
$block-gutter-vertical: 60px;

//------------------------------------------
//  colors
//------------------------------------------
$color-black: #323232;
$color-black-light: #666;
$color-black-dark: #222;
$color-gray: #ccc;
$color-gray-light: #f0f0f0;
$color-white: #fff;
$color-main: #003c87;
$color-main-light: #e6f0f2;
$color-main-light2: #f8feff;
$color-main2: #e95377;
$color-main2-light: lighten(mix($color-main2, white, 70%), 10%);
$color-main3: #FFFF50;

$color-sub1: #589e57; //法学部
$color-sub1-light: lighten(mix($color-sub1, white, 70%), 10%);

$color-sub2: #0060af; //経済学部
$color-sub2-light: lighten(mix($color-sub2, white, 70%), 10%);

$color-sub3: #ba233c; //文学部
$color-sub3-light: lighten(mix($color-sub3, white, 70%), 10%);

$color-sub4: #464c8e; //理学部
$color-sub4-light: lighten(mix($color-sub4, white, 70%), 10%);

$color-sub5: #1586ac; //国際社会科学部
$color-sub5-light: lighten(mix($color-sub5, white, 70%), 10%);

$color-sub6: #9A7100; //国際文化交流学部
$color-sub6-light: lighten(mix($color-sub6, white, 70%), 10%);

$color-anchor: #337ab7;
$color-red: red;
$color-blue: blue;
$color-green: green;
$color-yellow: #ffe033;
$color-body: #fff;

// ---------------------------------------
// Bootstrap
// [origine] -> vender/bootstrap/_variables.scss
// ---------------------------------------
//  font colors
$font-family-sans-serif: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, Verdana, Roboto, 'Droid Sans', sans-serif;
// $font-family-sans-serif: YakuHanJP, 'Noto Sans JP', '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体', YuGothic, 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, Verdana, Roboto, 'Droid Sans', sans-serif;
$font-family-serif: YuMincho, '游明朝', 'Hiragino Mincho ProN', 'ヒラギノ明朝 W3 JIS2004', 'Sawarabi Mincho', 'Times New Roman', Times, serif;
$font-family-sans-serif-ie11 : 'メイリオ', Meiryo, Verdana, Roboto, 'Droid Sans', sans-serif;
$font-family-en: 'Lato', sans-serif;

// Breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 769px,
  lg: $content-inner,
  xl: 1367px
);

// Sizes
$sizes:
  (
		5: 5%,
		10: 10%,
		15: 15%,
		20: 20%,
		25: 25%,
		30: 30%,
		35: 35%,
		40: 40%,
    45: 45%,
    50: 50%,
		60: 60%,
		70: 70%,
		75: 75%,
		80: 80%,
		90: 90%,
    100: 100%,
    auto: auto
);

$sizes-em:
	(
		1: 1em,
		2: 2em,
		3: 3em,
		4: 4em,
		5: 5em,
		6: 6em,
		7: 7em,
		8: 8em,
		9: 9em,
		10: 10em,
	);

// font size
$font-size-base: 1.6rem;
$lead-font-size: ($font-size-base * 2);
$h1-font-size: $font-size-base * 2.75;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;
$line-height-base: 1.88;
$headings-line-height: 1.3;

// breadcomb
$breadcrumb-bg: transparent;
// $breadcrumb-divider: '>';

// table
$table-border-color: #aaa;
