@charset 'utf-8';
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-down(sm) {
// }

/* キャリアサポート */
.career {
	&__point {
		padding-top: 4rem;
		
		&-title {
			margin-bottom: 6rem;
			color: $color-main;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.35;
			text-align: center;
			@include media-breakpoint-down(sm) {
				font-size: 2rem;
			}
			strong {
				display: inline-block;
				padding: 0 .2em;
				background:linear-gradient(transparent 70%, $color-main-light 70%);
			}
			&_num {
				font-size: 3.6rem;
				@include media-breakpoint-down(sm) {
					font-size: 2.6rem;
				}
			}
			&_en {
				font-weight: bold;
				font-family: $font-family-en;
			}
		}

		&-block {
			position: relative;
			z-index: 0;
			margin-bottom: 12rem;
			padding: 0 6rem;
			@include media-breakpoint-down(sm) {
				padding: 0;
			}
			&_mv {
				margin: 0 -6rem;
			}
			&_content {
				position: relative;
				z-index: 1;
				margin: -6rem 0 0;
				padding: 0 3rem;
				background-color: $color-white;
				@include media-breakpoint-down(sm) {
					margin: 0 -2rem;
					padding: 0 2rem;
				}
			}
			&_header {
				margin-bottom: 6rem;
			}
			&_point {
				width: 100px;
				height: 100px;
				margin: 0 0 -2rem;
				transform: translateY(-4rem);
				padding-top: 1em;
				background-color: $color-main;
				border-radius: 50%;
				color: $color-white;
				font-size: 2rem;
				font-family: $font-family-en;
				font-weight: bold;
				line-height: 1.04;
				text-align: center;
				white-space: nowrap;
				span {
					display: block;
					font-size: 4rem;
				}
			}
		}
	}


	&__program {
		padding: 8rem 0 12rem;
		background-color: $color-main-light;
		
		&-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 30px;
			@include media-breakpoint-down(sm) {
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 30px;
			}
			&_item {
				position: relative;
				padding: 2.5rem 2rem;
				background-color: $color-white;
				border: 1px solid $color-gray;
				border-top: none;
				&:before {
					position: absolute;
					left: -1px;
					top: 0;
					content: "";
					width: calc(100% + 2px);
					height: 5px;
					background-color: $color-main;
				}
			}
			&_title {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 2.72em;
				margin-bottom: .8em;
				color: $color-main;
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 1.36;
				text-align: center;
				@include media-breakpoint-down(sm) {
					font-size: 2rem;
				}
			}
		}
	}
}

/* 卒業生紹介・内定者の声 */
.graduate {
	.page__fv {
		background-color: $color-main-light;
	}

	&__pagelink {
		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
			&:nth-child(1) {
				padding-left: 0;
			}
			li {
				width: 140px;
				&.-all {
					flex: none;
					width: 140px;
				}
				&.-long {
					width: 200px;
				}
				&.-sub {
					flex: none;
					width: 160px;
				}
			}
		}
	}

	&__list {
		&-item {
			&.-color1 {
				.card_style1-title {
					color: $color-sub1;
				}
			}
			&.-color2 {
				.card_style1-title {
					color: $color-sub2;
				}
			}
			&.-color3 {
				.card_style1-title {
					color: $color-sub3;
				}
			}
			&.-color4 {
				.card_style1-title {
					color: $color-sub4;
				}
			}
			&.-color5 {
				.card_style1-title {
					color: $color-sub5;
				}
			}
		}
	}
	
}


