@charset 'utf-8';
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-down(sm) {
// }

/* 桜凛祭 */
.fes {
	&__about {
		padding-top: 4rem;
		padding-bottom: 10rem;
	}

	&__program {
		padding: 8rem 0 12rem;
		background-color: $color-main-light;
		
		&-list {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 60px;
			@include media-breakpoint-down(sm) {
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 30px;
			}
			&_item {
				padding: 2.2rem 4rem 3rem;
				background-color: $color-white;
				border-top: 5px solid $color-main;
			}
			&_title {
				margin-bottom: .8em;
				color: $color-main;
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 1.36;
				text-align: center;
				@include media-breakpoint-down(sm) {
					font-size: 2rem;
				}
			}
		}
	}

	&__gallery {
		padding: 8rem 0 12rem;
		
		&-main {
			margin-bottom: 2rem;
			figure {
				margin: 0;
			}
		}
		&-thumb {
			margin: -7.5px;
			padding: 0;
			list-style: none;
			@include media-breakpoint-down(sm) {
				margin: -5px;
			}
			&_item {
				width: 10% !important;
				cursor: pointer;
				@include media-breakpoint-down(sm) {
					width: 20% !important;
				}
				&.slick-current {
					opacity: .6;
				}
				figure {
					margin: 0;
					padding: 7.5px;
					@include media-breakpoint-down(sm) {
						padding: 5px;
					}
					img{
						object-fit: cover;
						aspect-ratio: 3/2;
					}
				}
			}
			.slick-track {
				width: 100% !important;
				transform: unset !important; /*画面幅サイズ変更に伴うサムネイル固定*/
			}
		}
	}
}
