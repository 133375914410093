@charset 'utf-8';
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-down(sm) {
// }

/* キャンパス紹介 */
.campuslife {
	&__map {
		padding-top: 6rem;
		margin-bottom: 10rem;
		@include media-breakpoint-down(sm) {
			padding-top: 4rem;
		}

		&-main {
			margin-bottom: 6rem;
			@include media-breakpoint-down(sm) {
				overflow-x: auto;
			}
			img{
				@include media-breakpoint-down(sm) {
					min-width: 500px;
				}
			}
			map {
				area{
					cursor: pointer!important;
				}
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}

		&-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 60px;
			@include media-breakpoint-down(sm) {
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 30px;
			}
			&_item {
			}
			&_image {
				position: relative;
				margin-bottom: 1.8rem;
			}
			&_title {
				margin-bottom: .5em;
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 1.5;
				small {
					font-size: 1.4rem;
					font-weight: inherit;
				}
			}
			&_num {
				display: inline-block;
				transform: translateY(-1px);
				min-width: 28px;
				margin-right: 10px;
				padding: 0 2px;
				background-color: $color-black;
				color: $color-white;
				font-size: 1.8rem;
				line-height: 28px;
				text-align: center;
			}
			&_text {
				margin-bottom: 0;
			}
		}
	}

	$color-spring: #ffb3d5;
	$color-summer: #12a3fc;
	$color-autumn: #922c16;
	$color-winter: #736b68;

	&__season {
		padding-top: 6rem;

		&-section {
			position: relative;
			margin-bottom: 10rem;
			&::before {
				position: absolute;
				left: 50%;
				top: 300px;
				transform: translateX(-100%);
				z-index: -1;
				content: "";
				display: block;
				width: 50vw;
				height: calc(100% - 300px);
				@include media-breakpoint-down(sm) {
					top: 230px;
					height: calc(100% - 230px);
				}
			}

			&.-reverse {
				&::before {
					right: 50%;
					transform: translateX(0);
				}
				.campuslife__season-container {
					flex-direction: row-reverse;
				}
				.campuslife__season-title {
					flex-direction: row-reverse;
					&_en {
						margin: 0 0 0 1rem;
					}
					&_ja {
						flex-direction: row-reverse;
					}
				}
			}
			&.-spring {
				&::before {
					background-color: $color-spring;
				}
				.campuslife__season-schedule_title {
					border-color: $color-spring;
					color: $color-spring;
				}
			}
			&.-summer {
				&::before {
					background-color: $color-summer;
				}
				.campuslife__season-schedule_title {
					border-color: $color-summer;
					color: $color-summer;
				}
			}
			&.-autumn {
				&::before {
					background-color: $color-autumn;
				}
				.campuslife__season-schedule_title {
					border-color: $color-autumn;
					color: $color-autumn;
				}
			}
			&.-winter {
				&::before {
					background-color: $color-winter;
				}
				.campuslife__season-schedule_title {
					border-color: $color-winter;
					color: $color-winter;
				}
			}
		}

		&-inner {
			padding: 0 6rem 10rem;
			background-color: $color-white;
			@include media-breakpoint-down(sm) {
				padding: 0 2rem 8rem;
			}
		}

		&-title {
			display: flex;
			align-items: flex-start;
			margin-bottom: 2rem;
			font-weight: bold;
			line-height: 1;
			@include media-breakpoint-down(sm) {
				justify-content: space-between;
				margin: 0 -2rem 1.5rem;
			}
			&_en {
				margin-right: 1rem;
				font-size: 8rem;
				font-family: $font-family-en;
				font-weight: 700;
				@include media-breakpoint-down(sm) {
					font-size: 5rem;
				}
			}
			&_ja {
				display: flex;
				align-items: center;
				margin-top: .5em;
				font-size: 2.4rem;
				@include media-breakpoint-down(sm) {
					font-size: 2rem;
				}
				&::before {
					content: "";
					display: inline-block;
					width: 40px;
					height: 1px;
					margin: 0 10px;
					background-color: $color-black;
				}
			}
		}

		&-mv {
			margin: 0 -6rem 8rem;
			@include media-breakpoint-down(sm) {
				margin: 0 -2rem 4rem;
			}
		}

		&-container {
			@include media-breakpoint-up(md) {
				display: flex;
				margin: 0 -30px;
			}
		}
		
		&-schedule {
			margin-bottom: 4rem;
			@include media-breakpoint-up(md) {
				width: 50%;
				margin: 0;
				padding: 0 30px;
			}
			&_item {
				display: flex;
				&:not(:last-child) {
					margin-bottom: 4rem;
				}
			}
			&_title {
				width: 66px;
				margin: 0 30px 0 0;
				border-right: 1px solid $color-black;
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 1.5;
				&.-wide {
					width: 90px;
				}
			}
			&_list {
				flex: 1 0 0%;
				margin: 0;
				padding: 0;
				list-style: none;
				font-weight: bold;
			}
		}

		&-main {
			@include media-breakpoint-up(md) {
				width: 50%;
				padding: 0 30px;
			}
			&_block {
				& + & {
					margin-top: 6rem;
				}
			}
		}
	}
}

/* 学習院大学のここが好き */
.like {
	&__header {
		@include media-breakpoint-up(md) {
			padding-top: $height-header;
		}
	}

	&__mv {
		position: relative;
		position: relative;

		&-content {
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				width: 100%;
				height: 100%;
				text-align: center;
			}
		}

		&-title {
			margin-bottom: 2.5rem;
			text-align: center;
			@include media-breakpoint-up(md) {
				max-width: 47%;
				margin: 0 auto 3.7%;
			}
		}

		&-image {
			margin: 3rem 0 0;
			@include media-breakpoint-up(md) {
				margin: 0 -8px 0 0;
			}
		}
	}
	
	&__section {
		background-color: $color-main-light;

		&-block {
			&:not(:last-child) {
				margin-bottom: 6rem;
			}
		}

		&-header {
			position: relative;
			z-index: 0;
			height: 137px;
			margin: 0 6rem 5rem;
			@include media-breakpoint-down(sm) {
				height: 148px;
				margin: 0 2rem 4rem;
			}
			&::before, &::after {
				content: "";
				position: absolute;
				bottom: -20px;
				z-index: -1;
				display: inline-block;
				width: 80px;
				height: 100%;
				@include media-breakpoint-down(sm) {
					width: 60px;
				}
			}
			&::before {
				left: 20px;
				transform: translateX(-100%);
				background: url(../campuslife/img/like/ribbon_bg_left.svg) no-repeat right bottom / contain;
				@include media-breakpoint-down(sm) {
					background-image: url(../campuslife/img/like/ribbon_bg_left-sp.svg);
				}
			}
			&::after {
				right: 20px;
				transform: translateX(100%);
				background: url(../campuslife/img/like/ribbon_bg_right.svg) no-repeat left bottom / contain;
				@include media-breakpoint-down(sm) {
					background-image: url(../campuslife/img/like/ribbon_bg_right-sp.svg);
				}
			}
		}

		&-title {
			position: relative;
			z-index: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			background-color: $color-main;
			color: $color-white;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.4;
			text-align: center;
			@include media-breakpoint-down(sm) {
				font-size: 2.2rem;
			}
			span {
				color: $color-yellow;
				font-size: 4rem;
				font-family: $font-family-en;
			}
		}

		&-subtitle {
			margin-bottom: 4rem;
			color: $color-main;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.5;
			text-align: center;
			@include media-breakpoint-down(sm) {
				font-size: 2.2rem;
			}
		}

		&-main {
			position: relative;
			z-index: 0;
			padding: 6rem;
			background-color: $color-white;
			@include media-breakpoint-down(sm) {
				padding: 3rem 2rem 4rem;
			}
			&::before, &::after {
				content: "";
				position: absolute;
				z-index: 1;
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
			}
			&::before {
				left: 0;
				top: 0;
				border-color: $color-main-light transparent transparent transparent;
				border-width: 60px 60px 0px 0px;
				@include media-breakpoint-down(sm) {
					border-width: 30px 30px 0px 0px;
				}
			}
			&::after {
				right: 0;
				bottom: 0;
				border-color: transparent transparent $color-main-light transparent;
				border-width: 0px 0px 60px 60px;
				@include media-breakpoint-down(sm) {
					border-width: 0px 0px 30px 30px;
				}
			}
		}
	}

	&__point {
		padding: 6rem 0;

		&-ranking {
			margin: -17px -17px 4rem;
			&_container {
				@include media-breakpoint-up(md) {
					display: flex;
					justify-content: space-around;
				}
			}
			&_item {
				position: relative;
				padding: 17px;
				@include media-breakpoint-up(md) {
					display: inline-block;
				}
				&.-rank1 {
					.like__point-ranking_rank {
						background-image: url(../campuslife/img/like/ranking_ico01.svg);
					}
				}
				&.-rank2 {
					.like__point-ranking_rank {
						background-image: url(../campuslife/img/like/ranking_ico02.svg);
					}
				}
				&.-rank3 {
					.like__point-ranking_rank {
						background-image: url(../campuslife/img/like/ranking_ico03.svg);
					}
				}
			}
			&_inner {
				display: flex;
				align-items: flex-end;
				padding-bottom: 1rem;
				border-bottom: 1px solid $color-black;
			}
			&_rank {
				width: 100px;
				height: 90px;
				margin: 0 2rem 0 0;
				padding-top: 43px;
				background-repeat: no-repeat;
				background-position: center bottom;
				background-size: contain;
				color: $color-white;
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 1;
				text-align: center;
				@include media-breakpoint-down(sm) {
					width: 80px;
					height: 72px;
					margin: 0 2rem 3rem 0;
					padding-top: 36px;
					font-size: 1.7rem;
				}
				& > span {
					font-size: 4rem;
					font-family: $font-family-en;
					@include media-breakpoint-down(sm) {
						font-size: 3.1rem;
					}
				}
			}
			&_title {
				font-size: 3.6rem;
				font-weight: bold;
				line-height: 1.2;
				@include media-breakpoint-down(sm) {
					flex: 1 0 0%;
					font-size: 2.6rem;
				}
			}
			&_num {
				display: inline-block;
				margin-left: 1rem;
				font-size: 2.2rem;
				@include media-breakpoint-down(sm) {
					display: block;
					margin: .5rem 0 0;
					text-align: right;
					font-size: 2rem;
				}
			}
		}

		&-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 80px 60px;
			@include media-breakpoint-down(sm) {
				grid-template-columns: repeat(1, 1fr);
				grid-gap: 40px 0;
			}
			&_item {
				position: relative;
				&.-color1 {
					.like__point-list_message {
						border-color: $color-sub1;
					}
					.like__point-list_point {
						color: $color-sub1;
						&::before, &::after {
							background-color: $color-sub1;
						}
					}
				}
				&.-color2 {
					.like__point-list_message {
						border-color: $color-sub2;
					}
					.like__point-list_point {
						color: $color-sub2;
						&::before, &::after {
							background-color: $color-sub2;
						}
					}
				}
				&.-color3 {
					.like__point-list_message {
						border-color: $color-sub3;
					}
					.like__point-list_point {
						color: $color-sub3;
						&::before, &::after {
							background-color: $color-sub3;
						}
					}
				}
				&.-color4 {
					.like__point-list_message {
						border-color: $color-sub4;
					}
					.like__point-list_point {
						color: $color-sub4;
						&::before, &::after {
							background-color: $color-sub4;
						}
					}
				}
				&.-color5 {
					.like__point-list_message {
						border-color: $color-sub5;
					}
					.like__point-list_point {
						color: $color-sub5;
						&::before, &::after {
							background-color: $color-sub5;
						}
					}
				}
			}
			&_image {
				margin-bottom: 3rem;
			}
			&_message {
				position: relative;
				z-index: 0;
				padding: 1.5rem 2rem;
				background-color: $color-white;
				border: 2px solid $color-black;
				border-radius: 10px;
				&::before, &::after {
					content: "";
					position: absolute;
					left: 50%;
					transform: translateX(-50%);
				}
				&::before {
					top: -23px;
					border: 12px solid transparent;
					border-bottom: 12px solid $color-white;
					z-index: -1;
				}
				&::after {
					top: -28px;
					border: 14px solid transparent;
					border-bottom: 14px solid $color-black;
					border-bottom-color: inherit !important;
					z-index: -2;
				}
			}
			&_point {
				display: flex;
				align-items: flex-end;
				justify-content: center;
				font-weight: bold;
				line-height: 1.4;
				text-align: center;
				@include media-breakpoint-up(md) {
					position: absolute;
					top: 0;
					left: 0;
					transform: translateY(-100%);
					width: 100%;
					margin: 0;
					padding-bottom: 1.2rem;
				}
				&::before, &::after {
					content: "";
					display: inline-block;
					width: 20px;
					height: 1px;
					background-color: $color-black;
				}
				&::before {
					transform: rotate(45deg) translateY(-5px);
					transform-origin: right center;
					margin-right: 10px;
				}
				&::after {
					transform: rotate(-45deg) translateY(-5px);
					transform-origin: left center;
					margin-left: 10px;
				}
			}
		}
	}

	&__spot {
		padding: 0 0 12rem;

		&-ranking {
			margin-bottom: 8rem;
			&_item {
				position: relative;
				@include media-breakpoint-up(md) {
					display: flex;
					align-items: center;
				}
				& + & {
					margin-top: 4rem;
				}
				&.-rank1 {
					.like__spot-ranking_rank {
						background-image: url(../campuslife/img/like/ranking_ico01.svg);
					}
				}
				&.-rank2 {
					.like__spot-ranking_rank {
						background-image: url(../campuslife/img/like/ranking_ico02.svg);
					}
				}
				&.-rank3 {
					.like__spot-ranking_rank {
						background-image: url(../campuslife/img/like/ranking_ico03.svg);
					}
				}
			}
			&_rank {
				display: inline-block;
				vertical-align: bottom;
				width: 100px;
				height: 90px;
				margin: 0 2rem 0 0;
				padding-top: 43px;
				background-repeat: no-repeat;
				background-position: center bottom;
				background-size: contain;
				color: $color-white;
				font-size: 2.2rem;
				font-weight: bold;
				line-height: 1;
				text-align: center;
				@include media-breakpoint-down(sm) {
					width: 80px;
					height: 72px;
					margin: 0 0 7rem;
					padding-top: 36px;
					font-size: 1.7rem;
				}
				& > span {
					font-size: 4rem;
					font-family: $font-family-en;
					@include media-breakpoint-down(sm) {
						font-size: 3.1rem;
					}
				}
			}
			&_content {
				@include media-breakpoint-up(md) {
					flex: 1 0 0%;
				}
			}
			&_image {
				@include media-breakpoint-up(md) {
					width: 20%;
					margin-right: 4rem;
				}
			}
			&_title {
				padding-bottom: 1rem;
				border-bottom: 1px solid $color-black;
				font-size: 3.6rem;
				font-weight: bold;
				line-height: 1.2;
				@include media-breakpoint-down(sm) {
					position: absolute;
					top: 9rem;
					width: 100%;
					padding: 0;
					border-bottom: none;
					font-size: 2.6rem;
				}
			}
			&_num {
				margin-left: 1rem;
				font-size: 2.2rem;
				@include media-breakpoint-down(sm) {
					position: absolute;
					right: 0;
					top: -4rem;
					font-size: 2rem;
				}
			}
		}
	}

	&__sakuma {
		margin-top: 6rem;
		padding-bottom: 6rem;
		@include media-breakpoint-down(sm) {
			padding-bottom: 12rem;
		}
		&-text {
			position: relative;
			margin-bottom: 3.8rem;
			padding: 6rem;
			background-color: $color-main2;
			border-radius: 20px;
			color: $color-white;
			font-size: 2.8rem;
			font-weight: bold;
			line-height: 1.7;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding: 3rem 2rem 4rem;
				font-size: 2.2rem;
			}
			@include media-breakpoint-down(xs) {
				font-size: 5.8vw;
			}
			&::before {
				position: absolute;
				left: 50%;
				bottom: 1px;
				transform: translate(-50%, 100%);
				content: "";
				display: inline-block;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 20px 20px 0 20px;
				border-color: $color-main2 transparent transparent transparent;
			}
		}
		&-image {
			width: 120px;
			margin: 0 auto;
		}
	}
}


/* がんばれ受験生 */
.study{
	&__header{
		background: linear-gradient(#26bcff 0%, #8afaed 100%);
		.breadcrumb{
			padding-bottom: 30px;
			z-index: 1;
			position: relative;
		}
	}
	&__mv{
		max-width: 1400px;
		margin: -50px auto 6rem;
	}
	&__movie{
		.box{
			display: flex;
			gap: 60px;
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				gap: 30px;
			}
		}
		margin-bottom: 100px;
	}
	&__accordion{
		position: relative;
		z-index: 1;
		&::before{
			content: "";
			background-image: url(/campuslife/img/study/parts-1.svg);
			width: 556px;
			height: 558px;
			position: absolute;
			top: -150px;
			right: -100px;
			z-index: -1;
			@include media-breakpoint-down(sm) {
				right: -300px;
			}
		}
		&::after{
			content: "";
			background-image: url(/campuslife/img/study/parts-1.svg);
			width: 556px;
			height: 558px;
			position: absolute;
			top: 350px;
			left: -150px;
			z-index: -1;
			@include media-breakpoint-down(sm) {
				top: 550px;
				left: -300px;
			}
		}
		.inner{
			@include media-breakpoint-down(md) {
				padding-left: 70px;
			}
			@include media-breakpoint-down(sm) {
				padding-left: 2rem;
			}
		}
		&-item{
			border-radius: 10px;
			box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
			margin-bottom: 40px;
			background-color: $color-white;
			@include media-breakpoint-down(sm) {
				margin-bottom: 80px;
			}
			&.item{
				&-1{
					.accordion-head .icon{
						background: linear-gradient(45deg,#A0EBFF 0%, #8AFAED 100%);
					}
					.content-text{
						border: 2px solid #A0EBFF;
					}
				}
				&-2{
					.accordion-head .icon{
						background: linear-gradient(45deg,#C6B5F4 0%, #849AE8 100%);
					}
					.content-text{
						border: 2px solid #849AE8;
					}
				}
				&-3{
					.accordion-head .icon{
						background: linear-gradient(45deg,#003C87 0%, #5513AF 100%);
					}
					.content-text{
						border: 2px solid #5513AF;
					}
				}

				
			}
			.accordion-head{
				display: flex;
				align-items: center;
				gap: 30px;
				padding: 20px 70px 20px 20px;
				font-size: 2.8rem;
				font-weight: bold;
				position: relative;
				line-height: 1.2;
				@include media-breakpoint-down(sm) {
					display: block;
					position: relative;
					padding: 70px 70px 20px 20px;
					font-size: 2.4rem;
				}
				.icon{
					width: 100px;
					height: 100px;
					color: $color-white;
					font-family: $font-family-en;
					line-height: 1;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					margin-left: -65px;
					@include media-breakpoint-down(sm) {
						position: absolute;
						left: 50%;
						transform: translateX(-50%);
						top: -50px;
						margin: 0;
					}
					.en{
						font-size: 2rem;
					}
					.math{
						font-size: 4rem;
						display: block;
					}
				}

				&::before{
					content: "";
					height: 30px;
					width: 2px;
					background: $color-main;
					position: absolute;
					right: 45px;
					top: 50%;
					transform: translateY(-50%);
				}
				&::after{
					content: "";
					width: 30px;
					height: 2px;
					background: $color-main;
					position: absolute;
					right: 30px;
					top: 50%;
					transform: translateY(-50%);
				}

				&[aria-expanded="true"]{
					&::before{
						content: none;
					}
				}
				
			}
			.accordion-content{
				background: $color-main-light;
				position: relative;
				overflow: hidden;
				border-radius: 0 0 10px 10px;
				&::after{
					content: "";
					width: 100%;
					top: -10px;
					bottom: 0px;
					position: absolute;
					box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
				}
				.content{
					padding: 40px 20px;
					.box{
						display: flex;
						gap: 30px;
						align-items: center;
						margin-bottom: 20px;
						@include media-breakpoint-down(sm) {
							display: block;
							margin-bottom: 40px;
						}
						&:nth-of-type(odd){
							.content-text{
								&::before {
									left: -23px;
									border-right: 12px solid $color-white;
								}
								&::after {
									left: -28px;
									border: 14px solid transparent;
									border-right: 14px solid $color-black;
									border-right-color: inherit !important;
								}
							}
						}
						&:nth-of-type(even){
							flex-direction: row-reverse;
							.content-text{
								&::before {
									right: -23px;
									border-left: 12px solid $color-white;
								}
								&::after {
									right: -28px;
									border: 14px solid transparent;
									border-left: 14px solid $color-black;
									border-left-color: inherit !important;
								}
							}
						}
					}
					&-img{
						flex: 0 0 200px;
						@include media-breakpoint-down(sm) {
							text-align: center;
							margin-bottom: 30px;
						}
					}
					&-text{
						position: relative;
						z-index: 0;
						padding: 1.5rem 4rem;
						background-color: $color-white;
						border-radius: 10px;
						&::before, &::after {
							content: "";
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
						}
						&::before {
							border: 12px solid transparent;
							z-index: -1;
							@include media-breakpoint-down(sm) {
								top: -23px;
								left: 50%!important;
								transform: translateX(-50%)rotate(95deg)!important;;
							}
						}
						&::after {
							border: 14px solid transparent;
							z-index: -2;
							@include media-breakpoint-down(sm) {
								top: -28px;
								left: 50%!important;;
								transform: translateX(-50%)rotate(95deg)!important;;
							}
						}
					}
				}
			}
		}
	}
}


/* キャンパススナップ */
.snap{
	&__header{
		.breadcrumb{
			padding-bottom: 30px;
			z-index: 1;
			position: relative;
		}
	}
	&__mv{
		max-width: 1400px;
		margin: 0 auto 60px;
	}
	&__slide{
		margin-top: 30px;
		.slick-track {
			margin: 0 !important;
		}
		.slick-prev, .slick-next {
			top: 200px;
			width: 35px;
			height: 35px;
		}
		.slick-prev {
			left: -30px;
			@include media-breakpoint-down(sm) {
				left: -20px;
			}
		}
		.slick-next {
			right: -30px;
			@include media-breakpoint-down(sm) {
				right: -20px;
			}
		}
		.slick-prev:before, .slick-next:before {
			color: $color-main;
			font-size: 3.5rem;
			font-family: 'Line Awesome Free';
			font-weight: 900;
		}
		.slick-prev:before {
			content: "\f104";
		}
		.slick-next:before {
			content: "\f105";
		}
		.slider-snap{
			border-bottom: 1px dashed $color-black;
			margin-bottom: 60px;
			padding-bottom: 60px;
			@include media-breakpoint-down(sm) {
				margin-bottom: 30px;
				padding-bottom: 20px;
			}
			&:last-of-type{
				border-bottom:none;
				margin-bottom: 0px;
				padding-bottom: 0px
			}
		}
		.item{
			display: flex;
			align-items: flex-start;
			gap: 20px;
			padding: 10px 20px;
			@include media-breakpoint-down(sm) {
				display: block;
			}
			.left{
				width: 57%;
				padding: 15px 20px;
				box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
				position: relative;
				@include media-breakpoint-down(sm) {
					width: 100%;
				}
				.profile{
					margin-top: 10px;
					text-align: right;
					&-name{
						font-weight: bold;
						font-size: 1.4rem;
						margin-bottom: 5px;
					}
					&-detail{
						font-size: 1.3rem;
					}
				}
				.icon{
					font-size: 2.8rem;
					font-family: $font-family-en;
					font-weight: bold;
					position: absolute;
					top: 0px;
					left: 5px;
					margin: 0.5rem;
					padding: 2px 10px;
					outline-offset: -5px;
					background-image:
						var(--x-gradient),
						var(--y-gradient),
						var(--x-gradient),
						var(--y-gradient);
					background-repeat: no-repeat;
					background-size: 100% 5px, 5px 100%, 100% 5px, 5px 100%;
					background-position: top, right, bottom, left;
				}
			}
			.right{
				width: 43%;
				padding: 10px 0;
				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-top: 20px;
				}
				.comment{
					&-title{
						font-weight: bold;
						line-height: 1.2;
					}
					&-text{
						font-size: 1.4rem;
						line-height: 1.6;
					}
				}
				.box{
					padding-bottom: 15px;
					&:last-of-type{
						&::after{
							content: none;
						}
					}
					&::after{
						content: "";
						width: 30px;
						height: 5px;
						display: block;
						margin-top: 15px;
					}
				}
			}
			

			&.color-1{
				.left{
					.icon{
						color: #78E3FD;
						--x-gradient: linear-gradient(90deg, #78E3FD 0 15px, transparent 0 calc(100% - 15px), #78E3FD calc(100% - 15px));
						--y-gradient: linear-gradient(#78E3FD 0 15px, transparent 0 calc(100% - 15px), #78E3FD calc(100% - 15px));	
					}
				}
				.right{
					.box{
						&::after{
							background-color: #78E3FD;
						}
					}
				}
			}

			&.color-2{
				.left{
					.icon{
						color: #78A1FD;
						--x-gradient: linear-gradient(90deg, #78A1FD 0 15px, transparent 0 calc(100% - 15px), #78A1FD calc(100% - 15px));
						--y-gradient: linear-gradient(#78A1FD 0 15px, transparent 0 calc(100% - 15px), #78A1FD calc(100% - 15px));	
					}
				}
				.right{
					.box{
						&::after{
							background-color: #78A1FD;
						}
					}
				}
			}

			&.color-3{
				.left{
					.icon{
						color: #00FFD8;
						--x-gradient: linear-gradient(90deg, #00FFD8 0 15px, transparent 0 calc(100% - 15px), #00FFD8 calc(100% - 15px));
						--y-gradient: linear-gradient(#00FFD8 0 15px, transparent 0 calc(100% - 15px), #00FFD8 calc(100% - 15px));	
					}
				}
				.right{
					.box{
						&::after{
							background-color: #00FFD8;
						}
					}
				}
			}

			&.color-4{
				.left{
					.icon{
						color: #7A62FF;
						--x-gradient: linear-gradient(90deg, #7A62FF 0 15px, transparent 0 calc(100% - 15px), #7A62FF calc(100% - 15px));
						--y-gradient: linear-gradient(#7A62FF 0 15px, transparent 0 calc(100% - 15px), #7A62FF calc(100% - 15px));	
					}
				}
				.right{
					.box{
						&::after{
							background-color: #7A62FF;
						}
					}
				}
			}
		}
	}
}