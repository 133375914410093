@charset "UTF-8";
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 1080px;
  --breakpoint-xl: 1367px;
  --font-family-sans-serif: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.88;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.3; }

h1, .h1 {
  font-size: 4.4rem; }

h2, .h2 {
  font-size: 3.2rem; }

h3, .h3 {
  font-size: 2.8rem; }

h4, .h4 {
  font-size: 2.08rem; }

h5, .h5 {
  font-size: 1.92rem; }

h6, .h6 {
  font-size: 1.6rem; }

.lead {
  font-size: 3.2rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.3; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.3; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.3; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.3; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 2rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 769px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 1080px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1367px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 769px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1080px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1367px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #aaa; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #aaa; }
  .table tbody + tbody {
    border-top: 2px solid #aaa; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #aaa; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #aaa; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #aaa; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1079.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1366.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.88em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.88;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.88; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 2rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 1.4rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1.6rem;
  line-height: 1.88;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.4rem;
  line-height: 1.88;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.88em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.47em + 0.1875rem) center;
  background-size: calc(0.94em + 0.375rem) calc(0.94em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.88em + 0.75rem);
  background-position: top calc(0.47em + 0.1875rem) right calc(0.47em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.94em + 0.375rem) calc(0.94em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 1.4rem;
  line-height: 1.88;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.88em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.47em + 0.1875rem) center;
  background-size: calc(0.94em + 0.375rem) calc(0.94em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.88em + 0.75rem);
  background-position: top calc(0.47em + 0.1875rem) right calc(0.47em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.94em + 0.375rem) calc(0.94em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1.6rem;
  line-height: 1.88;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1.6rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1080px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1367px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 1.4rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.88;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 2rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 3.008rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 2.004rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 1.004rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 1.004rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(1.004rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.88em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.88;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 1.4rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 2rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.88em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.88em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.88em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.88;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.88em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.88;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.124rem;
  padding-bottom: 0.124rem;
  margin-right: 1rem;
  font-size: 2rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1079.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1080px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1366.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1367px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 2rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 1.4rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4.9rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 1.2rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1080px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1367px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.88; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1080px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1367px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.88;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.88;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1.4rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.6rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1080px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1367px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1080px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1367px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1080px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1367px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-15 {
  height: 15% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-35 {
  height: 35% !important; }

.h-40 {
  height: 40% !important; }

.h-45 {
  height: 45% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1080px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1367px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1080px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1367px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media screen and (min-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
  .breadcrumb {
    padding-right: constant(safe-area-inset-right);
    padding-left: constant(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left); } }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-20 {
  width: 20% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-60 {
  width: 60% !important; }

.w-70 {
  width: 70% !important; }

.w-80 {
  width: 80% !important; }

.w-90 {
  width: 90% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.h-5 {
  height: 5% !important; }

.h-10 {
  height: 10% !important; }

.h-15 {
  height: 15% !important; }

.h-20 {
  height: 20% !important; }

.h-30 {
  height: 30% !important; }

.h-35 {
  height: 35% !important; }

.h-40 {
  height: 40% !important; }

.h-45 {
  height: 45% !important; }

.h-60 {
  height: 60% !important; }

.h-70 {
  height: 70% !important; }

.h-80 {
  height: 80% !important; }

.h-90 {
  height: 90% !important; }

@media (min-width: 576px) {
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .w-sm-5 {
    width: 5% !important; }
  .w-sm-10 {
    width: 10% !important; }
  .w-sm-15 {
    width: 15% !important; }
  .w-sm-20 {
    width: 20% !important; }
  .w-sm-30 {
    width: 30% !important; }
  .w-sm-35 {
    width: 35% !important; }
  .w-sm-40 {
    width: 40% !important; }
  .w-sm-45 {
    width: 45% !important; }
  .w-sm-60 {
    width: 60% !important; }
  .w-sm-70 {
    width: 70% !important; }
  .w-sm-80 {
    width: 80% !important; }
  .w-sm-90 {
    width: 90% !important; }
  .h-sm-25 {
    height: 25% !important; }
  .h-sm-50 {
    height: 50% !important; }
  .h-sm-75 {
    height: 75% !important; }
  .h-sm-100 {
    height: 100% !important; }
  .h-sm-auto {
    height: auto !important; }
  .h-sm-5 {
    height: 5% !important; }
  .h-sm-10 {
    height: 10% !important; }
  .h-sm-15 {
    height: 15% !important; }
  .h-sm-20 {
    height: 20% !important; }
  .h-sm-30 {
    height: 30% !important; }
  .h-sm-35 {
    height: 35% !important; }
  .h-sm-40 {
    height: 40% !important; }
  .h-sm-45 {
    height: 45% !important; }
  .h-sm-60 {
    height: 60% !important; }
  .h-sm-70 {
    height: 70% !important; }
  .h-sm-80 {
    height: 80% !important; }
  .h-sm-90 {
    height: 90% !important; } }

@media (min-width: 769px) {
  .w-md-25 {
    width: 25% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .w-md-5 {
    width: 5% !important; }
  .w-md-10 {
    width: 10% !important; }
  .w-md-15 {
    width: 15% !important; }
  .w-md-20 {
    width: 20% !important; }
  .w-md-30 {
    width: 30% !important; }
  .w-md-35 {
    width: 35% !important; }
  .w-md-40 {
    width: 40% !important; }
  .w-md-45 {
    width: 45% !important; }
  .w-md-60 {
    width: 60% !important; }
  .w-md-70 {
    width: 70% !important; }
  .w-md-80 {
    width: 80% !important; }
  .w-md-90 {
    width: 90% !important; }
  .h-md-25 {
    height: 25% !important; }
  .h-md-50 {
    height: 50% !important; }
  .h-md-75 {
    height: 75% !important; }
  .h-md-100 {
    height: 100% !important; }
  .h-md-auto {
    height: auto !important; }
  .h-md-5 {
    height: 5% !important; }
  .h-md-10 {
    height: 10% !important; }
  .h-md-15 {
    height: 15% !important; }
  .h-md-20 {
    height: 20% !important; }
  .h-md-30 {
    height: 30% !important; }
  .h-md-35 {
    height: 35% !important; }
  .h-md-40 {
    height: 40% !important; }
  .h-md-45 {
    height: 45% !important; }
  .h-md-60 {
    height: 60% !important; }
  .h-md-70 {
    height: 70% !important; }
  .h-md-80 {
    height: 80% !important; }
  .h-md-90 {
    height: 90% !important; } }

@media (min-width: 1080px) {
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .w-lg-5 {
    width: 5% !important; }
  .w-lg-10 {
    width: 10% !important; }
  .w-lg-15 {
    width: 15% !important; }
  .w-lg-20 {
    width: 20% !important; }
  .w-lg-30 {
    width: 30% !important; }
  .w-lg-35 {
    width: 35% !important; }
  .w-lg-40 {
    width: 40% !important; }
  .w-lg-45 {
    width: 45% !important; }
  .w-lg-60 {
    width: 60% !important; }
  .w-lg-70 {
    width: 70% !important; }
  .w-lg-80 {
    width: 80% !important; }
  .w-lg-90 {
    width: 90% !important; }
  .h-lg-25 {
    height: 25% !important; }
  .h-lg-50 {
    height: 50% !important; }
  .h-lg-75 {
    height: 75% !important; }
  .h-lg-100 {
    height: 100% !important; }
  .h-lg-auto {
    height: auto !important; }
  .h-lg-5 {
    height: 5% !important; }
  .h-lg-10 {
    height: 10% !important; }
  .h-lg-15 {
    height: 15% !important; }
  .h-lg-20 {
    height: 20% !important; }
  .h-lg-30 {
    height: 30% !important; }
  .h-lg-35 {
    height: 35% !important; }
  .h-lg-40 {
    height: 40% !important; }
  .h-lg-45 {
    height: 45% !important; }
  .h-lg-60 {
    height: 60% !important; }
  .h-lg-70 {
    height: 70% !important; }
  .h-lg-80 {
    height: 80% !important; }
  .h-lg-90 {
    height: 90% !important; } }

@media (min-width: 1367px) {
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .w-xl-5 {
    width: 5% !important; }
  .w-xl-10 {
    width: 10% !important; }
  .w-xl-15 {
    width: 15% !important; }
  .w-xl-20 {
    width: 20% !important; }
  .w-xl-30 {
    width: 30% !important; }
  .w-xl-35 {
    width: 35% !important; }
  .w-xl-40 {
    width: 40% !important; }
  .w-xl-45 {
    width: 45% !important; }
  .w-xl-60 {
    width: 60% !important; }
  .w-xl-70 {
    width: 70% !important; }
  .w-xl-80 {
    width: 80% !important; }
  .w-xl-90 {
    width: 90% !important; }
  .h-xl-25 {
    height: 25% !important; }
  .h-xl-50 {
    height: 50% !important; }
  .h-xl-75 {
    height: 75% !important; }
  .h-xl-100 {
    height: 100% !important; }
  .h-xl-auto {
    height: auto !important; }
  .h-xl-5 {
    height: 5% !important; }
  .h-xl-10 {
    height: 10% !important; }
  .h-xl-15 {
    height: 15% !important; }
  .h-xl-20 {
    height: 20% !important; }
  .h-xl-30 {
    height: 30% !important; }
  .h-xl-35 {
    height: 35% !important; }
  .h-xl-40 {
    height: 40% !important; }
  .h-xl-45 {
    height: 45% !important; }
  .h-xl-60 {
    height: 60% !important; }
  .h-xl-70 {
    height: 70% !important; }
  .h-xl-80 {
    height: 80% !important; }
  .h-xl-90 {
    height: 90% !important; } }

.w-em-1 {
  width: 1em !important; }

.w-em-2 {
  width: 2em !important; }

.w-em-3 {
  width: 3em !important; }

.w-em-4 {
  width: 4em !important; }

.w-em-5 {
  width: 5em !important; }

.w-em-6 {
  width: 6em !important; }

.w-em-7 {
  width: 7em !important; }

.w-em-8 {
  width: 8em !important; }

.w-em-9 {
  width: 9em !important; }

.w-em-10 {
  width: 10em !important; }

.h-em-1 {
  height: 1em !important; }

.h-em-2 {
  height: 2em !important; }

.h-em-3 {
  height: 3em !important; }

.h-em-4 {
  height: 4em !important; }

.h-em-5 {
  height: 5em !important; }

.h-em-6 {
  height: 6em !important; }

.h-em-7 {
  height: 7em !important; }

.h-em-8 {
  height: 8em !important; }

.h-em-9 {
  height: 9em !important; }

.h-em-10 {
  height: 10em !important; }

@media (min-width: 576px) {
  .w-em-sm-1 {
    width: 1em !important; }
  .w-em-sm-2 {
    width: 2em !important; }
  .w-em-sm-3 {
    width: 3em !important; }
  .w-em-sm-4 {
    width: 4em !important; }
  .w-em-sm-5 {
    width: 5em !important; }
  .w-em-sm-6 {
    width: 6em !important; }
  .w-em-sm-7 {
    width: 7em !important; }
  .w-em-sm-8 {
    width: 8em !important; }
  .w-em-sm-9 {
    width: 9em !important; }
  .w-em-sm-10 {
    width: 10em !important; }
  .h-em-sm-1 {
    height: 1em !important; }
  .h-em-sm-2 {
    height: 2em !important; }
  .h-em-sm-3 {
    height: 3em !important; }
  .h-em-sm-4 {
    height: 4em !important; }
  .h-em-sm-5 {
    height: 5em !important; }
  .h-em-sm-6 {
    height: 6em !important; }
  .h-em-sm-7 {
    height: 7em !important; }
  .h-em-sm-8 {
    height: 8em !important; }
  .h-em-sm-9 {
    height: 9em !important; }
  .h-em-sm-10 {
    height: 10em !important; } }

@media (min-width: 769px) {
  .w-em-md-1 {
    width: 1em !important; }
  .w-em-md-2 {
    width: 2em !important; }
  .w-em-md-3 {
    width: 3em !important; }
  .w-em-md-4 {
    width: 4em !important; }
  .w-em-md-5 {
    width: 5em !important; }
  .w-em-md-6 {
    width: 6em !important; }
  .w-em-md-7 {
    width: 7em !important; }
  .w-em-md-8 {
    width: 8em !important; }
  .w-em-md-9 {
    width: 9em !important; }
  .w-em-md-10 {
    width: 10em !important; }
  .h-em-md-1 {
    height: 1em !important; }
  .h-em-md-2 {
    height: 2em !important; }
  .h-em-md-3 {
    height: 3em !important; }
  .h-em-md-4 {
    height: 4em !important; }
  .h-em-md-5 {
    height: 5em !important; }
  .h-em-md-6 {
    height: 6em !important; }
  .h-em-md-7 {
    height: 7em !important; }
  .h-em-md-8 {
    height: 8em !important; }
  .h-em-md-9 {
    height: 9em !important; }
  .h-em-md-10 {
    height: 10em !important; } }

@media (min-width: 1080px) {
  .w-em-lg-1 {
    width: 1em !important; }
  .w-em-lg-2 {
    width: 2em !important; }
  .w-em-lg-3 {
    width: 3em !important; }
  .w-em-lg-4 {
    width: 4em !important; }
  .w-em-lg-5 {
    width: 5em !important; }
  .w-em-lg-6 {
    width: 6em !important; }
  .w-em-lg-7 {
    width: 7em !important; }
  .w-em-lg-8 {
    width: 8em !important; }
  .w-em-lg-9 {
    width: 9em !important; }
  .w-em-lg-10 {
    width: 10em !important; }
  .h-em-lg-1 {
    height: 1em !important; }
  .h-em-lg-2 {
    height: 2em !important; }
  .h-em-lg-3 {
    height: 3em !important; }
  .h-em-lg-4 {
    height: 4em !important; }
  .h-em-lg-5 {
    height: 5em !important; }
  .h-em-lg-6 {
    height: 6em !important; }
  .h-em-lg-7 {
    height: 7em !important; }
  .h-em-lg-8 {
    height: 8em !important; }
  .h-em-lg-9 {
    height: 9em !important; }
  .h-em-lg-10 {
    height: 10em !important; } }

@media (min-width: 1367px) {
  .w-em-xl-1 {
    width: 1em !important; }
  .w-em-xl-2 {
    width: 2em !important; }
  .w-em-xl-3 {
    width: 3em !important; }
  .w-em-xl-4 {
    width: 4em !important; }
  .w-em-xl-5 {
    width: 5em !important; }
  .w-em-xl-6 {
    width: 6em !important; }
  .w-em-xl-7 {
    width: 7em !important; }
  .w-em-xl-8 {
    width: 8em !important; }
  .w-em-xl-9 {
    width: 9em !important; }
  .w-em-xl-10 {
    width: 10em !important; }
  .h-em-xl-1 {
    height: 1em !important; }
  .h-em-xl-2 {
    height: 2em !important; }
  .h-em-xl-3 {
    height: 3em !important; }
  .h-em-xl-4 {
    height: 4em !important; }
  .h-em-xl-5 {
    height: 5em !important; }
  .h-em-xl-6 {
    height: 6em !important; }
  .h-em-xl-7 {
    height: 7em !important; }
  .h-em-xl-8 {
    height: 8em !important; }
  .h-em-xl-9 {
    height: 9em !important; }
  .h-em-xl-10 {
    height: 10em !important; } }

.mt-0 {
  margin-top: 0px !important; }

.mt-n0 {
  margin-top: 0px !important; }

.mt-5 {
  margin-top: 5px !important; }

.mt-n5 {
  margin-top: -5px !important; }

.mt-10 {
  margin-top: 10px !important; }

.mt-n10 {
  margin-top: -10px !important; }

.mt-15 {
  margin-top: 15px !important; }

.mt-n15 {
  margin-top: -15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.mt-n20 {
  margin-top: -20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.mt-n25 {
  margin-top: -25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-n30 {
  margin-top: -30px !important; }

.mt-35 {
  margin-top: 35px !important; }

.mt-n35 {
  margin-top: -35px !important; }

.mt-40 {
  margin-top: 40px !important; }

.mt-n40 {
  margin-top: -40px !important; }

.mt-45 {
  margin-top: 45px !important; }

.mt-n45 {
  margin-top: -45px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-n50 {
  margin-top: -50px !important; }

.mt-55 {
  margin-top: 55px !important; }

.mt-n55 {
  margin-top: -55px !important; }

.mt-60 {
  margin-top: 60px !important; }

.mt-n60 {
  margin-top: -60px !important; }

.mt-65 {
  margin-top: 65px !important; }

.mt-n65 {
  margin-top: -65px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-n70 {
  margin-top: -70px !important; }

.mt-75 {
  margin-top: 75px !important; }

.mt-n75 {
  margin-top: -75px !important; }

.mt-80 {
  margin-top: 80px !important; }

.mt-n80 {
  margin-top: -80px !important; }

.mt-85 {
  margin-top: 85px !important; }

.mt-n85 {
  margin-top: -85px !important; }

.mt-90 {
  margin-top: 90px !important; }

.mt-n90 {
  margin-top: -90px !important; }

.mt-95 {
  margin-top: 95px !important; }

.mt-n95 {
  margin-top: -95px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mt-n100 {
  margin-top: -100px !important; }

.mr-0 {
  margin-right: 0px !important; }

.mr-n0 {
  margin-right: 0px !important; }

.mr-5 {
  margin-right: 5px !important; }

.mr-n5 {
  margin-right: -5px !important; }

.mr-10 {
  margin-right: 10px !important; }

.mr-n10 {
  margin-right: -10px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-n15 {
  margin-right: -15px !important; }

.mr-20 {
  margin-right: 20px !important; }

.mr-n20 {
  margin-right: -20px !important; }

.mr-25 {
  margin-right: 25px !important; }

.mr-n25 {
  margin-right: -25px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-n30 {
  margin-right: -30px !important; }

.mr-35 {
  margin-right: 35px !important; }

.mr-n35 {
  margin-right: -35px !important; }

.mr-40 {
  margin-right: 40px !important; }

.mr-n40 {
  margin-right: -40px !important; }

.mr-45 {
  margin-right: 45px !important; }

.mr-n45 {
  margin-right: -45px !important; }

.mr-50 {
  margin-right: 50px !important; }

.mr-n50 {
  margin-right: -50px !important; }

.mr-55 {
  margin-right: 55px !important; }

.mr-n55 {
  margin-right: -55px !important; }

.mr-60 {
  margin-right: 60px !important; }

.mr-n60 {
  margin-right: -60px !important; }

.mr-65 {
  margin-right: 65px !important; }

.mr-n65 {
  margin-right: -65px !important; }

.mr-70 {
  margin-right: 70px !important; }

.mr-n70 {
  margin-right: -70px !important; }

.mr-75 {
  margin-right: 75px !important; }

.mr-n75 {
  margin-right: -75px !important; }

.mr-80 {
  margin-right: 80px !important; }

.mr-n80 {
  margin-right: -80px !important; }

.mr-85 {
  margin-right: 85px !important; }

.mr-n85 {
  margin-right: -85px !important; }

.mr-90 {
  margin-right: 90px !important; }

.mr-n90 {
  margin-right: -90px !important; }

.mr-95 {
  margin-right: 95px !important; }

.mr-n95 {
  margin-right: -95px !important; }

.mr-100 {
  margin-right: 100px !important; }

.mr-n100 {
  margin-right: -100px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.mb-n0 {
  margin-bottom: 0px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.mb-n5 {
  margin-bottom: -5px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.mb-n10 {
  margin-bottom: -10px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-n15 {
  margin-bottom: -15px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.mb-n20 {
  margin-bottom: -20px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.mb-n25 {
  margin-bottom: -25px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-n30 {
  margin-bottom: -30px !important; }

.mb-35 {
  margin-bottom: 35px !important; }

.mb-n35 {
  margin-bottom: -35px !important; }

.mb-40 {
  margin-bottom: 40px !important; }

.mb-n40 {
  margin-bottom: -40px !important; }

.mb-45 {
  margin-bottom: 45px !important; }

.mb-n45 {
  margin-bottom: -45px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-n50 {
  margin-bottom: -50px !important; }

.mb-55 {
  margin-bottom: 55px !important; }

.mb-n55 {
  margin-bottom: -55px !important; }

.mb-60 {
  margin-bottom: 60px !important; }

.mb-n60 {
  margin-bottom: -60px !important; }

.mb-65 {
  margin-bottom: 65px !important; }

.mb-n65 {
  margin-bottom: -65px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-n70 {
  margin-bottom: -70px !important; }

.mb-75 {
  margin-bottom: 75px !important; }

.mb-n75 {
  margin-bottom: -75px !important; }

.mb-80 {
  margin-bottom: 80px !important; }

.mb-n80 {
  margin-bottom: -80px !important; }

.mb-85 {
  margin-bottom: 85px !important; }

.mb-n85 {
  margin-bottom: -85px !important; }

.mb-90 {
  margin-bottom: 90px !important; }

.mb-n90 {
  margin-bottom: -90px !important; }

.mb-95 {
  margin-bottom: 95px !important; }

.mb-n95 {
  margin-bottom: -95px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.mb-n100 {
  margin-bottom: -100px !important; }

.ml-0 {
  margin-left: 0px !important; }

.ml-n0 {
  margin-left: 0px !important; }

.ml-5 {
  margin-left: 5px !important; }

.ml-n5 {
  margin-left: -5px !important; }

.ml-10 {
  margin-left: 10px !important; }

.ml-n10 {
  margin-left: -10px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-n15 {
  margin-left: -15px !important; }

.ml-20 {
  margin-left: 20px !important; }

.ml-n20 {
  margin-left: -20px !important; }

.ml-25 {
  margin-left: 25px !important; }

.ml-n25 {
  margin-left: -25px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-n30 {
  margin-left: -30px !important; }

.ml-35 {
  margin-left: 35px !important; }

.ml-n35 {
  margin-left: -35px !important; }

.ml-40 {
  margin-left: 40px !important; }

.ml-n40 {
  margin-left: -40px !important; }

.ml-45 {
  margin-left: 45px !important; }

.ml-n45 {
  margin-left: -45px !important; }

.ml-50 {
  margin-left: 50px !important; }

.ml-n50 {
  margin-left: -50px !important; }

.ml-55 {
  margin-left: 55px !important; }

.ml-n55 {
  margin-left: -55px !important; }

.ml-60 {
  margin-left: 60px !important; }

.ml-n60 {
  margin-left: -60px !important; }

.ml-65 {
  margin-left: 65px !important; }

.ml-n65 {
  margin-left: -65px !important; }

.ml-70 {
  margin-left: 70px !important; }

.ml-n70 {
  margin-left: -70px !important; }

.ml-75 {
  margin-left: 75px !important; }

.ml-n75 {
  margin-left: -75px !important; }

.ml-80 {
  margin-left: 80px !important; }

.ml-n80 {
  margin-left: -80px !important; }

.ml-85 {
  margin-left: 85px !important; }

.ml-n85 {
  margin-left: -85px !important; }

.ml-90 {
  margin-left: 90px !important; }

.ml-n90 {
  margin-left: -90px !important; }

.ml-95 {
  margin-left: 95px !important; }

.ml-n95 {
  margin-left: -95px !important; }

.ml-100 {
  margin-left: 100px !important; }

.ml-n100 {
  margin-left: -100px !important; }

.pt-0 {
  padding-top: 0px !important; }

.pt-n0 {
  padding-top: 0px !important; }

.pt-5 {
  padding-top: 5px !important; }

.pt-n5 {
  padding-top: -5px !important; }

.pt-10 {
  padding-top: 10px !important; }

.pt-n10 {
  padding-top: -10px !important; }

.pt-15 {
  padding-top: 15px !important; }

.pt-n15 {
  padding-top: -15px !important; }

.pt-20 {
  padding-top: 20px !important; }

.pt-n20 {
  padding-top: -20px !important; }

.pt-25 {
  padding-top: 25px !important; }

.pt-n25 {
  padding-top: -25px !important; }

.pt-30 {
  padding-top: 30px !important; }

.pt-n30 {
  padding-top: -30px !important; }

.pt-35 {
  padding-top: 35px !important; }

.pt-n35 {
  padding-top: -35px !important; }

.pt-40 {
  padding-top: 40px !important; }

.pt-n40 {
  padding-top: -40px !important; }

.pt-45 {
  padding-top: 45px !important; }

.pt-n45 {
  padding-top: -45px !important; }

.pt-50 {
  padding-top: 50px !important; }

.pt-n50 {
  padding-top: -50px !important; }

.pt-55 {
  padding-top: 55px !important; }

.pt-n55 {
  padding-top: -55px !important; }

.pt-60 {
  padding-top: 60px !important; }

.pt-n60 {
  padding-top: -60px !important; }

.pt-65 {
  padding-top: 65px !important; }

.pt-n65 {
  padding-top: -65px !important; }

.pt-70 {
  padding-top: 70px !important; }

.pt-n70 {
  padding-top: -70px !important; }

.pt-75 {
  padding-top: 75px !important; }

.pt-n75 {
  padding-top: -75px !important; }

.pt-80 {
  padding-top: 80px !important; }

.pt-n80 {
  padding-top: -80px !important; }

.pt-85 {
  padding-top: 85px !important; }

.pt-n85 {
  padding-top: -85px !important; }

.pt-90 {
  padding-top: 90px !important; }

.pt-n90 {
  padding-top: -90px !important; }

.pt-95 {
  padding-top: 95px !important; }

.pt-n95 {
  padding-top: -95px !important; }

.pt-100 {
  padding-top: 100px !important; }

.pt-n100 {
  padding-top: -100px !important; }

.pr-0 {
  padding-right: 0px !important; }

.pr-n0 {
  padding-right: 0px !important; }

.pr-5 {
  padding-right: 5px !important; }

.pr-n5 {
  padding-right: -5px !important; }

.pr-10 {
  padding-right: 10px !important; }

.pr-n10 {
  padding-right: -10px !important; }

.pr-15 {
  padding-right: 15px !important; }

.pr-n15 {
  padding-right: -15px !important; }

.pr-20 {
  padding-right: 20px !important; }

.pr-n20 {
  padding-right: -20px !important; }

.pr-25 {
  padding-right: 25px !important; }

.pr-n25 {
  padding-right: -25px !important; }

.pr-30 {
  padding-right: 30px !important; }

.pr-n30 {
  padding-right: -30px !important; }

.pr-35 {
  padding-right: 35px !important; }

.pr-n35 {
  padding-right: -35px !important; }

.pr-40 {
  padding-right: 40px !important; }

.pr-n40 {
  padding-right: -40px !important; }

.pr-45 {
  padding-right: 45px !important; }

.pr-n45 {
  padding-right: -45px !important; }

.pr-50 {
  padding-right: 50px !important; }

.pr-n50 {
  padding-right: -50px !important; }

.pr-55 {
  padding-right: 55px !important; }

.pr-n55 {
  padding-right: -55px !important; }

.pr-60 {
  padding-right: 60px !important; }

.pr-n60 {
  padding-right: -60px !important; }

.pr-65 {
  padding-right: 65px !important; }

.pr-n65 {
  padding-right: -65px !important; }

.pr-70 {
  padding-right: 70px !important; }

.pr-n70 {
  padding-right: -70px !important; }

.pr-75 {
  padding-right: 75px !important; }

.pr-n75 {
  padding-right: -75px !important; }

.pr-80 {
  padding-right: 80px !important; }

.pr-n80 {
  padding-right: -80px !important; }

.pr-85 {
  padding-right: 85px !important; }

.pr-n85 {
  padding-right: -85px !important; }

.pr-90 {
  padding-right: 90px !important; }

.pr-n90 {
  padding-right: -90px !important; }

.pr-95 {
  padding-right: 95px !important; }

.pr-n95 {
  padding-right: -95px !important; }

.pr-100 {
  padding-right: 100px !important; }

.pr-n100 {
  padding-right: -100px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.pb-n0 {
  padding-bottom: 0px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.pb-n5 {
  padding-bottom: -5px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.pb-n10 {
  padding-bottom: -10px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.pb-n15 {
  padding-bottom: -15px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.pb-n20 {
  padding-bottom: -20px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.pb-n25 {
  padding-bottom: -25px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.pb-n30 {
  padding-bottom: -30px !important; }

.pb-35 {
  padding-bottom: 35px !important; }

.pb-n35 {
  padding-bottom: -35px !important; }

.pb-40 {
  padding-bottom: 40px !important; }

.pb-n40 {
  padding-bottom: -40px !important; }

.pb-45 {
  padding-bottom: 45px !important; }

.pb-n45 {
  padding-bottom: -45px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.pb-n50 {
  padding-bottom: -50px !important; }

.pb-55 {
  padding-bottom: 55px !important; }

.pb-n55 {
  padding-bottom: -55px !important; }

.pb-60 {
  padding-bottom: 60px !important; }

.pb-n60 {
  padding-bottom: -60px !important; }

.pb-65 {
  padding-bottom: 65px !important; }

.pb-n65 {
  padding-bottom: -65px !important; }

.pb-70 {
  padding-bottom: 70px !important; }

.pb-n70 {
  padding-bottom: -70px !important; }

.pb-75 {
  padding-bottom: 75px !important; }

.pb-n75 {
  padding-bottom: -75px !important; }

.pb-80 {
  padding-bottom: 80px !important; }

.pb-n80 {
  padding-bottom: -80px !important; }

.pb-85 {
  padding-bottom: 85px !important; }

.pb-n85 {
  padding-bottom: -85px !important; }

.pb-90 {
  padding-bottom: 90px !important; }

.pb-n90 {
  padding-bottom: -90px !important; }

.pb-95 {
  padding-bottom: 95px !important; }

.pb-n95 {
  padding-bottom: -95px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.pb-n100 {
  padding-bottom: -100px !important; }

.pl-0 {
  padding-left: 0px !important; }

.pl-n0 {
  padding-left: 0px !important; }

.pl-5 {
  padding-left: 5px !important; }

.pl-n5 {
  padding-left: -5px !important; }

.pl-10 {
  padding-left: 10px !important; }

.pl-n10 {
  padding-left: -10px !important; }

.pl-15 {
  padding-left: 15px !important; }

.pl-n15 {
  padding-left: -15px !important; }

.pl-20 {
  padding-left: 20px !important; }

.pl-n20 {
  padding-left: -20px !important; }

.pl-25 {
  padding-left: 25px !important; }

.pl-n25 {
  padding-left: -25px !important; }

.pl-30 {
  padding-left: 30px !important; }

.pl-n30 {
  padding-left: -30px !important; }

.pl-35 {
  padding-left: 35px !important; }

.pl-n35 {
  padding-left: -35px !important; }

.pl-40 {
  padding-left: 40px !important; }

.pl-n40 {
  padding-left: -40px !important; }

.pl-45 {
  padding-left: 45px !important; }

.pl-n45 {
  padding-left: -45px !important; }

.pl-50 {
  padding-left: 50px !important; }

.pl-n50 {
  padding-left: -50px !important; }

.pl-55 {
  padding-left: 55px !important; }

.pl-n55 {
  padding-left: -55px !important; }

.pl-60 {
  padding-left: 60px !important; }

.pl-n60 {
  padding-left: -60px !important; }

.pl-65 {
  padding-left: 65px !important; }

.pl-n65 {
  padding-left: -65px !important; }

.pl-70 {
  padding-left: 70px !important; }

.pl-n70 {
  padding-left: -70px !important; }

.pl-75 {
  padding-left: 75px !important; }

.pl-n75 {
  padding-left: -75px !important; }

.pl-80 {
  padding-left: 80px !important; }

.pl-n80 {
  padding-left: -80px !important; }

.pl-85 {
  padding-left: 85px !important; }

.pl-n85 {
  padding-left: -85px !important; }

.pl-90 {
  padding-left: 90px !important; }

.pl-n90 {
  padding-left: -90px !important; }

.pl-95 {
  padding-left: 95px !important; }

.pl-n95 {
  padding-left: -95px !important; }

.pl-100 {
  padding-left: 100px !important; }

.pl-n100 {
  padding-left: -100px !important; }

@media (min-width: 769px) {
  .mt-md-0 {
    margin-top: 0px !important; }
  .mt-n0 {
    margin-top: 0px !important; }
  .mt-md-5 {
    margin-top: 5px !important; }
  .mt-n5 {
    margin-top: -5px !important; }
  .mt-md-10 {
    margin-top: 10px !important; }
  .mt-n10 {
    margin-top: -10px !important; }
  .mt-md-15 {
    margin-top: 15px !important; }
  .mt-n15 {
    margin-top: -15px !important; }
  .mt-md-20 {
    margin-top: 20px !important; }
  .mt-n20 {
    margin-top: -20px !important; }
  .mt-md-25 {
    margin-top: 25px !important; }
  .mt-n25 {
    margin-top: -25px !important; }
  .mt-md-30 {
    margin-top: 30px !important; }
  .mt-n30 {
    margin-top: -30px !important; }
  .mt-md-35 {
    margin-top: 35px !important; }
  .mt-n35 {
    margin-top: -35px !important; }
  .mt-md-40 {
    margin-top: 40px !important; }
  .mt-n40 {
    margin-top: -40px !important; }
  .mt-md-45 {
    margin-top: 45px !important; }
  .mt-n45 {
    margin-top: -45px !important; }
  .mt-md-50 {
    margin-top: 50px !important; }
  .mt-n50 {
    margin-top: -50px !important; }
  .mt-md-55 {
    margin-top: 55px !important; }
  .mt-n55 {
    margin-top: -55px !important; }
  .mt-md-60 {
    margin-top: 60px !important; }
  .mt-n60 {
    margin-top: -60px !important; }
  .mt-md-65 {
    margin-top: 65px !important; }
  .mt-n65 {
    margin-top: -65px !important; }
  .mt-md-70 {
    margin-top: 70px !important; }
  .mt-n70 {
    margin-top: -70px !important; }
  .mt-md-75 {
    margin-top: 75px !important; }
  .mt-n75 {
    margin-top: -75px !important; }
  .mt-md-80 {
    margin-top: 80px !important; }
  .mt-n80 {
    margin-top: -80px !important; }
  .mt-md-85 {
    margin-top: 85px !important; }
  .mt-n85 {
    margin-top: -85px !important; }
  .mt-md-90 {
    margin-top: 90px !important; }
  .mt-n90 {
    margin-top: -90px !important; }
  .mt-md-95 {
    margin-top: 95px !important; }
  .mt-n95 {
    margin-top: -95px !important; }
  .mt-md-100 {
    margin-top: 100px !important; }
  .mt-n100 {
    margin-top: -100px !important; }
  .mr-md-0 {
    margin-right: 0px !important; }
  .mr-n0 {
    margin-right: 0px !important; }
  .mr-md-5 {
    margin-right: 5px !important; }
  .mr-n5 {
    margin-right: -5px !important; }
  .mr-md-10 {
    margin-right: 10px !important; }
  .mr-n10 {
    margin-right: -10px !important; }
  .mr-md-15 {
    margin-right: 15px !important; }
  .mr-n15 {
    margin-right: -15px !important; }
  .mr-md-20 {
    margin-right: 20px !important; }
  .mr-n20 {
    margin-right: -20px !important; }
  .mr-md-25 {
    margin-right: 25px !important; }
  .mr-n25 {
    margin-right: -25px !important; }
  .mr-md-30 {
    margin-right: 30px !important; }
  .mr-n30 {
    margin-right: -30px !important; }
  .mr-md-35 {
    margin-right: 35px !important; }
  .mr-n35 {
    margin-right: -35px !important; }
  .mr-md-40 {
    margin-right: 40px !important; }
  .mr-n40 {
    margin-right: -40px !important; }
  .mr-md-45 {
    margin-right: 45px !important; }
  .mr-n45 {
    margin-right: -45px !important; }
  .mr-md-50 {
    margin-right: 50px !important; }
  .mr-n50 {
    margin-right: -50px !important; }
  .mr-md-55 {
    margin-right: 55px !important; }
  .mr-n55 {
    margin-right: -55px !important; }
  .mr-md-60 {
    margin-right: 60px !important; }
  .mr-n60 {
    margin-right: -60px !important; }
  .mr-md-65 {
    margin-right: 65px !important; }
  .mr-n65 {
    margin-right: -65px !important; }
  .mr-md-70 {
    margin-right: 70px !important; }
  .mr-n70 {
    margin-right: -70px !important; }
  .mr-md-75 {
    margin-right: 75px !important; }
  .mr-n75 {
    margin-right: -75px !important; }
  .mr-md-80 {
    margin-right: 80px !important; }
  .mr-n80 {
    margin-right: -80px !important; }
  .mr-md-85 {
    margin-right: 85px !important; }
  .mr-n85 {
    margin-right: -85px !important; }
  .mr-md-90 {
    margin-right: 90px !important; }
  .mr-n90 {
    margin-right: -90px !important; }
  .mr-md-95 {
    margin-right: 95px !important; }
  .mr-n95 {
    margin-right: -95px !important; }
  .mr-md-100 {
    margin-right: 100px !important; }
  .mr-n100 {
    margin-right: -100px !important; }
  .mb-md-0 {
    margin-bottom: 0px !important; }
  .mb-n0 {
    margin-bottom: 0px !important; }
  .mb-md-5 {
    margin-bottom: 5px !important; }
  .mb-n5 {
    margin-bottom: -5px !important; }
  .mb-md-10 {
    margin-bottom: 10px !important; }
  .mb-n10 {
    margin-bottom: -10px !important; }
  .mb-md-15 {
    margin-bottom: 15px !important; }
  .mb-n15 {
    margin-bottom: -15px !important; }
  .mb-md-20 {
    margin-bottom: 20px !important; }
  .mb-n20 {
    margin-bottom: -20px !important; }
  .mb-md-25 {
    margin-bottom: 25px !important; }
  .mb-n25 {
    margin-bottom: -25px !important; }
  .mb-md-30 {
    margin-bottom: 30px !important; }
  .mb-n30 {
    margin-bottom: -30px !important; }
  .mb-md-35 {
    margin-bottom: 35px !important; }
  .mb-n35 {
    margin-bottom: -35px !important; }
  .mb-md-40 {
    margin-bottom: 40px !important; }
  .mb-n40 {
    margin-bottom: -40px !important; }
  .mb-md-45 {
    margin-bottom: 45px !important; }
  .mb-n45 {
    margin-bottom: -45px !important; }
  .mb-md-50 {
    margin-bottom: 50px !important; }
  .mb-n50 {
    margin-bottom: -50px !important; }
  .mb-md-55 {
    margin-bottom: 55px !important; }
  .mb-n55 {
    margin-bottom: -55px !important; }
  .mb-md-60 {
    margin-bottom: 60px !important; }
  .mb-n60 {
    margin-bottom: -60px !important; }
  .mb-md-65 {
    margin-bottom: 65px !important; }
  .mb-n65 {
    margin-bottom: -65px !important; }
  .mb-md-70 {
    margin-bottom: 70px !important; }
  .mb-n70 {
    margin-bottom: -70px !important; }
  .mb-md-75 {
    margin-bottom: 75px !important; }
  .mb-n75 {
    margin-bottom: -75px !important; }
  .mb-md-80 {
    margin-bottom: 80px !important; }
  .mb-n80 {
    margin-bottom: -80px !important; }
  .mb-md-85 {
    margin-bottom: 85px !important; }
  .mb-n85 {
    margin-bottom: -85px !important; }
  .mb-md-90 {
    margin-bottom: 90px !important; }
  .mb-n90 {
    margin-bottom: -90px !important; }
  .mb-md-95 {
    margin-bottom: 95px !important; }
  .mb-n95 {
    margin-bottom: -95px !important; }
  .mb-md-100 {
    margin-bottom: 100px !important; }
  .mb-n100 {
    margin-bottom: -100px !important; }
  .ml-md-0 {
    margin-left: 0px !important; }
  .ml-n0 {
    margin-left: 0px !important; }
  .ml-md-5 {
    margin-left: 5px !important; }
  .ml-n5 {
    margin-left: -5px !important; }
  .ml-md-10 {
    margin-left: 10px !important; }
  .ml-n10 {
    margin-left: -10px !important; }
  .ml-md-15 {
    margin-left: 15px !important; }
  .ml-n15 {
    margin-left: -15px !important; }
  .ml-md-20 {
    margin-left: 20px !important; }
  .ml-n20 {
    margin-left: -20px !important; }
  .ml-md-25 {
    margin-left: 25px !important; }
  .ml-n25 {
    margin-left: -25px !important; }
  .ml-md-30 {
    margin-left: 30px !important; }
  .ml-n30 {
    margin-left: -30px !important; }
  .ml-md-35 {
    margin-left: 35px !important; }
  .ml-n35 {
    margin-left: -35px !important; }
  .ml-md-40 {
    margin-left: 40px !important; }
  .ml-n40 {
    margin-left: -40px !important; }
  .ml-md-45 {
    margin-left: 45px !important; }
  .ml-n45 {
    margin-left: -45px !important; }
  .ml-md-50 {
    margin-left: 50px !important; }
  .ml-n50 {
    margin-left: -50px !important; }
  .ml-md-55 {
    margin-left: 55px !important; }
  .ml-n55 {
    margin-left: -55px !important; }
  .ml-md-60 {
    margin-left: 60px !important; }
  .ml-n60 {
    margin-left: -60px !important; }
  .ml-md-65 {
    margin-left: 65px !important; }
  .ml-n65 {
    margin-left: -65px !important; }
  .ml-md-70 {
    margin-left: 70px !important; }
  .ml-n70 {
    margin-left: -70px !important; }
  .ml-md-75 {
    margin-left: 75px !important; }
  .ml-n75 {
    margin-left: -75px !important; }
  .ml-md-80 {
    margin-left: 80px !important; }
  .ml-n80 {
    margin-left: -80px !important; }
  .ml-md-85 {
    margin-left: 85px !important; }
  .ml-n85 {
    margin-left: -85px !important; }
  .ml-md-90 {
    margin-left: 90px !important; }
  .ml-n90 {
    margin-left: -90px !important; }
  .ml-md-95 {
    margin-left: 95px !important; }
  .ml-n95 {
    margin-left: -95px !important; }
  .ml-md-100 {
    margin-left: 100px !important; }
  .ml-n100 {
    margin-left: -100px !important; }
  .pt-md-0 {
    padding-top: 0px !important; }
  .pt-n0 {
    padding-top: 0px !important; }
  .pt-md-5 {
    padding-top: 5px !important; }
  .pt-n5 {
    padding-top: -5px !important; }
  .pt-md-10 {
    padding-top: 10px !important; }
  .pt-n10 {
    padding-top: -10px !important; }
  .pt-md-15 {
    padding-top: 15px !important; }
  .pt-n15 {
    padding-top: -15px !important; }
  .pt-md-20 {
    padding-top: 20px !important; }
  .pt-n20 {
    padding-top: -20px !important; }
  .pt-md-25 {
    padding-top: 25px !important; }
  .pt-n25 {
    padding-top: -25px !important; }
  .pt-md-30 {
    padding-top: 30px !important; }
  .pt-n30 {
    padding-top: -30px !important; }
  .pt-md-35 {
    padding-top: 35px !important; }
  .pt-n35 {
    padding-top: -35px !important; }
  .pt-md-40 {
    padding-top: 40px !important; }
  .pt-n40 {
    padding-top: -40px !important; }
  .pt-md-45 {
    padding-top: 45px !important; }
  .pt-n45 {
    padding-top: -45px !important; }
  .pt-md-50 {
    padding-top: 50px !important; }
  .pt-n50 {
    padding-top: -50px !important; }
  .pt-md-55 {
    padding-top: 55px !important; }
  .pt-n55 {
    padding-top: -55px !important; }
  .pt-md-60 {
    padding-top: 60px !important; }
  .pt-n60 {
    padding-top: -60px !important; }
  .pt-md-65 {
    padding-top: 65px !important; }
  .pt-n65 {
    padding-top: -65px !important; }
  .pt-md-70 {
    padding-top: 70px !important; }
  .pt-n70 {
    padding-top: -70px !important; }
  .pt-md-75 {
    padding-top: 75px !important; }
  .pt-n75 {
    padding-top: -75px !important; }
  .pt-md-80 {
    padding-top: 80px !important; }
  .pt-n80 {
    padding-top: -80px !important; }
  .pt-md-85 {
    padding-top: 85px !important; }
  .pt-n85 {
    padding-top: -85px !important; }
  .pt-md-90 {
    padding-top: 90px !important; }
  .pt-n90 {
    padding-top: -90px !important; }
  .pt-md-95 {
    padding-top: 95px !important; }
  .pt-n95 {
    padding-top: -95px !important; }
  .pt-md-100 {
    padding-top: 100px !important; }
  .pt-n100 {
    padding-top: -100px !important; }
  .pr-md-0 {
    padding-right: 0px !important; }
  .pr-n0 {
    padding-right: 0px !important; }
  .pr-md-5 {
    padding-right: 5px !important; }
  .pr-n5 {
    padding-right: -5px !important; }
  .pr-md-10 {
    padding-right: 10px !important; }
  .pr-n10 {
    padding-right: -10px !important; }
  .pr-md-15 {
    padding-right: 15px !important; }
  .pr-n15 {
    padding-right: -15px !important; }
  .pr-md-20 {
    padding-right: 20px !important; }
  .pr-n20 {
    padding-right: -20px !important; }
  .pr-md-25 {
    padding-right: 25px !important; }
  .pr-n25 {
    padding-right: -25px !important; }
  .pr-md-30 {
    padding-right: 30px !important; }
  .pr-n30 {
    padding-right: -30px !important; }
  .pr-md-35 {
    padding-right: 35px !important; }
  .pr-n35 {
    padding-right: -35px !important; }
  .pr-md-40 {
    padding-right: 40px !important; }
  .pr-n40 {
    padding-right: -40px !important; }
  .pr-md-45 {
    padding-right: 45px !important; }
  .pr-n45 {
    padding-right: -45px !important; }
  .pr-md-50 {
    padding-right: 50px !important; }
  .pr-n50 {
    padding-right: -50px !important; }
  .pr-md-55 {
    padding-right: 55px !important; }
  .pr-n55 {
    padding-right: -55px !important; }
  .pr-md-60 {
    padding-right: 60px !important; }
  .pr-n60 {
    padding-right: -60px !important; }
  .pr-md-65 {
    padding-right: 65px !important; }
  .pr-n65 {
    padding-right: -65px !important; }
  .pr-md-70 {
    padding-right: 70px !important; }
  .pr-n70 {
    padding-right: -70px !important; }
  .pr-md-75 {
    padding-right: 75px !important; }
  .pr-n75 {
    padding-right: -75px !important; }
  .pr-md-80 {
    padding-right: 80px !important; }
  .pr-n80 {
    padding-right: -80px !important; }
  .pr-md-85 {
    padding-right: 85px !important; }
  .pr-n85 {
    padding-right: -85px !important; }
  .pr-md-90 {
    padding-right: 90px !important; }
  .pr-n90 {
    padding-right: -90px !important; }
  .pr-md-95 {
    padding-right: 95px !important; }
  .pr-n95 {
    padding-right: -95px !important; }
  .pr-md-100 {
    padding-right: 100px !important; }
  .pr-n100 {
    padding-right: -100px !important; }
  .pb-md-0 {
    padding-bottom: 0px !important; }
  .pb-n0 {
    padding-bottom: 0px !important; }
  .pb-md-5 {
    padding-bottom: 5px !important; }
  .pb-n5 {
    padding-bottom: -5px !important; }
  .pb-md-10 {
    padding-bottom: 10px !important; }
  .pb-n10 {
    padding-bottom: -10px !important; }
  .pb-md-15 {
    padding-bottom: 15px !important; }
  .pb-n15 {
    padding-bottom: -15px !important; }
  .pb-md-20 {
    padding-bottom: 20px !important; }
  .pb-n20 {
    padding-bottom: -20px !important; }
  .pb-md-25 {
    padding-bottom: 25px !important; }
  .pb-n25 {
    padding-bottom: -25px !important; }
  .pb-md-30 {
    padding-bottom: 30px !important; }
  .pb-n30 {
    padding-bottom: -30px !important; }
  .pb-md-35 {
    padding-bottom: 35px !important; }
  .pb-n35 {
    padding-bottom: -35px !important; }
  .pb-md-40 {
    padding-bottom: 40px !important; }
  .pb-n40 {
    padding-bottom: -40px !important; }
  .pb-md-45 {
    padding-bottom: 45px !important; }
  .pb-n45 {
    padding-bottom: -45px !important; }
  .pb-md-50 {
    padding-bottom: 50px !important; }
  .pb-n50 {
    padding-bottom: -50px !important; }
  .pb-md-55 {
    padding-bottom: 55px !important; }
  .pb-n55 {
    padding-bottom: -55px !important; }
  .pb-md-60 {
    padding-bottom: 60px !important; }
  .pb-n60 {
    padding-bottom: -60px !important; }
  .pb-md-65 {
    padding-bottom: 65px !important; }
  .pb-n65 {
    padding-bottom: -65px !important; }
  .pb-md-70 {
    padding-bottom: 70px !important; }
  .pb-n70 {
    padding-bottom: -70px !important; }
  .pb-md-75 {
    padding-bottom: 75px !important; }
  .pb-n75 {
    padding-bottom: -75px !important; }
  .pb-md-80 {
    padding-bottom: 80px !important; }
  .pb-n80 {
    padding-bottom: -80px !important; }
  .pb-md-85 {
    padding-bottom: 85px !important; }
  .pb-n85 {
    padding-bottom: -85px !important; }
  .pb-md-90 {
    padding-bottom: 90px !important; }
  .pb-n90 {
    padding-bottom: -90px !important; }
  .pb-md-95 {
    padding-bottom: 95px !important; }
  .pb-n95 {
    padding-bottom: -95px !important; }
  .pb-md-100 {
    padding-bottom: 100px !important; }
  .pb-n100 {
    padding-bottom: -100px !important; }
  .pl-md-0 {
    padding-left: 0px !important; }
  .pl-n0 {
    padding-left: 0px !important; }
  .pl-md-5 {
    padding-left: 5px !important; }
  .pl-n5 {
    padding-left: -5px !important; }
  .pl-md-10 {
    padding-left: 10px !important; }
  .pl-n10 {
    padding-left: -10px !important; }
  .pl-md-15 {
    padding-left: 15px !important; }
  .pl-n15 {
    padding-left: -15px !important; }
  .pl-md-20 {
    padding-left: 20px !important; }
  .pl-n20 {
    padding-left: -20px !important; }
  .pl-md-25 {
    padding-left: 25px !important; }
  .pl-n25 {
    padding-left: -25px !important; }
  .pl-md-30 {
    padding-left: 30px !important; }
  .pl-n30 {
    padding-left: -30px !important; }
  .pl-md-35 {
    padding-left: 35px !important; }
  .pl-n35 {
    padding-left: -35px !important; }
  .pl-md-40 {
    padding-left: 40px !important; }
  .pl-n40 {
    padding-left: -40px !important; }
  .pl-md-45 {
    padding-left: 45px !important; }
  .pl-n45 {
    padding-left: -45px !important; }
  .pl-md-50 {
    padding-left: 50px !important; }
  .pl-n50 {
    padding-left: -50px !important; }
  .pl-md-55 {
    padding-left: 55px !important; }
  .pl-n55 {
    padding-left: -55px !important; }
  .pl-md-60 {
    padding-left: 60px !important; }
  .pl-n60 {
    padding-left: -60px !important; }
  .pl-md-65 {
    padding-left: 65px !important; }
  .pl-n65 {
    padding-left: -65px !important; }
  .pl-md-70 {
    padding-left: 70px !important; }
  .pl-n70 {
    padding-left: -70px !important; }
  .pl-md-75 {
    padding-left: 75px !important; }
  .pl-n75 {
    padding-left: -75px !important; }
  .pl-md-80 {
    padding-left: 80px !important; }
  .pl-n80 {
    padding-left: -80px !important; }
  .pl-md-85 {
    padding-left: 85px !important; }
  .pl-n85 {
    padding-left: -85px !important; }
  .pl-md-90 {
    padding-left: 90px !important; }
  .pl-n90 {
    padding-left: -90px !important; }
  .pl-md-95 {
    padding-left: 95px !important; }
  .pl-n95 {
    padding-left: -95px !important; }
  .pl-md-100 {
    padding-left: 100px !important; }
  .pl-n100 {
    padding-left: -100px !important; } }

.form-control {
  width: auto; }

.ttl_style1, .page__lead-title {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.3; }
  @media (max-width: 768.98px) {
    .ttl_style1, .page__lead-title {
      font-size: 2.6rem; } }

.ttl_style2 {
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.3; }
  @media (max-width: 768.98px) {
    .ttl_style2 {
      font-size: 2.4rem; } }

.ttl_style3 {
  padding-bottom: .2em;
  border-bottom: 1px solid #323232;
  font-size: 2.8rem;
  font-weight: bold;
  line-height: 1.3; }
  @media (max-width: 768.98px) {
    .ttl_style3 {
      font-size: 2rem; } }

.ttl_style4 {
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 1.5; }
  @media (max-width: 768.98px) {
    .ttl_style4 {
      font-size: 1.8rem; } }

.ttl_style5 {
  font-weight: bold;
  font-size: 2.2rem;
  color: #003c87; }
  @media (max-width: 768.98px) {
    .ttl_style5 {
      font-size: 1.8rem; } }

.btn_block {
  margin-top: 4rem;
  text-align: center; }

.btn_default {
  position: relative;
  display: inline-block;
  padding: 1.6rem 4rem;
  font-weight: bold;
  line-height: 1.4;
  text-align: center;
  font-size: 2rem;
  background-color: #003c87;
  border-radius: 4px;
  color: #fff !important; }
  @media (min-width: 769px) {
    .btn_default {
      min-width: 420px; } }
  @media (max-width: 768.98px) {
    .btn_default {
      width: 100%; } }
  .btn_default::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    content: "\f138" !important;
    display: inline-block;
    color: inherit;
    font-size: 2.6rem;
    font-family: 'Line Awesome Free';
    font-weight: 900; }
  .btn_default.-white {
    background-color: #fff;
    color: #003c87 !important; }
  .btn_default.-full {
    width: 100%; }

.btn_more {
  position: relative;
  display: inline-block;
  padding: .8rem 6rem .8rem 2rem;
  border: 1px solid #323232;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.4; }
  .btn_more::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    content: "\f138" !important;
    display: inline-block;
    color: inherit;
    font-size: 2rem;
    font-family: 'Line Awesome Free';
    font-weight: 900; }
  .btn_more.-white {
    border-color: #fff;
    color: #fff !important; }

.pagination {
  border-radius: 0;
  justify-content: center; }
  .pagination .page-link, .pagination .link_page, .pagination .current_page {
    min-width: 28px;
    padding: 0 5px;
    background-color: #fff;
    border: 1px solid #323232;
    color: #323232;
    font-size: 1.4rem;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    line-height: 26px;
    text-align: center;
    display: inline-block; }
  .pagination .current_page {
    background-color: #323232;
    border: 1px solid #323232;
    color: #fff; }
  .pagination .page-prev,
  .pagination .page-next {
    position: relative;
    z-index: 0; }
    .pagination .page-prev .page-link,
    .pagination .page-next .page-link {
      width: 20px;
      min-width: 20px;
      border: none;
      font-size: 0px; }
    .pagination .page-prev::before,
    .pagination .page-next::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: 0;
      top: 10px;
      display: block;
      width: 7px;
      height: 7px;
      border-top: 2px solid #323232;
      border-right: 2px solid #323232;
      transform: rotate(45deg); }
  .pagination .page-prev::before {
    left: auto;
    right: 0;
    transform: rotate(-135deg); }
  .pagination .page-item.active .page-link {
    background-color: #323232;
    border: 1px solid #323232;
    color: #fff; }
  .pagination .link_last {
    font-weight: bold;
    margin-left: 5px; }
  .pagination .link_first {
    font-weight: bold;
    margin-right: 5px; }

.tabnav .nav-tabs {
  display: none; }

@media (min-width: 769px) {
  .tabnav .nav-tabs {
    display: flex; } }

.tabnav .selecton {
  position: relative;
  z-index: 10;
  background-color: #fff;
  border: 1px #adb5bd solid; }
  .tabnav .selecton::after {
    content: '\f078';
    font-family: 'FontAwesome';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: 50%;
    right: 10px;
    margin-top: -.5em;
    width: 1em;
    height: 1em;
    line-height: 1;
    z-index: -1;
    color: #323232; }
  .tabnav .selecton select {
    appearance: none;
    display: block;
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: 0; }
    .tabnav .selecton select option {
      background-color: transparent; }
  @media (min-width: 769px) {
    .tabnav .selecton {
      display: none; } }

.panel-group {
  border-bottom: 1px #dee2e6 solid; }
  .panel-group .panel {
    border-radius: 0;
    border: 0;
    box-shadow: none; }
    .panel-group .panel .panel-heading {
      padding: 0;
      background-color: transparent;
      border-top: 1px #dee2e6 solid;
      border-radius: 0; }
      .panel-group .panel .panel-heading .panel-title {
        position: relative; }
        .panel-group .panel .panel-heading .panel-title a {
          position: relative;
          display: block;
          padding: 14px 1.8em 14px 0;
          color: #323232;
          text-decoration: none; }
          .panel-group .panel .panel-heading .panel-title a .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 50%;
            right: 2px;
            margin-top: -.5em;
            width: 1.3em;
            height: 1.3em;
            line-height: 1;
            border-radius: 50%;
            background-color: #464c8e; }
            .panel-group .panel .panel-heading .panel-title a .icon::before {
              content: '\f107';
              font-family: 'FontAwesome';
              color: #fff;
              font-size: .9em; }
    .panel-group .panel .panel-body {
      padding: 10px; }
  .panel-group .collapse_show > .panel-heading > .panel-title > a {
    color: #464c8e; }
    .panel-group .collapse_show > .panel-heading > .panel-title > a .icon {
      background-color: #adb5bd; }
      .panel-group .collapse_show > .panel-heading > .panel-title > a .icon::before {
        content: '\f106'; }

.pagelink_style1 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 4rem 0 8rem;
  list-style: none; }
  @media (min-width: 769px) {
    .pagelink_style1-item {
      flex: 1 0 0%; } }
  @media (max-width: 768.98px) {
    .pagelink_style1-item {
      width: 100%; } }
  .pagelink_style1-item a {
    position: relative;
    display: block;
    padding: 1.6rem 4rem 1.6rem 2rem;
    background-color: #003c87;
    border: 2px solid transparent;
    border-radius: 2px;
    color: #fff !important;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    white-space: nowrap; }
    @media (max-width: 768.98px) {
      .pagelink_style1-item a {
        padding: 1.2rem 4rem 1.2rem 2rem; } }
    .pagelink_style1-item a::after {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all .2s;
      content: "\f0ab";
      display: inline-block;
      color: inherit;
      font-size: 2.6rem;
      font-family: 'Line Awesome Free';
      font-weight: 900; }
    .pagelink_style1-item a.-border {
      background-color: #fff;
      border: 2px solid #003c87;
      color: #003c87 !important; }
      .pagelink_style1-item a.-border.active {
        background-color: #003c87;
        color: #fff !important; }

.pagelink_style2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 4rem 0 6rem;
  list-style: none; }
  @media (max-width: 768.98px) {
    .pagelink_style2 {
      padding: 2rem 0 4rem; } }
  @media (min-width: 769px) {
    .pagelink_style2-item {
      flex: 1 0 0%; } }
  @media (max-width: 768.98px) {
    .pagelink_style2-item {
      width: 100%; } }
  .pagelink_style2-item a {
    position: relative;
    display: block;
    padding: 1.6rem 4rem 1.6rem 2rem;
    background-color: #fff;
    border: 2px solid #003c87;
    border-radius: 2px;
    color: #003c87 !important;
    font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: normal;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap; }
    @media (max-width: 768.98px) {
      .pagelink_style2-item a {
        padding: 1.2rem 4rem 1.2rem 2rem; } }
    .pagelink_style2-item a::after {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all .2s;
      content: "\f0ab";
      display: inline-block;
      color: inherit;
      font-size: 2.6rem;
      font-family: 'Line Awesome Free';
      font-weight: 900; }

.pagelink_style3 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0;
  padding: 4rem 0 8rem;
  list-style: none;
  box-sizing: border-box; }
  .pagelink_style3-item {
    width: calc(33.3% - 14px); }
    @media (max-width: 768.98px) {
      .pagelink_style3-item {
        width: 100%; } }
    .pagelink_style3-item a {
      position: relative;
      display: block;
      padding: 1.6rem 4rem 1.6rem 2rem;
      background-color: #003c87;
      border: 2px solid transparent;
      border-radius: 2px;
      color: #fff !important;
      font-size: 1.8rem;
      font-weight: bold;
      letter-spacing: normal;
      line-height: 1.5;
      white-space: nowrap; }
      @media (max-width: 768.98px) {
        .pagelink_style3-item a {
          padding: 1.2rem 4rem 1.2rem 2rem; } }
      .pagelink_style3-item a::after {
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
        content: "\f0ab";
        display: inline-block;
        color: inherit;
        font-size: 2.6rem;
        font-family: 'Line Awesome Free';
        font-weight: 900; }
      .pagelink_style3-item a.-border {
        background-color: #fff;
        border: 2px solid #003c87;
        color: #003c87 !important; }
        .pagelink_style3-item a.-border.active {
          background-color: #003c87;
          color: #fff !important; }

.table thead tr > th {
  vertical-align: middle;
  text-align: center;
  line-height: 1.4; }

.table tbody tr > th {
  line-height: 1.4; }

.table_layout_fixed {
  table-layout: fixed; }

.table-responsive {
  -webkit-overflow-scrolling: touch; }
  .table-responsive .table tbody tr td,
  .table-responsive .table tbody tr th,
  .table-responsive .table tfoot tr td,
  .table-responsive .table tfoot tr th,
  .table-responsive .table thead tr td,
  .table-responsive .table thead tr th {
    background-color: #fff; }
  .table-responsive .table tbody th,
  .table-responsive .table tfoot th,
  .table-responsive .table thead th {
    white-space: nowrap; }

@media (max-width: 768.98px) {
  .table__break_sm {
    width: 100%; }
    .table__break_sm tbody > tr th,
    .table__break_sm thead > tr th {
      display: block;
      width: 100%;
      border-width: 1px;
      border-color: inherit;
      border-style: solid; }
    .table__break_sm tbody > tr td,
    .table__break_sm thead > tr td {
      display: block;
      width: 100%;
      border-width: 1px;
      border-color: inherit;
      border-style: solid; } }

.table.tbl__style_1 {
  width: 100%;
  border-collapse: collapse; }
  .table.tbl__style_1 td,
  .table.tbl__style_1 th {
    border: 1px #aaa solid;
    word-wrap: break-word;
    overflow-wrap: break-word; }
  .table.tbl__style_1 thead tr th {
    border-bottom-width: 1px;
    font-weight: bold;
    color: #fff;
    background-color: #323232; }
  .table.tbl__style_1 thead tr td {
    border-bottom-width: 1px; }
  .table.tbl__style_1 tbody tr th {
    background-color: #f0f0f0;
    vertical-align: middle; }
  .table.tbl__style_1 tbody tr td {
    vertical-align: middle; }
  .table.tbl__style_1 tbody tr .thead_th {
    border-bottom-width: 1px;
    font-weight: bold;
    color: #fff;
    background-color: #323232; }
  @media (max-width: 768.98px) {
    .table.tbl__style_1.table__break_sm {
      border-top: 1px #aaa solid; }
      .table.tbl__style_1.table__break_sm s thead tr td,
      .table.tbl__style_1.table__break_sm s thead tr th,
      .table.tbl__style_1.table__break_sm tbody tr td,
      .table.tbl__style_1.table__break_sm tbody tr th {
        border-top: 0; } }

.table.tbl__style_2 td,
.table.tbl__style_2 th {
  border: 0; }

.table.tbl__style_2 thead tr th {
  border-left: 1px #fff solid;
  border-bottom: 1px #fff solid;
  background-color: #498ed0;
  color: #fff; }

.table.tbl__style_2 thead tr th:first-child {
  border-left: 0; }

.table.tbl__style_2 tbody {
  border-top: 1px #ccc solid; }
  .table.tbl__style_2 tbody::before {
    display: none; }
  .table.tbl__style_2 tbody tr td {
    border: 0;
    border-bottom: 1px #ccc solid;
    border-left: 1px #ccc solid; }
    .table.tbl__style_2 tbody tr td:first-child {
      border-left: 0; }
  .table.tbl__style_2 tbody tr th {
    background-color: #fff;
    color: #0060af;
    background-color: #eaf2f8;
    border: 0;
    border-bottom: 1px #ccc solid;
    border-left: 1px #ccc solid; }
  .table.tbl__style_2 tbody tr .thead_th {
    background-color: #498ed0;
    color: #fff;
    border-bottom-color: #fff; }
  .table.tbl__style_2 tbody tr:last-child .thead_th {
    border-bottom-color: #ccc; }
  .table.tbl__style_2 tbody tr th:first-child {
    border-left: 0; }

@media (max-width: 768.98px) {
  .table.tbl__style_2.table__break_sm {
    border: 1px #ccc solid; }
    .table.tbl__style_2.table__break_sm tbody tr td,
    .table.tbl__style_2.table__break_sm tbody tr th,
    .table.tbl__style_2.table__break_sm thead tr td,
    .table.tbl__style_2.table__break_sm thead tr th {
      border-top: 0;
      border-left: 0; } }

.subbox1 {
  padding: 24px;
  background-color: #f0f0f0; }
  .subbox1__inner {
    border: 1px #ccc solid;
    background-color: #fff;
    padding: 16px; }
  .subbox1.small_box {
    padding: 1em 1.2em; }
  @media (min-width: 769px) {
    .subbox1 {
      padding: 32px; }
      .subbox1__inner {
        padding: 24px; } }

.subbox2 {
  padding: 24px;
  border: 1px #ccc solid;
  background-color: #fff; }
  .subbox2__inner {
    background-color: #e5edf6;
    padding: 16px; }
  .subbox2.small_box {
    padding: 1em 1.2em; }
  @media (min-width: 769px) {
    .subbox2 {
      padding: 32px; }
      .subbox2__inner {
        padding: 24px; } }

.block {
  margin-bottom: 48px; }
  .block > .block {
    margin-bottom: 24px; }
  .block:last-child {
    margin-bottom: 0; }
  @media (min-width: 769px) {
    .block {
      margin-bottom: 60px; }
      .block > .block {
        margin-bottom: 30px; }
      .block:last-child {
        margin-bottom: 0; } }

.inner, .header_category__wrapper {
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto; }
  @media (min-width: 1080px) {
    .inner, .header_category__wrapper {
      width: 1080px; } }

.list__reset,
.list__reset li,
.list__reset ol,
.list__reset ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__disc,
.list__disc > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__disc > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: disc;
  list-style-position: inside; }
  .list__disc > li > * {
    text-indent: 0; }

.list__num,
.list__num > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__num > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: decimal;
  list-style-position: inside; }
  .list__num > li > * {
    text-indent: 0; }

.list__num_kakko {
  counter-reset: item; }
  .list__num_kakko,
  .list__num_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__num_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__num_kakko > li::before {
      content: "(" counter(item, decimal) ")"; }

.list__num_kome {
  counter-reset: item; }
  .list__num_kome,
  .list__num_kome > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__num_kome > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__num_kome > li::before {
      content: "※" counter(item, decimal) " "; }

.list__num_zero,
.list__num_zero > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__num_zero li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: decimal-leading-zero;
  list-style-position: inside; }
  .list__num_zero li > * {
    text-indent: 0; }

.list__num_zero_kakko {
  counter-reset: item; }
  .list__num_zero_kakko,
  .list__num_zero_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__num_zero_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__num_zero_kakko > li::before {
      content: "(" counter(item, decimal-leading-zero) ")"; }

.list__lower_roman,
.list__lower_roman > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__lower_roman > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: lower-roman;
  list-style-position: inside; }
  .list__lower_roman > li > * {
    text-indent: 0; }

.list__lower_roman_kakko {
  counter-reset: item; }
  .list__lower_roman_kakko,
  .list__lower_roman_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__lower_roman_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__lower_roman_kakko > li::before {
      content: "(" counter(item, lower-roman) ")"; }

.list__upper_roman,
.list__upper_roman > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__upper_roman > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: upper-roman;
  list-style-position: inside; }
  .list__upper_roman > li > * {
    text-indent: 0; }

.list__upper_roman_kakko {
  counter-reset: item; }
  .list__upper_roman_kakko,
  .list__upper_roman_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__upper_roman_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__upper_roman_kakko > li::before {
      content: "(" counter(item, upper-roman) ")"; }

.list__lower_latin,
.list__lower_latin > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__lower_latin > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: lower-latin;
  list-style-position: inside; }
  .list__lower_latin > li > * {
    text-indent: 0; }

.list__lower_latin_kakko {
  counter-reset: item; }
  .list__lower_latin_kakko,
  .list__lower_latin_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__lower_latin_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__lower_latin_kakko > li::before {
      content: "(" counter(item, lower-latin) ")"; }

.list__upper_latin,
.list__upper_latin > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__upper_latin > li {
  text-indent: -1em;
  padding-left: 1em;
  list-style-type: upper-latin;
  list-style-position: inside; }
  .list__upper_latin > li > * {
    text-indent: 0; }

.list__upper_latin_kakko {
  counter-reset: item; }
  .list__upper_latin_kakko,
  .list__upper_latin_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__upper_latin_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -1.5em;
    padding-left: 1.5em; }
    .list__upper_latin_kakko > li::before {
      content: "(" counter(item, upper-latin) ")"; }

.list__hiragana,
.list__hiragana > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__hiragana > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: hiragana;
  list-style-position: inside; }
  .list__hiragana > li > * {
    text-indent: 0; }

.list__hiragana_kakko {
  counter-reset: item; }
  .list__hiragana_kakko,
  .list__hiragana_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__hiragana_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__hiragana_kakko > li::before {
      content: "(" counter(item, hiragana) ")"; }

.list__katakana,
.list__katakana > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__katakana > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: katakana;
  list-style-position: inside; }
  .list__katakana > li > * {
    text-indent: 0; }

.list__katakana_kakko {
  counter-reset: item; }
  .list__katakana_kakko,
  .list__katakana_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__katakana_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__katakana_kakko > li::before {
      content: "(" counter(item, katakana) ")"; }

.list__hiragana-iroha,
.list__hiragana-iroha > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__hiragana-iroha > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: hiragana-iroha;
  list-style-position: inside; }
  .list__hiragana-iroha > li > * {
    text-indent: 0; }

.list__hiragana-iroha_kakko {
  counter-reset: item; }
  .list__hiragana-iroha_kakko,
  .list__hiragana-iroha_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__hiragana-iroha_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__hiragana-iroha_kakko > li::before {
      content: "(" counter(item, hiragana-iroha) ")"; }

.list__katakana-iroha,
.list__katakana-iroha > li {
  margin: 0;
  padding: 0;
  list-style: none; }

.list__katakana-iroha > li {
  text-indent: -1.5em;
  padding-left: 1.5em;
  list-style-type: katakana-iroha;
  list-style-position: inside; }
  .list__katakana-iroha > li > * {
    text-indent: 0; }

.list__katakana-iroha_kakko {
  counter-reset: item; }
  .list__katakana-iroha_kakko,
  .list__katakana-iroha_kakko > li {
    margin: 0;
    padding: 0;
    list-style: none; }
  .list__katakana-iroha_kakko > li {
    counter-increment: item;
    list-style: none;
    text-indent: -2em;
    padding-left: 2em; }
    .list__katakana-iroha_kakko > li::before {
      content: "(" counter(item, katakana-iroha) ")"; }

.txt__lead {
  margin-bottom: 1em;
  line-height: 1.4;
  font-size: 2.56rem;
  font-weight: 300; }
  @media (min-width: 769px) {
    .txt__lead {
      font-size: 3.2rem; } }

.txt__lead_s {
  margin-bottom: 1em;
  line-height: 1.4;
  font-size: 2.08rem;
  font-weight: 400; }
  @media (min-width: 769px) {
    .txt__lead_s {
      font-size: 2.56rem; } }

.txt__line_ylw {
  background: linear-gradient(transparent 60%, #ffff26 60%); }

.txt__cap {
  text-indent: -1em;
  padding-left: 1em; }

.fz__large {
  font-size: 120%;
  line-height: 1.4; }

.fz__small {
  font-size: 0.875em;
  line-height: 1.3; }

.fz__bold {
  font-weight: 700; }

.fz__normal {
  font-weight: 400; }

.color__main {
  color: #003c87 !important; }

.color__sub1 {
  color: #589e57 !important; }

.color__sub2 {
  color: #0060af !important; }

.color__sub3 {
  color: #ba233c !important; }

.color__sub4 {
  color: #464c8e !important; }

.color__sub5 {
  color: #1586ac !important; }

.color__red {
  color: red !important; }

.color__white {
  color: #fff !important; }

.color__black {
  color: #323232 !important; }

.ff__gothic {
  font-family: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif; }

.ff__mincho {
  font-family: YuMincho, "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Sawarabi Mincho", "Times New Roman", Times, serif; }

a[target='_blank']::after {
  content: '\f35d';
  font-family: 'Line Awesome Free'; }

a[href$='.pdf']::after {
  content: '\f1c1';
  font-family: 'Line Awesome Free';
  color: red; }

a[href$='.doc']::after, a[href$='.docx']::after {
  content: '\f1c2';
  font-family: 'Line Awesome Free';
  color: blue; }

a[href$='.xls']::after, a[href$='.xlsx']::after {
  content: '\f1c3';
  font-family: 'Line Awesome Free';
  color: green; }

a.no-icon::after {
  content: none !important; }

body .mrg__btm0 {
  margin-bottom: 0 !important; }

body .mrg__btm05 {
  margin-bottom: .5em !important; }

body .mrg__btm1 {
  margin-bottom: 1em !important; }

body .mrg__btm2 {
  margin-bottom: 2em !important; }

body .mrg__btm3 {
  margin-bottom: 3em !important; }

body .mrg__btm4 {
  margin-bottom: 4em !important; }

body .mrg__btm5 {
  margin-bottom: 5em !important; }

body .mrg__btm6 {
  margin-bottom: 6em !important; }

body .mrg__btm_gutter {
  margin-bottom: 30px !important; }

body .mrg__top0 {
  margin-top: 0 !important; }

body .mrg__top05 {
  margin-top: .5em !important; }

body .mrg__top1 {
  margin-top: 1em !important; }

body .mrg__top2 {
  margin-top: 2em !important; }

body .mrg__top3 {
  margin-top: 3em !important; }

body .mrg__top_gutter {
  margin-top: 30px !important; }

body .mrg__left0 {
  margin-left: 0 !important; }

body .mrg__left1 {
  margin-left: 1em !important; }

body .mrg__left2 {
  margin-left: 2em !important; }

body .mrg__left3 {
  margin-left: 3em !important; }

body .mrg__left_gutter {
  margin-left: 30px !important; }

body .mrg__right0 {
  margin-right: 0 !important; }

body .mrg__right1 {
  margin-right: 1em !important; }

body .mrg__right2 {
  margin-right: 2em !important; }

body .mrg__right3 {
  margin-right: 3em !important; }

body .mrg__right_gutter {
  margin-right: 30px !important; }

body .pad__top0 {
  padding-top: 0 !important; }

body .pad__top1 {
  padding-top: 1em !important; }

body .pad__top2 {
  padding-top: 2em !important; }

body .pad__top3 {
  padding-top: 3em !important; }

body .pad__top_gutter {
  padding-top: 30px !important; }

body .pad__btm0 {
  padding-bottom: 0 !important; }

body .pad__btm1 {
  padding-bottom: 1em !important; }

body .pad__btm2 {
  padding-bottom: 2em !important; }

body .pad__btm3 {
  padding-bottom: 3em !important; }

body .pad__btm_gutter {
  padding-bottom: 30px !important; }

body .pad__left0 {
  padding-left: 0 !important; }

body .pad__left1 {
  padding-left: 1em !important; }

body .pad__left2 {
  padding-left: 2em !important; }

body .pad__left3 {
  padding-left: 3em !important; }

body .pad__left_gutter {
  padding-left: 30px !important; }

body .pad__right0 {
  padding-right: 0 !important; }

body .pad__right1 {
  padding-right: 1em !important; }

body .pad__right2 {
  padding-right: 2em !important; }

body .pad__right3 {
  padding-right: 3em !important; }

body .pad__right_gutter {
  padding-right: 30px !important; }

@media (min-width: 576px) {
  body .mrg_btm_sm_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 769px) {
  body .mrg_btm_md_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 1080px) {
  body .mrg_btm_lg_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 1367px) {
  body .mrg_btm_xl_0 {
    margin-bottom: 0 !important; } }

@media (min-width: 576px) {
  body .pad_btm_sm_0 {
    padding-bottom: 0 !important; } }

@media (min-width: 769px) {
  body .pad_btm_md_0 {
    padding-bottom: 0 !important; } }

@media (min-width: 1080px) {
  body .pad_btm_lg_0 {
    padding-bottom: 0 !important; } }

@media (min-width: 1367px) {
  body .pad_btm_xl_0 {
    padding-bottom: 0 !important; } }

.card_style1 {
  display: flex;
  flex-wrap: wrap;
  margin: -16px 0; }
  @media (min-width: 769px) {
    .card_style1 {
      margin: -30px -15px; } }
  .card_style1-item {
    padding: 16px 0;
    line-height: 1.5; }
    @media (min-width: 769px) {
      .card_style1-item {
        width: 33.3333%;
        padding: 30px 0; } }
    .card_style1-item.-noarrow .card_style1-image::after {
      content: none; }
    .card_style1-item.-color1 .card_style1-image::after {
      color: #589e57; }
    .card_style1-item.-color1 .card_style1-tag {
      background-color: #589e57; }
    .card_style1-item.-color2 .card_style1-image::after {
      color: #0060af; }
    .card_style1-item.-color2 .card_style1-tag {
      background-color: #0060af; }
    .card_style1-item.-color3 .card_style1-image::after {
      color: #ba233c; }
    .card_style1-item.-color3 .card_style1-tag {
      background-color: #ba233c; }
    .card_style1-item.-color4 .card_style1-image::after {
      color: #464c8e; }
    .card_style1-item.-color4 .card_style1-tag {
      background-color: #464c8e; }
    .card_style1-item.-color5 .card_style1-image::after {
      color: #1586ac; }
    .card_style1-item.-color5 .card_style1-tag {
      background-color: #1586ac; }
  .card_style1-link {
    display: block; }
    @media (min-width: 769px) {
      .card_style1-link {
        padding: 0 15px; } }
    .card_style1-link .card_style1-image::after {
      position: absolute;
      right: 0;
      bottom: 0;
      content: "\f061";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background-color: #fff;
      color: #003c87;
      font-size: 1.6rem;
      font-family: 'Line Awesome Free';
      font-weight: 900; }
  @media (min-width: 769px) {
    .card_style1-inner {
      padding: 0 15px; } }
  .card_style1-image {
    position: relative;
    margin-bottom: 1rem; }
    .card_style1-image img {
      width: 100%;
      height: auto;
      aspect-ratio: 3/2;
      object-fit: cover; }
  .card_style1-video {
    margin-bottom: 1rem; }
    .card_style1-video iframe {
      width: 100%;
      height: auto;
      aspect-ratio: 16 / 9;
      vertical-align: bottom; }
  .card_style1-title {
    margin-bottom: .2em;
    font-weight: bold; }
  .card_style1-name {
    margin-bottom: 0;
    font-weight: bold; }
  .card_style1-text {
    margin-bottom: 0; }
  .card_style1-date {
    font-size: 1.4rem; }
  .card_style1-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 8px; }
  .card_style1-tag {
    display: inline-block;
    padding: 7px 15px;
    background-color: #003c87;
    border: 1px solid transparent;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    white-space: nowrap; }
    .card_style1-tag.-border {
      background-color: #fff !important;
      border: 1px solid #003c87;
      color: #003c87; }
  .card_style1.slider-col3 {
    opacity: 0;
    transition: opacity .3s; }
    .card_style1.slider-col3.slick-initialized {
      opacity: 1; }
  .card_style1 .slick-track {
    margin: 0 !important; }
  .card_style1 .slick-prev, .card_style1 .slick-next {
    top: 115px;
    width: 35px;
    height: 35px; }
  .card_style1 .slick-prev {
    left: -30px; }
    @media (max-width: 768.98px) {
      .card_style1 .slick-prev {
        left: -20px; } }
  .card_style1 .slick-next {
    right: -30px; }
    @media (max-width: 768.98px) {
      .card_style1 .slick-next {
        right: -20px; } }
  .card_style1 .slick-prev:before, .card_style1 .slick-next:before {
    color: #003c87;
    font-size: 3.5rem;
    font-family: 'Line Awesome Free';
    font-weight: 900; }
  .card_style1 .slick-prev:before {
    content: "\f104"; }
  .card_style1 .slick-next:before {
    content: "\f105"; }

@-ms-viewport {
  width: auto;
  initial-scale: 1; }

img {
  -webkit-backface-visibility: hidden; }

html {
  font-size: 62.5%; }

body {
  width: 100%;
  color: #323232;
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 500; }
  @media (min-width: 769px) {
    body {
      font-size: 1.6rem; } }
  body.ie11 {
    font-family: "メイリオ", Meiryo, Verdana, Roboto, "Droid Sans", sans-serif; }

img {
  width: auto;
  max-width: 100%;
  height: auto; }

a {
  transition: all .2s linear;
  opacity: 1; }
  a:hover {
    opacity: .6; }

ol,
ul {
  padding-left: 1em; }
  ol li,
  ul li {
    padding-left: 0; }

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

input[type='number'] {
  -moz-appearance: textfield; }

.h1,
h1 {
  font-size: 3.52rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 1.5em; }
  @media (min-width: 769px) {
    .h1,
    h1 {
      font-size: 4.4rem; } }

.h2,
h2 {
  font-size: 2.56rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 769px) {
    .h2,
    h2 {
      font-size: 3.2rem;
      margin-bottom: 1em; } }

.h3,
h3 {
  font-size: 2.24rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 769px) {
    .h3,
    h3 {
      font-size: 2.8rem;
      margin-bottom: 1em; } }

.h4,
h4 {
  font-size: 1.768rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 769px) {
    .h4,
    h4 {
      font-size: 2.08rem;
      margin-bottom: 1em; } }

.h5,
h5 {
  font-size: 1.632rem;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: .8em; }
  @media (min-width: 769px) {
    .h5,
    h5 {
      font-size: 1.92rem;
      margin-bottom: 1em; } }

figcaption {
  font-size: 1.1rem;
  display: block;
  padding-top: .2em;
  text-align: center;
  color: #999; }

*:last-child {
  margin-bottom: 0; }

.fa-file-pdf-o {
  color: red; }

.fa-file-word-o {
  color: blue; }

.fa-file-excel-o {
  color: green; }

a[class] {
  color: currentColor; }

a:hover {
  text-decoration: none; }

hr {
  border: 1px dashed #003c87;
  margin: 20px 0; }

.main {
  overflow: hidden; }

.inner, .header_category__wrapper {
  padding-left: 2rem;
  padding-right: 2rem; }
  @media (min-width: 1080px) {
    .inner, .header_category__wrapper {
      width: 100%;
      max-width: calc(1080px + (10vw * 2));
      padding-left: 10vw;
      padding-right: 10vw; } }

.section {
  padding-bottom: 12rem; }

.breadcrumb {
  margin: 0;
  padding: 4rem 0 6rem;
  font-size: 1.6rem;
  line-height: 1.5; }
  .breadcrumb-item.active {
    color: #323232; }
  .breadcrumb-item a {
    color: #003c87; }
  .breadcrumb-item + .breadcrumb-item::before {
    content: '>';
    float: none;
    color: #323232; }

.bg__main {
  background-color: #003c87 !important; }

.bg__sub1 {
  background-color: #589e57 !important; }
  .bg__sub1.active {
    background-color: #fff !important;
    color: #589e57 !important;
    border: 2px solid #589e57 !important; }

.bg__sub2 {
  background-color: #0060af !important; }
  .bg__sub2.active {
    background-color: #fff !important;
    color: #0060af !important;
    border: 2px solid #0060af !important; }

.bg__sub3 {
  background-color: #ba233c !important; }
  .bg__sub3.active {
    background-color: #fff !important;
    color: #ba233c !important;
    border: 2px solid #ba233c !important; }

.bg__sub4 {
  background-color: #464c8e !important; }
  .bg__sub4.active {
    background-color: #fff !important;
    color: #464c8e !important;
    border: 2px solid #464c8e !important; }

.bg__sub5 {
  background-color: #1586ac !important; }
  .bg__sub5.active {
    background-color: #fff !important;
    color: #1586ac !important;
    border: 2px solid #1586ac !important; }

.bg__sub6 {
  background-color: #9A7100 !important; }
  .bg__sub6.active {
    background-color: #fff !important;
    color: #9A7100 !important;
    border: 2px solid #9A7100 !important; }

.bg__red {
  background-color: red !important; }

.bg__white {
  background-color: #fff !important; }

.bg__black {
  background-color: #323232 !important; }

.hr_default {
  margin: 3.5rem 0;
  border-top: 1px solid #ccc; }

.mt-ttl_style1 {
  color: #003c87;
  font-size: 2.8rem;
  font-weight: bold; }

.mt-ttl_style2 {
  font-size: 2.2rem;
  border-bottom: 1px solid #323232;
  padding-bottom: 5px;
  font-weight: bold; }

.mt-ttl_style3 {
  font-weight: bold;
  border-bottom: 1px solid #003c87; }

.mt-ttl_style-en {
  font-size: 2.4rem;
  font-family: "Lato", sans-serif;
  font-weight: bold; }

.box_style1 {
  border: 1px solid #ccc;
  padding: 30px; }

.box_style2 {
  border: 2px solid #003c87;
  padding: 30px;
  border-radius: 10px; }

table {
  border-color: #ccc; }
  table th, table td {
    padding: 5px 10px; }
  table th {
    color: #003c87; }
    @media (max-width: 768.98px) {
      table th {
        width: 30% !important; } }
    table th p {
      margin-bottom: 0; }

.mt-be-columns {
  color: var(--dark-theme-white, #323232);
  width: 100%;
  display: flex;
  gap: 60px; }
  .mt-be-columns + .mt-be-columns {
    margin-top: 40px !important; }
    @media (max-width: 768.98px) {
      .mt-be-columns + .mt-be-columns {
        margin-top: 20px !important; } }
  @media (max-width: 768.98px) {
    .mt-be-columns {
      flex-direction: column;
      gap: 0px; } }
  .mt-be-columns.sp-column-block .mt-be-column {
    margin-top: 0px !important; }
  .mt-be-columns .mt-be-column {
    width: 100%; }
    @media (max-width: 768.98px) {
      .mt-be-columns .mt-be-column + .mt-be-column {
        margin-top: 20px; } }
    @media (min-width: 769px) {
      .mt-be-columns .mt-be-column + .box_style1, .mt-be-columns .mt-be-column + .box_style2 {
        height: 100%; } }
  @media (max-width: 768.98px) {
    .mt-be-columns.sp-column-block {
      display: flex !important;
      flex-direction: row !important; } }

.row-custom.column-6-4 .mt-be-column:nth-child(1) {
  flex: 0 0 60%; }

.row-custom.column-4-6 .mt-be-column:nth-child(1) {
  flex: 0 0 40%; }

.row-custom.column-7-3 .mt-be-column:nth-child(1) {
  flex: 0 0 70%; }

.row-custom.column-3-7 .mt-be-column:nth-child(1) {
  flex: 0 0 30%; }

@media (max-width: 768.98px) {
  .row-custom.reverse {
    flex-direction: column-reverse !important; } }

.fukidashi {
  position: relative;
  display: block;
  align-items: center;
  gap: 10px;
  margin: 10px auto;
  padding: 7px 10px;
  width: 100%;
  max-width: 350px;
  color: #003c87;
  font-size: 2rem;
  background: #FFF;
  border: solid 3px #003c87;
  box-sizing: border-box;
  font-weight: bold;
  position: relative;
  border-radius: 50px; }
  .fukidashi::before {
    content: "";
    position: absolute;
    bottom: -24px;
    left: 50%;
    margin-left: -15px;
    border: 12px solid transparent;
    border-top: 12px solid #FFF;
    z-index: 2; }
  .fukidashi::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 50%;
    margin-left: -17px;
    border: 14px solid transparent;
    border-top: 14px solid #003c87;
    z-index: 1; }
  .fukidashi-icon {
    background-color: #003c87;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    text-align: center;
    line-height: 1.2;
    font-size: 1.6rem; }
    .fukidashi-icon.color-1 {
      background-color: #003d87d1; }
    .fukidashi-icon.color-2 {
      background-color: #003d873e;
      color: #003c87; }
  .fukidashi-text {
    position: absolute;
    width: calc(100% - 120px);
    top: 50%;
    left: 53%;
    transform: translate(-50%, -50%);
    text-align: center; }

.day_parts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  padding-left: 0; }
  .day_parts-text {
    flex: 1;
    background: #003c87;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 1.2;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    font-weight: 500; }
    .day_parts-text.end {
      color: #fff;
      background: #ccc; }

.btn_style a {
  position: relative;
  display: block;
  padding: 1.6rem 4rem;
  font-weight: bold;
  line-height: 1.4;
  font-size: 2rem;
  background-color: #003c87;
  border-radius: 4px;
  color: #fff !important;
  width: 100%; }
  @media (max-width: 768.98px) {
    .btn_style a {
      font-size: 1.8rem; } }
  .btn_style a::after {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    content: "\f138" !important;
    display: inline-block;
    color: inherit;
    font-size: 2.6rem;
    font-family: 'Line Awesome Free';
    font-weight: 900; }

.btn_style.-half a {
  width: 50%; }
  @media (max-width: 768.98px) {
    .btn_style.-half a {
      width: 100%; } }

.btn_style.-white a {
  background-color: #fff;
  color: #003c87 !important;
  border: 2px solid #003c87; }

.btn_style_issg a {
  border: 2px solid #9A7100;
  border-radius: 5px;
  color: #9A7100;
  display: block;
  margin: auto;
  max-width: 420px;
  position: relative;
  padding: 1rem 4rem 1rem 1rem;
  text-align: center;
  font-size: 2rem;
  font-weight: bold; }
  .btn_style_issg a::after {
    content: "\f138";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translate(0, -50%); }

summary {
  display: block;
  list-style: none; }

/* 一部ブラウザで消えなかった場合は以下も追記 */
summary::-webkit-details-marker {
  display: none; }

.accordion {
  border: 2px solid #003c87; }
  .accordion + .accordion {
    border-top: none;
    border-bottom: 2px solid #003c87; }
  .accordion__inner {
    border-top: 1px dashed #003c87;
    padding-top: 20px; }
  .accordion__trigger {
    list-style: none;
    font-weight: bold;
    padding: 20px 50px 20px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2.2rem;
    position: relative; }
    @media (max-width: 768.98px) {
      .accordion__trigger {
        font-size: 1.8rem; } }
  .accordion__target {
    padding: 0 20px 20px 20px; }
  .accordion__icon {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 2px;
    min-width: 30px;
    margin-left: 10px;
    right: 20px; }
    .accordion__icon::before, .accordion__icon::after {
      display: flex;
      justify-content: center;
      align-items: center;
      content: "";
      position: absolute;
      background: #003c87;
      transition: all 0.25s ease-in-out;
      transform-origin: center center;
      top: 50%;
      left: 50%;
      width: 25px;
      height: 2px; }
    .accordion__icon::before {
      transform: translateX(-50%) rotate(90deg); }
    .accordion__icon::after {
      transform: translateX(-50%); }
  .accordion[open] .accordion__trigger {
    color: #003c87; }
    .accordion[open] .accordion__trigger .accordion__icon::before, .accordion[open] .accordion__trigger .accordion__icon::after {
      background: #003c87; }
    .accordion[open] .accordion__trigger .accordion__icon::before {
      transform: translateX(-50%) rotate(135deg); }
    .accordion[open] .accordion__trigger .accordion__icon::after {
      transform: translateX(-50%) rotate(45deg); }
  .accordion.faq .accordion__trigger::before {
    content: "Q";
    background-color: #003c87;
    color: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    margin-right: 10px; }
  .accordion.faq .accordion__inner {
    display: flex;
    align-items: flex-start; }
    .accordion.faq .accordion__inner::before {
      content: "A";
      background-color: #323232;
      color: #fff;
      flex: 0 0 40px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin-right: 10px;
      font-size: 2.2rem;
      font-weight: bold; }
  .accordion hr {
    border: 1px solid #ccc !important; }

@media (max-width: 768.98px) {
  .page.briefing .accordion__wrap .mt-be-column + .mt-be-column {
    margin-top: 0px !important; } }

.gheader {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 88;
  width: 100%;
  height: 80px;
  padding: 2rem 3rem 0;
  background-color: transparent; }
  @media (max-width: 768.98px) {
    .gheader {
      position: relative;
      padding: 2rem 2rem 0;
      background-color: #fff; } }
  .gheader__logo {
    width: 218px;
    margin: 0; }
    @media (max-width: 768.98px) {
      .gheader__logo {
        width: 170px;
        max-width: calc(100% - 140px); } }
  .gheader__hamburger {
    position: fixed;
    right: 30px;
    top: 20px;
    z-index: 99;
    display: block;
    margin: 0;
    width: 65px;
    height: 65px; }
    @media (max-width: 768.98px) {
      .gheader__hamburger {
        right: 20px;
        top: 10px;
        width: 60px;
        height: 60px; } }
  .gheader__request {
    position: fixed;
    right: 30px;
    top: 100px;
    z-index: 99;
    display: block;
    margin: 0;
    width: 65px;
    height: 65px;
    color: #003c87; }
    @media (max-width: 768.98px) {
      .gheader__request {
        right: 93px;
        top: 10px;
        width: 60px;
        height: 60px; } }
    .gheader__request-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-top: 2px;
      background-color: #FFFF50;
      border: 1px solid #fff;
      border-radius: 50%;
      box-shadow: 5px 5px 6px 0 rgba(28, 69, 110, 0.25);
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.2; }
      @media (max-width: 768.98px) {
        .gheader__request-link {
          font-size: 1.8rem; } }

/* hamburger */
.hamburger {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  appearance: none;
  background-color: #003c87;
  border: 1px solid #fff;
  border-radius: 50%;
  box-shadow: 5px 5px 6px 0 rgba(28, 69, 110, 0.25);
  outline: none;
  cursor: pointer;
  transition: all 0.4s; }
  .hamburger__line {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 25px;
    height: 2px;
    background-color: #FFFF50;
    border-radius: 1px;
    -webkit-transition: inherit;
    transition: inherit; }
    @media (max-width: 768.98px) {
      .hamburger__line {
        width: 23.5px;
        height: 2px; } }
    .hamburger__line::before, .hamburger__line::after {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: inherit;
      content: "";
      -webkit-transition: inherit;
      transition: inherit; }
    .hamburger__line::before {
      top: -9px; }
      @media (max-width: 768.98px) {
        .hamburger__line::before {
          top: -8px; } }
    .hamburger__line::after {
      top: 9px; }
      @media (max-width: 768.98px) {
        .hamburger__line::after {
          top: 8px; } }
  .hamburger__text {
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px; }
  .hamburger.-close .hamburger__line {
    background-color: transparent; }
    .hamburger.-close .hamburger__line::before, .hamburger.-close .hamburger__line::after {
      top: 0;
      background-color: #FFFF50; }
    .hamburger.-close .hamburger__line::before {
      transform: rotate(45deg); }
    .hamburger.-close .hamburger__line::after {
      transform: rotate(-45deg); }

.gfooter {
  padding: 10rem 0 5rem; }
  @media (max-width: 768.98px) {
    .gfooter {
      padding: 0 0 6rem; } }
  .gfooter__sns {
    display: flex;
    justify-content: center;
    margin-top: 6rem;
    padding: 0;
    list-style: none; }
    .gfooter__sns-item {
      margin: 0 1.5rem; }
      .gfooter__sns-item a::after {
        content: none !important; }
  .gfooter__logo {
    width: 266px;
    margin: 3rem auto 0; }
    .gfooter__logo img {
      width: 100%; }
  .gfooter__copy {
    margin: 6rem 0 0;
    font-size: 1.4rem;
    line-height: 1.4;
    text-align: center; }

.fnav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 60px; }
  @media (max-width: 768.98px) {
    .fnav {
      display: none; } }
  .fnav__category {
    margin-bottom: 2.5rem;
    padding-bottom: 1rem;
    line-height: 1.5;
    border-bottom: 1px solid #222; }
    .fnav__category_en {
      display: block;
      color: #003c87;
      font-size: 1.8rem;
      font-family: "Lato", sans-serif;
      font-weight: 400; }
    .fnav__category_ja {
      display: block;
      font-size: 2rem; }
  .fnav__list {
    padding: 0;
    list-style: none; }
    .fnav__list-item {
      margin-bottom: .5em;
      font-size: 1.8rem;
      line-height: 1.7; }
    .fnav__list-link {
      position: relative;
      display: inline-block;
      padding-left: 2rem; }
      .fnav__list-link::before {
        position: absolute;
        left: 0;
        top: 2px;
        content: "\f105";
        display: inline-block;
        color: #003c87;
        font-size: 1.6rem;
        font-family: 'Line Awesome Free';
        font-weight: 900; }

.pagetop {
  margin: 0; }
  @media (max-width: 768.98px) {
    .pagetop {
      display: block !important; } }
  .pagetop a {
    display: block;
    padding: 1rem;
    text-align: center;
    background-color: #003c87;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.4; }
  @media (min-width: 769px) {
    .pagetop {
      position: fixed;
      bottom: 2rem;
      right: 3rem;
      z-index: 77;
      width: 65px;
      height: 65px;
      overflow: hidden; }
      .pagetop a {
        position: relative;
        color: #003c87;
        width: 100%;
        height: 100%;
        border-radius: 50%; }
        .pagetop a::before {
          content: '\f106';
          font-family: 'Line Awesome Free';
          color: #FFFF50;
          display: inline-block;
          width: 1em;
          height: 1em;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -.5em;
          margin-left: -.5em;
          font-size: 2.6rem;
          font-weight: 900;
          line-height: 1; } }

.gnav {
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 88;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  padding: 16rem 0 9rem 4rem;
  background-color: #f8feff; }
  @media (max-width: 768.98px) {
    .gnav {
      padding: 14rem 0 5rem 3.4rem; } }
  .gnav.is-open {
    opacity: 1;
    visibility: visible; }
  .gnav:not(.is-open) * {
    display: none; }
  .gnav__inner {
    position: relative; }
  .gnav__logo {
    position: absolute;
    left: 3rem;
    top: 2rem;
    z-index: 99; }
    @media (max-width: 768.98px) {
      .gnav__logo {
        left: 2rem; } }
  .gnav__list {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1.75; }
    .gnav__list-item {
      margin-bottom: 4rem; }
      @media (max-width: 768.98px) {
        .gnav__list-item {
          position: relative; }
          .gnav__list-item::before, .gnav__list-item::after {
            position: absolute;
            top: 11px;
            right: 2rem;
            content: '';
            display: inline-block;
            width: 15px;
            height: 2px;
            background-color: #003c87; }
          .gnav__list-item::before {
            transform: rotate(0deg); }
          .gnav__list-item::after {
            transform: rotate(90deg); } }
      .gnav__list-item.is-active .gnav__list-title::after {
        opacity: 1;
        visibility: visible; }
      @media (min-width: 769px) {
        .gnav__list-item.is-active .gnav__child {
          opacity: 1;
          visibility: visible; } }
      @media (max-width: 768.98px) {
        .gnav__list-item.is-active::after {
          opacity: 0; }
        .gnav__list-item.is-active .gnav__child {
          opacity: 1;
          visibility: visible;
          height: auto;
          padding-top: 2rem; } }
    .gnav__list-title {
      position: relative;
      appearance: none;
      display: inline-block;
      margin: 0;
      padding: 0;
      background-color: transparent;
      border: none;
      color: #003c87;
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.2;
      text-align: left; }
      @media (max-width: 768.98px) {
        .gnav__list-title {
          display: block;
          width: 100%;
          font-size: 2rem; } }
      .gnav__list-title::after {
        opacity: 0;
        visibility: hidden;
        transition: all .2s linear;
        position: absolute;
        left: -10px;
        top: 0;
        transform: translateX(-100%);
        content: "\f138";
        display: inline-block;
        color: #003c87;
        font-size: 3rem;
        font-family: 'Line Awesome Free';
        font-weight: 900;
        line-height: 1.2; }
        @media (max-width: 768.98px) {
          .gnav__list-title::after {
            top: -3px;
            font-size: 2.4rem; } }
      @media (min-width: 769px) {
        .gnav__list-title:hover {
          text-decoration: underline; } }
  .gnav__child {
    opacity: 0;
    visibility: hidden;
    transition: all .2s linear; }
    @media (min-width: 769px) {
      .gnav__child {
        position: absolute;
        right: 0;
        top: 0;
        width: 45%;
        min-width: 500px; } }
    @media (max-width: 768.98px) {
      .gnav__child {
        overflow: hidden;
        height: 0;
        padding: 0 5.5rem 0 0; } }
    .gnav__child-image {
      margin-bottom: 4rem; }
      @media (max-width: 768.98px) {
        .gnav__child-image {
          display: none; } }
    .gnav__child-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      list-style: none;
      line-height: 1.75; }
      @media (max-width: 768.98px) {
        .gnav__child-list {
          display: block; } }
    .gnav__child-item {
      width: 45%;
      margin-bottom: 3rem;
      color: #003c87;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.5; }
      @media (max-width: 768.98px) {
        .gnav__child-item {
          width: 100%;
          margin-bottom: 1.2rem;
          font-size: 1.6rem; } }
    .gnav__child-link {
      position: relative;
      display: block;
      padding: 0 2rem 1rem 0;
      border-bottom: 1px dashed #003c87; }
      .gnav__child-link::after {
        position: absolute;
        right: 0;
        top: 0;
        content: "\f138";
        display: inline-block;
        color: inherit;
        font-size: 2rem;
        font-family: 'Line Awesome Free';
        font-weight: 900; }
  .gnav__sub {
    margin-top: 6rem;
    padding: 0;
    list-style: none; }
    .gnav__sub-item {
      margin-bottom: 2.5rem;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.2; }
  .gnav__sns {
    display: flex;
    margin-top: 4rem;
    padding: 0;
    list-style: none; }
    .gnav__sns-item {
      margin: 0 3rem 0 0; }
      .gnav__sns-item a::after {
        content: none !important; }

@media (max-width: 768.98px) {
  body.nonscroll {
    height: 100%;
    overflow: hidden; } }

.contents {
  width: 100%; }
  @media (min-width: 1080px) {
    .contents {
      min-width: 1080px;
      margin-left: auto;
      margin-right: auto; } }
  .contents main {
    padding: 60px 0; }
    @media screen and (min-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
      .contents main {
        padding-right: constant(safe-area-inset-right);
        padding-left: constant(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        padding-left: env(safe-area-inset-left); } }

.header_category {
  padding: 30px 15px;
  background-color: #f8f9fa;
  text-align: center; }
  @media screen and (min-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
    .header_category__wrapper {
      padding-right: constant(safe-area-inset-right);
      padding-left: constant(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
      padding-left: env(safe-area-inset-left); } }

.home .gheader {
  position: absolute !important;
  background-color: transparent !important; }

.home__lead {
  line-height: 1.88;
  font-weight: bold; }

.home__title {
  margin-bottom: .5em;
  font-size: 3vw;
  font-weight: bold; }
  @media (min-width: 1367px) {
    .home__title {
      font-size: 4rem; } }
  @media (max-width: 768.98px) {
    .home__title {
      margin-bottom: 3rem;
      font-size: 2.6rem; } }
  .home__title-en {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    width: 100%;
    margin: 0; }
    @media (max-width: 768.98px) {
      .home__title-en {
        left: 0;
        transform: translate(0, -37%); } }
    .home__title-en img {
      width: 100%; }

.home__button {
  position: relative;
  display: block;
  color: #fff !important;
  font-size: 2vw;
  font-weight: bold;
  line-height: 1.4;
  text-align: left; }
  @media (min-width: 1367px) {
    .home__button {
      font-size: 2.2rem; } }
  @media (max-width: 768.98px) {
    .home__button {
      font-size: 2rem; } }
  .home__button::before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16), inset 3px 3px 6px 0 rgba(255, 255, 255, 0.16);
    border: solid 1px transparent;
    border-radius: 5px;
    mix-blend-mode: overlay; }
  .home__button::after {
    position: absolute;
    right: 2.5vw;
    top: 50%;
    transform: translateY(-50%);
    transition: all .2s;
    content: "\f138";
    display: inline-block;
    color: inherit;
    font-size: 3.2vw;
    font-family: 'Line Awesome Free';
    font-weight: 900; }
    @media (min-width: 1367px) {
      .home__button::after {
        right: 3rem;
        font-size: 3.5rem; } }
    @media (max-width: 768.98px) {
      .home__button::after {
        right: 1rem;
        font-size: 3.5rem; } }
  .home__button-inner {
    position: relative;
    z-index: 2;
    display: block;
    padding: 2.2vw 6vw 2.2vw 3.3vw; }
    @media (min-width: 1367px) {
      .home__button-inner {
        padding: 2.5rem 7rem 2.5rem 3.6rem; } }
    @media (max-width: 768.98px) {
      .home__button-inner {
        padding: 2.6rem 5rem 2.4rem 2rem; } }
  .home__button:hover {
    opacity: 1; }
    .home__button:hover::after {
      transform: translate(10px, -50%); }
  .home__button-list {
    margin-top: 4.5rem; }
    .home__button-list_item {
      margin-bottom: 1rem; }

.home__fv {
  position: relative;
  z-index: 1;
  padding: 3rem 0 0;
  background: #a0ebff url(../img/home/fv_bg.jpg) no-repeat left top/100% auto; }
  @media (max-width: 768.98px) {
    .home__fv {
      padding: 8rem 0 0;
      background-image: url(../img/home/fv_bg-sp.jpg); } }
  .home__fv-title {
    max-width: 1400px;
    margin: 0 auto; }
  .home__fv-pict {
    position: absolute;
    margin: 0;
    pointer-events: none; }
    @media (max-width: 768.98px) {
      .home__fv-pict {
        display: none; } }
    .home__fv-pict.-pict1 {
      right: -94px;
      top: 50px;
      transform: translateY(-100%);
      width: 93px; }
      @media (max-width: 1079.98px) {
        .home__fv-pict.-pict1 {
          right: -20px;
          width: 80px; } }
    .home__fv-pict.-pict2 {
      left: -116px;
      bottom: -86px;
      width: 163px; }
      @media (max-width: 1079.98px) {
        .home__fv-pict.-pict2 {
          left: -35px;
          width: 150px; } }

.home__news {
  position: relative;
  z-index: 2;
  padding: 0 4rem 7rem; }
  @media (max-width: 1079.98px) {
    .home__news {
      padding: 0 10rem 7rem; } }
  @media (max-width: 768.98px) {
    .home__news {
      padding: 0 0 6rem; } }
  .home__news-title {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: -100px;
    transform: translateX(-50%);
    padding-top: 24px;
    line-height: 1.4;
    text-align: center; }
    @media (max-width: 768.98px) {
      .home__news-title {
        top: -90px; } }
    .home__news-title::before {
      position: absolute;
      left: 50%;
      top: 0;
      z-index: -1;
      transform: translateX(-50%);
      content: "";
      display: block;
      width: 112px;
      height: 124px;
      background: url(../img/home/news_ttl_bg.svg) no-repeat center center/contain; }
    .home__news-title_en {
      display: block;
      color: #003c87;
      font-size: 2.5rem;
      font-family: "Lato", sans-serif;
      font-weight: 900; }
    .home__news-title_ja {
      display: block;
      font-size: 1.4rem; }
  .home__news-list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 3px solid #fff; }
    .home__news-list_item {
      margin-top: 1rem;
      border-bottom: 3px solid #fff;
      line-height: 1.5; }
      @media (max-width: 768.98px) {
        .home__news-list_item {
          margin: 0; } }
    .home__news-list_link {
      position: relative;
      display: flex;
      padding: 1.2rem 10% 1.2rem 5%; }
      @media (max-width: 768.98px) {
        .home__news-list_link {
          display: block;
          padding: 1.5rem 2.5rem 1.8rem 0; } }
      .home__news-list_link::before {
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translateY(-50%);
        content: "\f138";
        display: inline-block;
        color: #003c87;
        font-size: 2rem;
        font-family: 'Line Awesome Free';
        font-weight: 900; }
        @media (max-width: 768.98px) {
          .home__news-list_link::before {
            right: 0;
            top: 50%;
            transform: translate(0, -50%); } }
    .home__news-list_date {
      display: block;
      width: 115px;
      color: #003c87;
      font-family: "Lato", sans-serif;
      font-weight: 900; }
      @media (max-width: 768.98px) {
        .home__news-list_date {
          width: 100%;
          margin-bottom: .5rem; } }
    .home__news-list_title {
      flex: 1 0 0%; }

.home__pickup {
  position: relative;
  z-index: 3;
  background-color: #fff; }
  .home__pickup-wrapper {
    position: relative;
    padding: 8rem 0 20rem; }
    @media (max-width: 768.98px) {
      .home__pickup-wrapper {
        padding: 7rem 0 16.5rem; } }
  .home__pickup-title {
    position: relative;
    padding-bottom: 3.4rem;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    line-height: 1.2;
    text-align: center; }
    .home__pickup-title::after {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      content: "";
      display: inline-block;
      width: 1px;
      height: 25px;
      background-color: #003c87; }
  .home__pickup-list .slick-track {
    margin: 0 !important; }
  .home__pickup-list .slick-prev, .home__pickup-list .slick-next {
    top: 145px;
    width: 35px;
    height: 35px; }
  .home__pickup-list .slick-prev {
    left: -20px; }
  .home__pickup-list .slick-next {
    right: -20px; }
  .home__pickup-list .slick-prev:before, .home__pickup-list .slick-next:before {
    color: #003c87;
    font-size: 3.5rem;
    font-family: 'Line Awesome Free';
    font-weight: 900; }
  .home__pickup-list .slick-prev:before {
    content: "\f104"; }
  .home__pickup-list .slick-next:before {
    content: "\f105"; }
  .home__pickup-list_item {
    margin: 0 10px;
    padding-bottom: 20px; }
  .home__pickup-list_link {
    position: relative;
    display: block;
    height: 100%;
    padding: 2rem 2rem 5rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 15px 0 rgba(28, 69, 110, 0.2); }
    .home__pickup-list_link::after {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      content: "\f35d";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      background-color: #003c87;
      border-radius: 50%;
      color: #fff;
      font-size: 2rem;
      font-family: 'Line Awesome Free';
      font-weight: 900; }
  .home__pickup-list_image {
    margin-bottom: 1.5rem; }
  .home__pickup-list_text {
    font-weight: bold;
    line-height: 1.7; }
  .home__pickup-pict {
    position: absolute;
    margin: 0;
    pointer-events: none; }
    .home__pickup-pict.-pict1 {
      right: -200px;
      top: -157px;
      width: 206px; }
      @media (max-width: 1079.98px) {
        .home__pickup-pict.-pict1 {
          right: -60px;
          width: 160px; } }
      @media (max-width: 768.98px) {
        .home__pickup-pict.-pict1 {
          display: none; } }
    .home__pickup-pict.-pict2 {
      left: -145px;
      bottom: -320px;
      width: 341px; }
      @media (max-width: 1079.98px) {
        .home__pickup-pict.-pict2 {
          left: -40px;
          width: 330px; } }
      @media (max-width: 768.98px) {
        .home__pickup-pict.-pict2 {
          left: 50%;
          bottom: -180px;
          transform: translateX(-46%);
          width: 200px; } }

.home__about {
  position: relative;
  z-index: 4;
  color: #fff; }
  .home__about-bg {
    height: 94vw;
    max-height: 1300px;
    min-height: 1000px;
    background-color: #003c87;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 17.5vw)); }
    @media (max-width: 768.98px) {
      .home__about-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        max-height: initial;
        min-height: initial; } }
    .home__about-bg::before, .home__about-bg::after {
      position: absolute;
      left: 0;
      z-index: -1;
      content: "";
      display: block;
      width: 100%;
      opacity: 0.5;
      mix-blend-mode: multiply; }
    .home__about-bg::before {
      top: 0;
      height: 440px;
      background-image: linear-gradient(to top, #fff 47%, #1c456e); }
    .home__about-bg::after {
      bottom: 0;
      height: 370px;
      background-image: linear-gradient(185deg, #fff 28%, #acc0cc); }
  .home__about-inner {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    width: 100%; }
    @media (max-width: 768.98px) {
      .home__about-inner {
        transform: translateY(0);
        position: static; } }
  .home__about-title {
    position: relative;
    margin-bottom: 1em;
    padding-top: 13rem;
    line-height: 1.2;
    text-align: center; }
    @media (max-width: 768.98px) {
      .home__about-title {
        padding-top: 6.5rem; } }
    .home__about-title::before {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      content: "";
      display: inline-block;
      width: 3px;
      height: 120px;
      background-color: #fff; }
      @media (max-width: 768.98px) {
        .home__about-title::before {
          width: 1px;
          height: 60px; } }
    .home__about-title_en {
      display: block;
      margin-bottom: 5rem;
      font-size: 5rem;
      font-family: "Lato", sans-serif;
      font-weight: 900; }
      @media (max-width: 768.98px) {
        .home__about-title_en {
          margin-bottom: 3rem;
          font-size: 4rem; } }
    .home__about-title_ja {
      display: block;
      font-size: 3.6rem; }
      @media (max-width: 768.98px) {
        .home__about-title_ja {
          font-size: 2.6rem; } }
  @media (min-width: 769px) {
    .home__about-content {
      padding-left: 55%; } }
  .home__about-lead {
    margin-bottom: 8rem; }
    @media (max-width: 768.98px) {
      .home__about-lead {
        margin-bottom: 5rem; } }
  @media (min-width: 769px) {
    .home__about-image {
      position: absolute;
      left: 50%;
      bottom: -1%;
      z-index: 1;
      transform: translateX(-90%);
      width: 50.3%;
      max-width: 704px; } }
  @media (max-width: 768.98px) {
    .home__about-image {
      margin: 6rem -2vw -36vw 2rem;
      text-align: center; } }
  .home__about .home__button {
    background-color: #003c87; }
    .home__about .home__button::before {
      background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.25), rgba(255, 255, 255, 0.25)); }
      @media (max-width: 768.98px) {
        .home__about .home__button::before {
          background-image: linear-gradient(103deg, rgba(165, 234, 255, 0.25), rgba(255, 255, 255, 0.25)); } }
    .home__about .home__button:hover::before {
      background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.5), rgba(255, 255, 255, 0.5)); }
  .home__about-sakuma {
    margin-top: 5vw; }
    @media (min-width: 1367px) {
      .home__about-sakuma {
        margin-top: 7rem; } }
    @media (max-width: 768.98px) {
      .home__about-sakuma {
        margin-top: 2rem; } }
    .home__about-sakuma_link {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-end;
      justify-content: center; }
      .home__about-sakuma_link:hover {
        opacity: 1; }
        .home__about-sakuma_link:hover .home__about-sakuma_image::before {
          opacity: 0;
          visibility: hidden; }
        .home__about-sakuma_link:hover .home__about-sakuma_image::after {
          opacity: 1;
          visibility: visible; }
    .home__about-sakuma_text {
      position: relative;
      display: inline-block;
      width: 250px;
      margin: 0;
      padding: 2rem .5rem 2rem 2rem;
      background-color: #e95377;
      border-radius: 10px;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.7; }
      @media (max-width: 768.98px) {
        .home__about-sakuma_text {
          width: 240px;
          font-size: 1.6rem; } }
      .home__about-sakuma_text::before {
        position: absolute;
        left: 1px;
        top: 50%;
        transform: translate(-100%, -50%);
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 10px 0;
        border-color: transparent #e95377 transparent transparent; }
    .home__about-sakuma_image {
      position: relative;
      width: 68px;
      height: 123px;
      margin: 0 3rem .5rem 0; }
      @media (max-width: 768.98px) {
        .home__about-sakuma_image {
          width: 64px; } }
      .home__about-sakuma_image::before, .home__about-sakuma_image::after {
        position: absolute;
        left: 0;
        top: 0;
        transition: all .3s;
        content: "";
        display: block;
        width: 100%;
        height: 100%; }
      .home__about-sakuma_image::before {
        background: url(../img/home/about_sakumasan.svg) no-repeat center center/contain; }
      .home__about-sakuma_image::after {
        opacity: 0;
        visibility: hidden;
        background: url(../img/home/about_sakumasan_on.svg) no-repeat center center/contain; }

.home__learning {
  position: relative;
  z-index: 5;
  margin-top: -17.5vw;
  color: #fff; }
  .home__learning-bg {
    height: 78.5vw;
    max-height: 1100px;
    min-height: 800px;
    background-image: linear-gradient(60deg, #c6b5f4 0%, #849ae8 67%);
    clip-path: polygon(0 0, 100% 17.5vw, 100% calc(100% - 17.5vw), 0 100%); }
    @media (max-width: 768.98px) {
      .home__learning-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        max-height: initial;
        min-height: initial; } }
    .home__learning-bg::after {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      content: "";
      display: block;
      width: 100%;
      height: 585px;
      opacity: 0.5;
      mix-blend-mode: multiply;
      background-image: linear-gradient(175deg, #fff 28%, #dfd1ff); }
  .home__learning-inner {
    position: absolute;
    right: 0;
    top: 12vw;
    z-index: 2;
    width: 100%; }
    @media (max-width: 768.98px) {
      .home__learning-inner {
        transform: translateY(0);
        position: static;
        padding: 10rem 0 0; } }
  @media (min-width: 769px) {
    .home__learning-content {
      padding-right: 55%; } }
  @media (min-width: 769px) {
    .home__learning-image {
      position: absolute;
      right: 50%;
      bottom: -15%;
      z-index: 1;
      transform: translateX(50vw);
      width: 80%;
      max-width: 1130px; } }
  @media (max-width: 768.98px) {
    .home__learning-image {
      margin: 2rem -4vw -30vw 2rem;
      text-align: center; } }
  .home__learning .home__button::before {
    background-image: linear-gradient(99deg, rgba(165, 196, 255, 0.25), rgba(255, 255, 255, 0.25)); }
  .home__learning .home__button:hover::before {
    background-image: linear-gradient(99deg, rgba(165, 189, 255, 0.5), rgba(255, 255, 255, 0.5)); }

.home__campuslife {
  position: relative;
  z-index: 6;
  margin-top: -17.5vw;
  color: #003c87; }
  .home__campuslife-bg {
    height: 88vw;
    max-height: 1360px;
    min-height: 900px;
    background-image: linear-gradient(140deg, #a0ebff 29%, #8afaed 109%);
    clip-path: polygon(0 17.5vw, 100% 0, 100% 100%, 0 calc(100% - 17.5vw)); }
    @media (max-width: 768.98px) {
      .home__campuslife-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        max-height: initial;
        min-height: initial; } }
    .home__campuslife-bg::after {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      content: "";
      display: block;
      width: 100%;
      height: 384px;
      opacity: 0.5;
      mix-blend-mode: multiply;
      background-image: linear-gradient(185deg, #fff 28%, #c6e8ed); }
  .home__campuslife-inner {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 100%; }
    @media (max-width: 768.98px) {
      .home__campuslife-inner {
        transform: translateY(0);
        position: static;
        padding: 10rem 0 0; } }
  @media (min-width: 769px) {
    .home__campuslife-content {
      padding-left: 55%; } }
  @media (min-width: 769px) {
    .home__campuslife-image {
      position: absolute;
      left: 50%;
      bottom: 6vw;
      z-index: 1;
      transform: translateX(-82%);
      width: 62%;
      max-width: 868px; } }
  @media (max-width: 768.98px) {
    .home__campuslife-image {
      margin: 2rem 0 -30vw 2rem;
      text-align: center; } }
  .home__campuslife .home__button {
    color: #003c87 !important; }
    .home__campuslife .home__button::before {
      background-image: linear-gradient(279deg, #e2fcff 99%, rgba(255, 255, 255, 0.8));
      border-color: #fff;
      box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.2), inset 3px 3px 6px 0 rgba(255, 255, 255, 0.16); }
    .home__campuslife .home__button:hover::before {
      background-image: linear-gradient(279deg, #e5fcff 99%, #fff); }

.home__experience {
  position: relative;
  z-index: 7;
  margin-top: -17.5vw;
  color: #003c87; }
  .home__experience-bg {
    height: 90vw;
    max-height: 1400px;
    min-height: 800px;
    background-color: #fff;
    clip-path: polygon(0 0, 100% 17.5vw, 100% calc(100% - 17.5vw), 0 100%); }
    @media (max-width: 768.98px) {
      .home__experience-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        max-height: initial;
        min-height: initial; } }
  .home__experience-inner {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 100%; }
    @media (max-width: 768.98px) {
      .home__experience-inner {
        transform: translateY(0);
        position: static;
        padding: 10rem 0 0; } }
  @media (min-width: 769px) {
    .home__experience-content {
      padding-right: 55%; } }
  @media (min-width: 769px) {
    .home__experience-image {
      position: absolute;
      right: 50%;
      bottom: 13.5vw;
      z-index: 1;
      transform: translateX(100%);
      width: 51.4%;
      max-width: 718px; } }
  @media (max-width: 768.98px) {
    .home__experience-image {
      margin: 6rem 0 24vw .4rem;
      text-align: center; } }
  .home__experience .home__button {
    color: #003c87 !important; }
    .home__experience .home__button::before {
      background-image: linear-gradient(279deg, #fcffff 99%, #fff);
      box-shadow: 3px 3px 6px 0 rgba(28, 69, 110, 0.2), inset 3px 3px 6px 0 rgba(255, 255, 255, 0.16);
      mix-blend-mode: normal; }
    .home__experience .home__button:hover::before {
      background-image: linear-gradient(279deg, rgba(252, 252, 255, 0.8) 99%, rgba(255, 255, 255, 0.9)); }

.home__career {
  position: relative;
  z-index: 8;
  margin-top: -17.5vw;
  color: #fff; }
  .home__career-bg {
    height: 90vw;
    max-height: 1300px;
    min-height: 800px;
    background-image: linear-gradient(145deg, #003c87 23%, #5513af 80%);
    clip-path: polygon(0 17.5vw, 100% 0, 100% 100%, 0 calc(100% - 17.5vw)); }
    @media (max-width: 768.98px) {
      .home__career-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        max-height: initial;
        min-height: initial;
        background-image: linear-gradient(168deg, #003c87 9%, #5513af 75%); } }
    .home__career-bg::after {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      content: "";
      display: block;
      width: 100%;
      height: 394px;
      opacity: 0.5;
      mix-blend-mode: multiply;
      background-image: linear-gradient(175deg, #fff 28%, #a9bcc9); }
  .home__career-inner {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    width: 100%; }
    @media (max-width: 768.98px) {
      .home__career-inner {
        transform: translateY(0);
        position: static;
        padding: 10rem 0 0; } }
  @media (min-width: 769px) {
    .home__career-content {
      padding-left: 55%; } }
  @media (min-width: 769px) {
    .home__career-image {
      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: 1;
      transform: translateX(-100%);
      width: 47.7%;
      max-width: 668px; } }
  @media (max-width: 768.98px) {
    .home__career-image {
      margin: 6rem -2vw -36vw 2rem;
      text-align: center; } }
  .home__career .home__button {
    background-image: linear-gradient(145deg, #3621A0 23%, #4B18AA 80%); }
    @media (max-width: 768.98px) {
      .home__career .home__button {
        background-image: linear-gradient(145deg, #2C289C 23%, #3D1FA3 80%); } }
    .home__career .home__button::before {
      background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.25), rgba(255, 255, 255, 0.25)); }
    .home__career .home__button:hover::before {
      background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.5), rgba(255, 255, 255, 0.5)); }

.home__admissions {
  position: relative;
  z-index: 9;
  margin-top: -17.5vw;
  color: #fff; }
  .home__admissions-bg {
    height: 70vw;
    max-height: 1000px;
    min-height: 700px;
    background-color: #849ae8;
    clip-path: polygon(0 0, 100% 17.5vw, 100% 100%, 0 100%); }
    @media (max-width: 768.98px) {
      .home__admissions-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        max-height: initial;
        min-height: initial; } }
    .home__admissions-bg::after {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      content: "";
      display: block;
      width: 100%;
      height: 105px;
      opacity: 0.5;
      mix-blend-mode: multiply;
      background-image: linear-gradient(to bottom, #fff, #4d8ac7); }
  .home__admissions-inner {
    position: absolute;
    right: 0;
    top: 12vw;
    z-index: 2;
    width: 100%; }
    @media (max-width: 768.98px) {
      .home__admissions-inner {
        transform: translateY(0);
        position: static;
        padding: 10rem 0 0; } }
  @media (min-width: 769px) {
    .home__admissions-content {
      padding-right: 55%; } }
  @media (min-width: 769px) {
    .home__admissions-image {
      position: absolute;
      right: 50%;
      bottom: -160px;
      z-index: 1;
      transform: translateX(63%);
      width: 96%;
      max-width: 1342px; } }
  @media (max-width: 768.98px) {
    .home__admissions-image {
      margin: 2rem 0 0;
      text-align: center; } }
  .home__admissions .home__button::before {
    background-image: linear-gradient(99deg, rgba(165, 196, 255, 0.25), rgba(255, 255, 255, 0.25)); }
  .home__admissions .home__button:hover::before {
    background-image: linear-gradient(99deg, rgba(165, 189, 255, 0.5), rgba(255, 255, 255, 0.5)); }

.page footer {
  padding: 10rem 0 6rem; }

.page.header-static header {
  position: static; }

.page.career.graduate .pagelink_style1-item a::after {
  content: "\f0a9" !important; }

.page__mv {
  position: relative;
  z-index: 0; }
  .page__mv-title {
    position: absolute;
    bottom: 6rem;
    z-index: 2;
    margin: 0;
    font-size: 4.6rem;
    font-weight: bold;
    line-height: 1.1; }
    @media (max-width: 768.98px) {
      .page__mv-title {
        font-size: 3.6rem; } }
    @media (max-width: 575.98px) {
      .page__mv-title {
        font-size: 9.6vw; } }
    .page__mv-title::before {
      position: absolute;
      left: 0;
      top: -6rem;
      content: "";
      display: block;
      width: 100px;
      height: 2px;
      background-color: #003c87; }
      @media (max-width: 768.98px) {
        .page__mv-title::before {
          top: -4rem;
          width: 40px; } }
    .page__mv-title_inner {
      display: inline-block;
      padding: 1.3rem 1.6rem 1.1rem 1.8rem;
      background-color: #003c87;
      color: #fff; }
      @media (max-width: 768.98px) {
        .page__mv-title_inner {
          padding: 1rem 1.6rem 1rem 1.8rem; } }
      @media (max-width: 575.98px) {
        .page__mv-title_inner {
          padding: 1rem 4.2vw 1rem 4.8vw; } }
      .page__mv-title_inner.-learning {
        background-image: linear-gradient(to right, #99a3ec, #8194e8); }
      .page__mv-title_inner.-campuslife {
        background-image: linear-gradient(to right, #a0ebff, #73e4e1); }
      .page__mv-title_inner.-experience {
        background-image: linear-gradient(to right, #849ae8, #5676e8); }
      .page__mv-title_inner.-career {
        background-image: linear-gradient(to right, #1f2d96, #5513af); }
    .page__mv-title small {
      font-size: 56.5%;
      font-weight: inherit; }
  .page__mv-bg {
    margin-right: calc(50% - 50vw);
    padding-left: 28rem; }
    @media (max-width: 768.98px) {
      .page__mv-bg {
        padding-left: 5.5rem; } }
    .page__mv-bg img {
      width: 100%;
      height: 380px;
      -o-object-fit: cover;
      object-fit: cover; }
      @media (max-width: 768.98px) {
        .page__mv-bg img {
          height: 300px; } }

.page__lead {
  padding-bottom: 6rem;
  text-align: center; }
  @media (max-width: 768.98px) {
    .page__lead {
      padding-bottom: 4rem;
      text-align: left; } }
  .page__lead-title {
    margin-bottom: 3rem;
    line-height: 1.7;
    color: #323232; }

@media (min-width: 769px) {
  .post__pagelink {
    padding-left: 0px;
    flex-wrap: wrap; }
    .post__pagelink:nth-child(1) {
      padding-left: 0; }
    .post__pagelink li {
      width: 100%;
      flex: 0; }
      .post__pagelink li a {
        display: inline-block;
        width: 100%; } }

.post .inner-post {
  max-width: 1150px;
  margin: 0 auto; }
  @media (max-width: 768.98px) {
    .post .inner-post {
      padding-left: 2rem;
      padding-right: 2rem; } }

@media (max-width: 768.98px) {
  .post .post_btnblock {
    margin: 40px 0; } }

.post .post_btnblock .graduate__pagelink-sp {
  width: 100%;
  padding: 15px 10px;
  margin-bottom: 10px;
  border: 2px solid #003c87;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  color: #003c87;
  font-weight: bold; }
  .post .post_btnblock .graduate__pagelink-sp:focus {
    outline: 0; }

.post.detail .post-detail {
  display: flex;
  gap: 20px; }
  .post.detail .post-detail .day {
    font-weight: bold; }

.post.detail .name {
  color: #003c87;
  font-weight: bold;
  font-size: 1.8rem;
  margin-top: 20px; }

.post.detail .editor {
  margin: 80px 0; }

.post.detail .post-detail__title {
  z-index: 2;
  margin-top: 3rem;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.1;
  position: relative; }
  @media (max-width: 768.98px) {
    .post.detail .post-detail__title {
      font-size: 2.6rem; } }
  .post.detail .post-detail__title::before {
    position: absolute;
    left: 0;
    top: -3rem;
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background-color: #003c87; }
    @media (max-width: 768.98px) {
      .post.detail .post-detail__title::before {
        top: -4rem;
        width: 40px; } }

.post.detail .post-detail__mv {
  margin: 60px 0; }

.post.detail .related-news {
  background: #e6f0f2;
  padding: 60px 20px;
  margin-top: 60px; }
  .post.detail .related-news__title {
    font-size: 2.6rem; }
    @media (max-width: 768.98px) {
      .post.detail .related-news__title {
        font-size: 2.6rem; } }

.research .ttl_style1, .research .page__lead-title {
  font-size: 2.2rem; }

.research .ttl_style2 {
  font-size: 2.8rem; }

.research .breadcrumb {
  margin-top: 0px !important; }
  @media (max-width: 768.98px) {
    .research .breadcrumb {
      margin-top: 10px; } }

.research-detail__profiel {
  display: flex;
  gap: 30px; }
  @media (max-width: 768.98px) {
    .research-detail__profiel {
      display: block; } }
  .research-detail__profiel .title {
    font-size: 3.6rem;
    margin-bottom: 30px; }
    @media (max-width: 768.98px) {
      .research-detail__profiel .title {
        font-size: 2.4rem; } }
  .research-detail__profiel .img {
    flex: 0 0 250px; }
    @media (max-width: 768.98px) {
      .research-detail__profiel .img {
        margin-bottom: 20px; } }
  .research-detail__profiel .day {
    font-size: 1.6rem;
    margin-bottom: 30px; }
  .research-detail__profiel .box-name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px; }
  .research-detail__profiel .name {
    font-size: 1.8rem;
    margin: 0 !important; }
    .research-detail__profiel .name-en {
      font-size: 1.6rem;
      font-weight: normal; }
  .research-detail__profiel .text {
    font-size: 1.6rem;
    font-weight: normal; }

.research-detail__movie {
  background: #e6f0f2;
  padding: 60px 20px;
  margin: 60px calc(50% - 50vw);
  width: 100vw; }
  .research-detail__movie .title {
    font-size: 2.6rem;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    text-align: center; }
  .research-detail__movie .box {
    margin: 0 auto;
    max-width: 560px; }
  .research-detail__movie iframe[src^="https://www.youtube.com/"] {
    width: 560px; }
    @media (max-width: 768.98px) {
      .research-detail__movie iframe[src^="https://www.youtube.com/"] {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9; } }

/* 学習院大学を知る */
.about__history {
  padding-bottom: 10rem; }
  @media (min-width: 769px) {
    .about__history-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start; } }
  .about__history-image {
    position: relative;
    z-index: 0;
    margin-bottom: 3rem; }
    @media (min-width: 769px) {
      .about__history-image {
        flex: 1 0 0%;
        margin: 0 0 0 -80px; } }
  .about__history-content {
    position: relative;
    z-index: 1;
    background-color: #fff; }
    @media (min-width: 769px) {
      .about__history-content {
        width: 47%;
        padding: 3rem 4rem 3.5rem 0; } }

.about .color__kenkyu {
  color: #1567aa; }

.about .color__tougou {
  color: #76bab2; }

.about .color__kenkyu-dark {
  color: #0c385d; }

.about .color__tougou-dark {
  color: #30757a; }

.about__features {
  padding: 7rem 0 0;
  background-color: #e6f0f2; }
  .about__features-subtitle {
    margin-bottom: 3.5rem;
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center; }
    @media (max-width: 768.98px) {
      .about__features-subtitle {
        font-size: 2rem; } }
    .about__features-subtitle > span {
      display: inline-block; }
    .about__features-subtitle_num {
      color: #003c87;
      font-size: 3.8rem; }
      @media (max-width: 768.98px) {
        .about__features-subtitle_num {
          font-size: 2.8rem; } }
  .about__features-nav_inner {
    padding: 6rem 6rem 4rem;
    background-color: #fff; }
    @media (max-width: 768.98px) {
      .about__features-nav_inner {
        padding: 4rem 2rem; } }
  .about__features-nav_list {
    padding: 0;
    list-style: none; }
    @media (min-width: 769px) {
      .about__features-nav_list {
        display: flex;
        justify-content: center; } }
  .about__features-nav_item {
    color: #fff; }
    @media (min-width: 769px) {
      .about__features-nav_item.-kenkyu {
        margin-right: -30px; } }
    .about__features-nav_item.-tougou {
      margin-top: -20px; }
      @media (min-width: 769px) {
        .about__features-nav_item.-tougou {
          margin-top: 0;
          margin-left: -30px; } }
      .about__features-nav_item.-tougou .about__features-nav_link {
        background-image: linear-gradient(to bottom, #76bab2, #30757a); }
  .about__features-nav_title {
    font-size: 3.6rem;
    font-family: YuMincho, "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Sawarabi Mincho", "Times New Roman", Times, serif;
    font-weight: 600; }
  .about__features-nav_link {
    display: block;
    opacity: .9;
    width: 340px;
    height: 340px;
    padding: 5rem 3rem;
    background-image: linear-gradient(to bottom, #4c84c4, #264262);
    border-radius: 50%;
    color: #fff;
    line-height: 1.56;
    text-align: center; }
    @media (max-width: 768.98px) {
      .about__features-nav_link {
        width: 295px;
        max-width: 100%;
        height: 295px;
        padding: 3.5rem 3rem; } }
  .about__features-nav_more {
    position: relative;
    margin: 3rem 0 0;
    font-size: 1.8rem;
    font-family: "Lato", sans-serif;
    font-weight: 900; }
    .about__features-nav_more::after {
      content: "\f13a";
      display: inline-block;
      margin-left: 1rem;
      color: inherit;
      font-family: 'Line Awesome Free';
      font-weight: 900; }
  .about__features-section {
    position: relative;
    z-index: 0; }
    .about__features-section::before {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-100%);
      z-index: -1;
      content: "";
      display: block;
      width: 50vw;
      height: 100%; }
    .about__features-section_inner {
      padding: 10rem 6rem;
      background: #fff; }
      @media (max-width: 768.98px) {
        .about__features-section_inner {
          padding: 4rem 2rem 10rem; } }
    .about__features-section_header {
      margin-bottom: 4rem; }
      @media (min-width: 769px) {
        .about__features-section_header {
          display: flex;
          align-items: flex-end;
          margin-bottom: 6rem; } }
    .about__features-section_title {
      color: #1567aa;
      font-size: 4.6rem;
      font-family: YuMincho, "游明朝", "Hiragino Mincho ProN", "ヒラギノ明朝 W3 JIS2004", "Sawarabi Mincho", "Times New Roman", Times, serif;
      font-weight: 600;
      line-height: 1; }
      @media (min-width: 769px) {
        .about__features-section_title {
          margin: 0 6rem 0 0;
          font-size: 6.4rem;
          writing-mode: vertical-rl; } }
    .about__features-section_lead {
      color: #1567aa; }
    .about__features-section.-kenkyu::before {
      background-image: linear-gradient(to bottom, #1567aa, #0c385d); }
    .about__features-section.-kenkyu .btn_default {
      background-image: linear-gradient(98deg, #1567aa, #0c385d);
      border: solid 1px rgba(255, 255, 255, 0.29); }
    .about__features-section.-tougou::before {
      right: 50%;
      transform: translateX(0);
      background-image: linear-gradient(to bottom, #76bab2, #30757a); }
    .about__features-section.-tougou .about__features-section_header {
      flex-direction: row-reverse; }
      @media (min-width: 769px) {
        .about__features-section.-tougou .about__features-section_header {
          text-align: right; } }
    .about__features-section.-tougou .about__features-section_title {
      color: #76bab2;
      text-align: right; }
      @media (min-width: 769px) {
        .about__features-section.-tougou .about__features-section_title {
          margin: 0 0 0 6rem; } }
    .about__features-section.-tougou .about__features-block_title {
      background-color: #30757a; }
    .about__features-section.-tougou .about__features-section_lead {
      color: #30757a; }
    .about__features-section.-tougou .card_style1-image::after {
      color: #76bab2; }
    .about__features-section.-tougou .slick-prev:before, .about__features-section.-tougou .slick-next:before {
      color: #76bab2; }
    .about__features-section.-tougou .btn_default {
      background-image: linear-gradient(98deg, #76bab2, #30757a);
      border: solid 1px rgba(255, 255, 255, 0.29); }
  .about__features-block {
    margin-top: 8rem; }
    .about__features-block_title {
      margin-bottom: 3.5rem;
      padding: 8px;
      background-color: #0c385d;
      color: #fff;
      font-size: 2.2rem;
      line-height: 1.5;
      text-align: center; }
      @media (max-width: 768.98px) {
        .about__features-block_title {
          font-size: 1.8rem; } }
  .about__features-list .card_style1-image::after {
    color: #1567aa; }
  .about__features-list .slick-prev:before, .about__features-list .slick-next:before {
    color: #1567aa; }
  .about__features-system {
    margin-top: 4rem; }
    @media (min-width: 769px) {
      .about__features-system {
        display: flex; } }
    .about__features-system_item {
      position: relative;
      z-index: 0; }
      @media (min-width: 769px) {
        .about__features-system_item {
          width: 50%; } }
      .about__features-system_item::before {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        content: "";
        display: block;
        opacity: .2;
        width: 100%;
        height: 100%; }
        @media (min-width: 769px) {
          .about__features-system_item::before {
            width: calc(100% + 3rem); } }
      .about__features-system_item:nth-child(1) {
        padding: 0 2rem 12rem; }
        @media (min-width: 769px) {
          .about__features-system_item:nth-child(1) {
            padding: 6rem 6rem 6rem 3rem; } }
        .about__features-system_item:nth-child(1)::before {
          background-image: linear-gradient(to top, #6eb2ab, #fff);
          border-radius: 0 0 200px 200px; }
          @media (min-width: 769px) {
            .about__features-system_item:nth-child(1)::before {
              margin-right: -3rem;
              background-image: linear-gradient(to left, #6eb2ab, #fff);
              border-radius: 0 400px 400px 0; } }
      .about__features-system_item:nth-child(2) {
        margin-top: -6rem;
        padding: 10rem 2rem 0; }
        @media (min-width: 769px) {
          .about__features-system_item:nth-child(2) {
            margin: 0;
            padding: 6rem 2rem 6rem 7rem; } }
        .about__features-system_item:nth-child(2)::before {
          background-image: linear-gradient(to bottom, #6eb2ab, #fff);
          border-radius: 200px 200px 0 0; }
          @media (min-width: 769px) {
            .about__features-system_item:nth-child(2)::before {
              margin-left: -3rem;
              background-image: linear-gradient(to right, #6eb2ab, #fff);
              border-radius: 400px 0  0 400px; } }
    .about__features-system_list {
      display: flex;
      flex-wrap: wrap;
      margin: 3rem -5px -5px;
      padding: 0;
      list-style: none; }
      @media (max-width: 768.98px) {
        .about__features-system_list {
          margin: 2rem 0 -5px; } }
      .about__features-system_list li {
        margin: 5px; }
        @media (max-width: 768.98px) {
          .about__features-system_list li {
            width: 100%;
            margin: 5px 0; } }
        .about__features-system_list li a {
          position: relative;
          display: block;
          padding: 10px 40px 10px 15px;
          color: #fff;
          font-size: 1.6rem;
          line-height: 1.2;
          background-color: #76bab2; }
          .about__features-system_list li a::after {
            position: absolute;
            right: 7px;
            top: 50%;
            transform: translateY(-50%);
            transition: all .2s;
            content: "\f138";
            display: inline-block;
            color: inherit;
            font-size: 2rem;
            font-family: 'Line Awesome Free';
            font-weight: 900; }
  .about__features-global {
    position: relative;
    z-index: 0;
    max-width: 295px;
    margin: 0 auto; }
    .about__features-global_image {
      margin: 0; }
      .about__features-global_image img {
        width: 100%; }
    .about__features-global_content {
      position: absolute;
      left: 50%;
      top: 46%;
      transform: translateX(-50%);
      z-index: 1;
      width: 80%; }
    .about__features-global_text {
      color: #30757a;
      font-size: clamp(1.2rem, 1.25vw, 1.5rem);
      font-weight: bold;
      font-feature-settings: 'palt';
      line-height: 1.53;
      text-align: center;
      white-space: nowrap; }
      @media (max-width: 1366.98px) {
        .about__features-global_text {
          font-size: clamp(1.2rem, 1vw, 1.4rem); } }
      @media (max-width: 1079.98px) {
        .about__features-global_text {
          font-size: clamp(1rem, 1.2vw, 1.3rem); } }
      @media (max-width: 768.98px) {
        .about__features-global_text {
          font-size: 4.27vw; } }
      .about__features-global_text .-number {
        margin-right: .03em;
        font-size: 313%;
        font-family: "Lato", sans-serif;
        line-height: 1; }
      .about__features-global_text .-large {
        font-size: 127%; }

.about__career {
  position: relative;
  z-index: 0;
  padding: 18rem 0 12rem; }
  @media (max-width: 768.98px) {
    .about__career {
      padding: 12rem 0 12rem; } }
  .about__career::before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    content: "";
    display: block;
    width: 100%;
    height: 400px;
    background: url(../about/img/career_bg.jpg) no-repeat center center/cover; }
  .about__career-inner {
    padding: 4rem 6rem 0;
    background-color: #fff; }
    @media (max-width: 768.98px) {
      .about__career-inner {
        padding: 4rem 2rem; } }
  .about__career-title {
    margin-bottom: 3rem;
    position: relative; }
    .about__career-title_en {
      position: absolute;
      top: -3rem;
      transform: translateY(-100%);
      display: block;
      color: #fff;
      font-size: 8rem;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      line-height: 1;
      white-space: nowrap; }
      @media (max-width: 768.98px) {
        .about__career-title_en {
          left: -2rem;
          font-size: 6rem; } }
  .about__career-subtitle {
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: bold; }
  .about__career-number {
    color: #003c87;
    font-size: 10rem;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    font-weight: bold;
    line-height: 1; }
    .about__career-number small {
      font-size: 60%; }
  .about__career-notes {
    font-size: 1.4rem; }

.about__campuslife {
  padding: 19rem 0;
  background: url(../about/img/campuslife_bg.jpg) no-repeat center center/cover;
  color: #fff; }
  @media (max-width: 768.98px) {
    .about__campuslife {
      padding: 17rem 0; } }
  .about__campuslife-inner {
    max-width: 800px;
    margin: 0 auto; }
  .about__campuslife-title {
    margin-bottom: 3rem;
    color: #fff; }
    @media (max-width: 768.98px) {
      .about__campuslife-title {
        text-align: center; } }
    .about__campuslife-title_en {
      display: block;
      margin-bottom: -.3em;
      opacity: 0.4;
      font-size: 12rem;
      font-family: "Lato", sans-serif;
      font-weight: 700;
      line-height: 1;
      white-space: nowrap; }
      @media (max-width: 768.98px) {
        .about__campuslife-title_en {
          font-size: 5.2rem; } }

/* 国際交流 */
.overseas section {
  margin-bottom: 80px; }
  .overseas section:last-child {
    margin-bottom: 0; }

.overseas__ryugaku {
  background: #e6f0f2;
  padding: 60px 0;
  position: relative;
  margin-top: 50px; }
  @media (max-width: 768.98px) {
    .overseas__ryugaku {
      padding: 20px 0; } }
  .overseas__ryugaku::before {
    content: "";
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-right: 30px solid transparent;
    border-left: 30px solid transparent;
    border-bottom: 40px solid #e6f0f2;
    border-top: 0; }
  .overseas__ryugaku-inner {
    background-color: #fff;
    padding: 60px;
    margin-bottom: 60px; }
    @media (max-width: 768.98px) {
      .overseas__ryugaku-inner {
        padding: 20px; } }
  .overseas__ryugaku-title {
    text-align: center; }
    .overseas__ryugaku-title span {
      background: linear-gradient(transparent 60%, #e6f0f2 60%); }
  .overseas__ryugaku-btnbox {
    display: flex;
    justify-content: space-between;
    gap: 80px; }
    @media (max-width: 768.98px) {
      .overseas__ryugaku-btnbox {
        display: block; } }
    .overseas__ryugaku-btnbox .btn_block {
      width: 100% !important; }
    .overseas__ryugaku-btnbox .btn_default {
      display: block;
      width: 100% !important;
      min-width: auto !important; }
  .overseas__ryugaku-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 80px; }
    .overseas__ryugaku-box .item {
      flex: 1; }
      @media (max-width: 768.98px) {
        .overseas__ryugaku-box .item {
          margin-bottom: 30px; } }
      .overseas__ryugaku-box .item .btn_block {
        width: 100% !important; }
      .overseas__ryugaku-box .item .btn_default {
        display: block;
        width: 100% !important;
        min-width: auto !important; }
    .overseas__ryugaku-box.--border {
      border-top: 1px solid #ccc;
      margin-top: 60px;
      padding-top: 60px; }
      @media (max-width: 768.98px) {
        .overseas__ryugaku-box.--border {
          margin-top: 50px;
          padding-top: 30px; } }
    .overseas__ryugaku-box.--card .item {
      flex: 0 0 calc(50% - 40px);
      padding: 20px;
      border-top: 5px solid #003c87;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
    @media (max-width: 768.98px) {
      .overseas__ryugaku-box {
        display: block; } }

.overseas section.campus {
  background: #e6f0f2;
  padding: 60px 0; }

/* さくまさん */
.sakumasan__content {
  background: #FFF2F3;
  margin-top: 120px;
  padding: 60px 0; }
  .sakumasan__content-title {
    color: #e95377;
    text-align: center; }
  .sakumasan__content-lead {
    text-align: center; }
  .sakumasan__content .sakumasan-img {
    max-width: 800px;
    margin: 60px auto; }

.sakumasan__box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px; }
  @media (max-width: 768.98px) {
    .sakumasan__box {
      display: block; } }
  .sakumasan__box .item {
    background-color: #fff;
    padding: 20px;
    border-top: 5px solid #e95377;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc; }
    @media (max-width: 768.98px) {
      .sakumasan__box .item {
        margin-bottom: 30px; } }
    .sakumasan__box .item .ttl_style5 {
      color: #e95377;
      text-align: center; }
    .sakumasan__box .item p {
      text-align: center; }

.sakumasan .tweet {
  max-width: 700px;
  margin: 40px auto; }

.sakumasan__sns-box {
  display: flex;
  gap: 30px;
  justify-content: center;
  margin-top: 100px; }

.sakumasan__sns-item {
  text-align: center;
  max-width: 235px;
  flex: 1; }
  .sakumasan__sns-item img {
    width: 60px;
    margin-top: 20px; }
  .sakumasan__sns-item .text {
    padding: 20px;
    font-weight: bold;
    font-size: 2rem;
    border-radius: 10px;
    line-height: 1.2;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 768.98px) {
      .sakumasan__sns-item .text {
        font-size: 1.4rem; } }
    .sakumasan__sns-item .text.x {
      border: 3px solid #323232;
      position: relative; }
      .sakumasan__sns-item .text.x::before {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 50%;
        margin-left: -15px;
        border: 12px solid transparent;
        border-top: 12px solid #FFF;
        z-index: 2; }
      .sakumasan__sns-item .text.x::after {
        content: "";
        position: absolute;
        bottom: -30px;
        left: 50%;
        margin-left: -17px;
        border: 14px solid transparent;
        border-top: 14px solid #323232;
        z-index: 1; }
    .sakumasan__sns-item .text.line {
      border: 3px solid #4CC764;
      position: relative; }
      .sakumasan__sns-item .text.line::before {
        content: "";
        position: absolute;
        bottom: -24px;
        left: 50%;
        margin-left: -15px;
        border: 12px solid transparent;
        border-top: 12px solid #FFF;
        z-index: 2; }
      .sakumasan__sns-item .text.line::after {
        content: "";
        position: absolute;
        bottom: -30px;
        left: 50%;
        margin-left: -17px;
        border: 14px solid transparent;
        border-top: 14px solid #4CC764;
        z-index: 1; }

.c-sp {
  display: none !important; }

.mfp-bg, .mfp-wrap {
  z-index: 999999; }

.mfp-container {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto; }

.c-modal {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 50px;
  background-color: #fff; }

.c-modal .mfp-close, .c-modal .popup-modal-dismiss {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: url(/about/img//modal_close.svg) 50% no-repeat;
  background-size: contain;
  color: transparent;
  opacity: 1; }

.c-modal__title {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5;
  text-align: center; }

.c-modal__title span {
  display: inline-block;
  color: #2da396;
  font-size: 28px; }

.c-modal__text {
  margin-top: 20px;
  font-size: 16px;
  line-height: 1.75; }

.c-modal-basic {
  display: flex;
  margin-top: 50px; }

.c-modal-basic__image {
  flex-shrink: 0;
  margin-right: 20px;
  width: 25%; }

.c-modal-basic__textarea .title {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 18px; }

.c-modal-basic__textarea ul {
  display: -ms-grid;
  display: grid;
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
  justify-content: space-between;
  font-size: 16px;
  line-height: 2;
  list-style: none; }

.c-modal-basic__textarea ul li.has_icon span {
  position: relative;
  border-bottom: 2px solid #2da396; }

.c-modal-basic__textarea ul li.has_icon span img {
  position: absolute;
  left: 105%; }

.c-modal-basic__textarea ul li.has_icon span img.icon1 {
  bottom: 0; }

.c-modal-info {
  margin-top: 30px; }

.c-modal-info__list {
  -ms-grid-columns: 1fr [3];
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-right: -30px;
  margin-left: -30px;
  list-style: none; }

.c-modal-info__list li {
  padding: 0 30px; }

.c-modal-info__list li + li {
  border-left: 1px solid #2da396; }

.c-modal-info__list li .title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #2da396;
  font-weight: 700;
  font-size: 12px; }

.c-modal-info__list li .title .num {
  margin-left: 3px;
  transform: translateY(-5px);
  font-size: 50px; }

.c-modal-info__list li .stitle {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 2; }

.c-modal-info__list li p {
  font-size: 18px;
  line-height: 1.5555555556; }

.c-modal-lang {
  margin-top: 40px; }

.c-modal-lang__box {
  padding: 12px 20px 20px;
  background: rgba(221, 234, 241, 0.25); }

.c-modal-lang__box .title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 7px;
  gap: 26px;
  font-weight: 700;
  font-size: 22px; }

.c-modal-lang__box .title span {
  flex-shrink: 0; }

.c-modal-lang__box .title:after, .c-modal-lang__box .title:before {
  width: 100%;
  height: 1px;
  background: #333;
  content: ""; }

.c-modal-lang__box .cap {
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.2;
  text-align: right; }

.c-modal-lang__box .flex {
  display: flex;
  align-items: center; }

.c-modal-lang__box .flex .text {
  flex-shrink: 0;
  margin-right: 20px;
  color: #2da396;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.7777777778; }

.c-modal-lang__box .flex .text img {
  margin-left: 10px;
  transform: translateY(-2px);
  vertical-align: middle; }

.c-modal-lang__box .flex .lineup {
  display: flex;
  flex-wrap: wrap;
  gap: 15px; }

.c-modal-lang__box .flex .lineup li {
  padding: 14px 15px;
  background: #2da396;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  list-style: none; }

.c-modal-lang__box .flex .lineup li sup {
  font-size: 10px;
  vertical-align: bottom; }

.c-modal-lang__box .flex .lineup li small {
  font-size: 14px; }

.c-modal-lang__text {
  margin-top: 30px;
  font-size: 18px;
  line-height: 1.5555555556;
  text-align: center; }

.c-modal-sports {
  -ms-grid-columns: 1fr [2];
  display: -ms-grid;
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 25px;
  gap: 10px; }

.c-modal-sports__icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.c-modal-sports__image {
  text-align: center; }

.c-modal-new {
  margin-top: 40px; }

.c-modal-new__list {
  -ms-grid-columns: 1fr [3];
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-right: -25px;
  margin-left: -25px; }

.c-modal-new__list li {
  padding: 0 25px;
  list-style: none; }

.c-modal-new__list li + li {
  border-left: 1px dashed #999; }

.c-modal-new__list li:first-child .c-modal-new__title {
  background: #c6e4dd; }

.c-modal-new__list li:nth-child(2) .c-modal-new__title {
  background: #2da396;
  color: #fff; }

.c-modal-new__list li:nth-child(3) .c-modal-new__title {
  background: #146f6f;
  color: #fff; }

.c-modal-new__title {
  margin-bottom: 15px;
  padding: 7px 10px;
  background: #000;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.2;
  text-align: center; }

.c-modal-new__text {
  font-size: 16px;
  line-height: 1.75; }

.c-modal-new__comment {
  position: relative;
  min-height: 297px;
  margin-top: 20px;
  padding: 15px;
  border-radius: 10px;
  background: #ddeaf1; }

.c-modal-new__comment:after {
  position: absolute;
  right: 0;
  bottom: 100%;
  left: 0;
  width: 0;
  height: 0;
  margin: 0 auto;
  border-width: 0 7px 17px;
  border-style: solid;
  border-color: transparent transparent #ddeaf1;
  content: ""; }

.c-modal-new__stitle {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  padding-bottom: 13px;
  border-bottom: 2px solid #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.375; }

.c-modal-new__stitle small {
  font-size: 14px; }

.c-modal-new__stitle img {
  flex-shrink: 0;
  margin-right: 10px;
  margin-left: -25px; }

.c-modal-new__text2 {
  font-size: 14px;
  line-height: 1.8571428571; }

@media screen and (max-width: 768px) {
  .c-sp {
    display: block !important; }
  .c-modal {
    padding: 50px 20px 20px; }
  .c-modal .mfp-close, .c-modal .popup-modal-dismiss {
    position: fixed;
    top: 10px;
    right: 6px; }
  .c-modal__title {
    font-size: 18px; }
  .c-modal__title span {
    font-size: 22px; }
  .c-modal__text {
    margin-top: 10px;
    font-size: 14px; }
  .c-modal-basic {
    display: block;
    margin-top: 30px; }
  .c-modal-basic__image {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
    width: 100%; }
  .c-modal-basic__textarea {
    max-width: 500px;
    margin: 0 auto; }
  .c-modal-basic__textarea .title {
    margin-bottom: 10px; }
  .c-modal-basic__textarea ul {
    -ms-grid-rows: auto [8];
    grid-template-rows: repeat(8, auto);
    font-size: 14px; }
  .c-modal-basic__textarea ul li.has_icon span img.icon1 {
    bottom: 10px;
    left: 80%; }
  .c-modal-info__list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin-right: 0;
    margin-left: 0; }
  .c-modal-info__list li {
    padding: 0; }
  .c-modal-info__list li + li {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #2da396;
    border-left-width: 0; }
  .c-modal-info__list li .title {
    margin-bottom: 5px; }
  .c-modal-info__list li .title .num {
    transform: translateY(-2px);
    font-size: 30px; }
  .c-modal-info__list li .stitle {
    margin-bottom: 10px; }
  .c-modal-info__list li p {
    font-size: 16px; }
  .c-modal-lang {
    margin-top: 30px; }
  .c-modal-lang__box .title {
    gap: 15px;
    font-size: 20px; }
  .c-modal-lang__box .cap {
    font-size: 12px; }
  .c-modal-lang__box .flex {
    display: block; }
  .c-modal-lang__box .flex .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 20px; }
  .c-modal-lang__box .flex .text img {
    margin-left: 0;
    transform: rotate(90deg); }
  .c-modal-lang__box .flex .lineup {
    gap: 10px; }
  .c-modal-lang__box .flex .lineup li {
    padding: 10px;
    font-size: 14px; }
  .c-modal-lang__box .flex .lineup li small {
    font-size: 12px; }
  .c-modal-lang__text {
    font-size: 14px; }
  .c-modal-sports {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr; }
  .c-modal-new {
    margin-top: 30px; }
  .c-modal-new__list {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    margin-right: 0;
    margin-left: 0; }
  .c-modal-new__list li {
    padding: 0;
    list-style: none; }
  .c-modal-new__list li + li {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px dashed #999;
    border-left-width: 0; }
  .c-modal-new__text {
    font-size: 14px; }
  .c-modal-new__comment {
    min-height: auto; }
  .c-modal-new__stitle img {
    margin-left: 0; }
  .c-modal-new__text2 {
    font-size: 13px; } }

@media print, screen and (min-width: 1081px) {
  .c-tab {
    display: none !important; } }

/* 卒業生紹介・内定者の声 */
.learning__block-list .slick-slide img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 332/186; }

.learning__section {
  position: relative;
  z-index: 0;
  margin-bottom: 12rem; }
  .learning__section::before {
    position: absolute;
    left: 50%;
    top: 156px;
    transform: translateX(-100%);
    z-index: -1;
    content: "";
    display: block;
    width: 50vw;
    height: calc(100% - 157px); }
  @media (min-width: 769px) {
    .learning__section-header {
      display: flex; } }
  @media (max-width: 768.98px) {
    .learning__section-header {
      margin: 0 -2rem; } }
  .learning__section-header_image {
    margin: 0; }
    @media (min-width: 769px) {
      .learning__section-header_image {
        width: 50%; } }
    .learning__section-header_image img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover; }
  .learning__section-header_content {
    padding: 2.5rem 2rem 3rem;
    color: #fff; }
    @media (min-width: 769px) {
      .learning__section-header_content {
        width: 50%;
        padding: 2.5rem 3rem 3rem; } }
  .learning__section-header_heading {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #fff; }
  .learning__section-header_title {
    margin-bottom: .3em;
    font-size: 2.6rem;
    font-weight: bold; }
  .learning__section-header_button {
    margin: 3rem 0 0;
    text-align: right; }
  .learning__section-main {
    padding: 6rem;
    background: #fff; }
    @media (max-width: 768.98px) {
      .learning__section-main {
        padding: 4rem 2rem; } }
  .learning__section-block {
    position: relative; }
    .learning__section-block:not(:last-child) {
      margin-bottom: 6rem; }
  .learning__section-title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2.5rem;
    font-size: 2.6rem;
    font-weight: bold; }
    @media (max-width: 768.98px) {
      .learning__section-title {
        font-size: 2rem; } }
    .learning__section-title > span {
      display: inline-block;
      width: 38px;
      min-width: 38px;
      margin-right: 10px;
      line-height: 1; }
      @media (max-width: 768.98px) {
        .learning__section-title > span {
          width: 30px;
          min-width: 30px; } }
  .learning__section-button {
    margin-top: 1.5rem;
    text-align: right; }
    @media (min-width: 769px) {
      .learning__section-button {
        margin: 0;
        position: absolute;
        right: 0;
        top: -2px; } }
  .learning__section.-reverse::before {
    right: 50%;
    transform: translateX(0); }
  @media (min-width: 769px) {
    .learning__section.-reverse .learning__section-header {
      flex-direction: row-reverse; }
    .learning__section.-reverse .learning__section-title {
      justify-content: flex-end;
      text-align: right; }
    .learning__section.-reverse .learning__section-button {
      left: 0;
      right: auto; } }
  .learning__section.-law::before {
    background-color: #589e57; }
  .learning__section.-law .slick-prev:before, .learning__section.-law .slick-next:before,
  .learning__section.-law .card_style1-image::after {
    color: #589e57; }
  .learning__section.-eco::before {
    background-color: #0060af; }
  .learning__section.-eco .slick-prev:before, .learning__section.-eco .slick-next:before,
  .learning__section.-eco .card_style1-image::after {
    color: #0060af; }
  .learning__section.-let::before {
    background-color: #ba233c; }
  .learning__section.-let .slick-prev:before, .learning__section.-let .slick-next:before,
  .learning__section.-let .card_style1-image::after {
    color: #ba233c; }
  .learning__section.-sci::before {
    background-color: #464c8e; }
  .learning__section.-sci .slick-prev:before, .learning__section.-sci .slick-next:before,
  .learning__section.-sci .card_style1-image::after {
    color: #464c8e; }
  .learning__section.-iss::before {
    background-color: #1586ac; }
  .learning__section.-iss .slick-prev:before, .learning__section.-iss .slick-next:before,
  .learning__section.-iss .card_style1-image::after {
    color: #1586ac; }
  .learning__section.-icr::before {
    background-color: #9A7100; }
  .learning__section.-icr .slick-prev:before, .learning__section.-icr .slick-next:before,
  .learning__section.-icr .card_style1-image::after {
    color: #9A7100; }

.learning .facultyselect label {
  position: relative;
  display: block;
  width: auto;
  border: 2px solid #003c87;
  /* ボックスの境界線を実線で指定する */
  border-radius: 5px;
  background: #fff; }
  .learning .facultyselect label::before {
    position: absolute;
    content: "\f0ab";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    font-size: 2.6rem;
    pointer-events: none;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #003c87;
    opacity: 1; }
  .learning .facultyselect label select {
    appearance: none;
    display: block;
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-radius: 5px; }

/* 学生がおすすめする授業 */
.recommend__section {
  position: relative;
  z-index: 0;
  margin-bottom: 12rem; }
  .recommend__section::before {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-100%);
    z-index: -1;
    content: "";
    display: block;
    width: 50vw;
    height: 100%; }
  .recommend__section-inner {
    padding: 0 6rem;
    background: #fff; }
    @media (max-width: 768.98px) {
      .recommend__section-inner {
        padding: 0 2rem; } }
  .recommend__section-title {
    margin-bottom: 8rem;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.3;
    text-align: center; }
    @media (max-width: 768.98px) {
      .recommend__section-title {
        margin-bottom: 6rem;
        font-size: 2.4rem; } }
  .recommend__section-main {
    margin-bottom: 6rem;
    padding-bottom: 6rem;
    border-bottom: 1px solid #323232; }
  .recommend__section-container:not(:last-child) {
    margin-bottom: 10rem; }
    @media (max-width: 768.98px) {
      .recommend__section-container:not(:last-child) {
        margin-bottom: 8rem; } }
  @media (min-width: 769px) {
    .recommend__section-container {
      display: flex;
      margin: 0 -30px; }
      .recommend__section-container.-reverse {
        flex-direction: row-reverse; } }
  .recommend__section-image {
    margin-bottom: 3rem; }
    @media (min-width: 769px) {
      .recommend__section-image {
        position: relative;
        width: 50%;
        margin: 0;
        padding: 0 30px; } }
  @media (min-width: 769px) {
    .recommend__section-content {
      width: 50%;
      padding: 0 30px; } }
  .recommend__section-point {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: bold;
    line-height: 1.4;
    text-align: center; }
    @media (min-width: 769px) {
      .recommend__section-point {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-100%);
        width: 100%;
        margin: 0;
        padding-bottom: 1.2rem; } }
    .recommend__section-point::before, .recommend__section-point::after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 1px;
      background-color: #323232; }
    .recommend__section-point::before {
      transform: rotate(45deg) translateY(-5px);
      transform-origin: right center;
      margin-right: 10px; }
    .recommend__section-point::after {
      transform: rotate(-45deg) translateY(-5px);
      transform-origin: left center;
      margin-left: 10px; }
  @media (min-width: 769px) {
    .recommend__section-heading {
      display: flex;
      justify-content: space-between; } }
  .recommend__section-heading_title {
    display: flex;
    align-items: flex-start;
    margin: 0;
    font-size: 2.2rem;
    font-weight: bold; }
    .recommend__section-heading_title > span {
      display: inline-block;
      width: 24px;
      min-width: 24px;
      margin-right: 10px;
      padding-top: 2px;
      line-height: 1; }
  .recommend__section-heading_name {
    margin: 0;
    font-weight: bold; }
    @media (max-width: 768.98px) {
      .recommend__section-heading_name {
        margin-top: .5rem;
        padding-left: 36px; } }
  .recommend__section-message {
    margin-top: 2rem;
    padding: 2rem;
    border: 1px solid #ccc;
    border-radius: 20px; }
    @media (min-width: 769px) {
      .recommend__section-message {
        margin-top: 3rem;
        padding: 2.5rem 3rem; } }
    .recommend__section-message_name {
      margin: 1.5rem 0 0;
      padding-top: 1rem;
      border-top: 1px dotted #323232; }
  .recommend__section.-reverse::before {
    right: 50%;
    transform: translateX(0); }
  .recommend__section.-law::before {
    background-color: #589e57; }
  .recommend__section.-law .recommend__section-point {
    color: #589e57; }
    .recommend__section.-law .recommend__section-point::before, .recommend__section.-law .recommend__section-point::after {
      background-color: #589e57; }
  .recommend__section.-law .slick-prev:before, .recommend__section.-law .slick-next:before,
  .recommend__section.-law .card_style1-image::after {
    color: #589e57; }
  .recommend__section.-eco::before {
    background-color: #0060af; }
  .recommend__section.-eco .recommend__section-point {
    color: #0060af; }
    .recommend__section.-eco .recommend__section-point::before, .recommend__section.-eco .recommend__section-point::after {
      background-color: #0060af; }
  .recommend__section.-eco .slick-prev:before, .recommend__section.-eco .slick-next:before,
  .recommend__section.-eco .card_style1-image::after {
    color: #0060af; }
  .recommend__section.-let::before {
    background-color: #ba233c; }
  .recommend__section.-let .recommend__section-point {
    color: #ba233c; }
    .recommend__section.-let .recommend__section-point::before, .recommend__section.-let .recommend__section-point::after {
      background-color: #ba233c; }
  .recommend__section.-let .slick-prev:before, .recommend__section.-let .slick-next:before,
  .recommend__section.-let .card_style1-image::after {
    color: #ba233c; }
  .recommend__section.-sci::before {
    background-color: #464c8e; }
  .recommend__section.-sci .recommend__section-point {
    color: #464c8e; }
    .recommend__section.-sci .recommend__section-point::before, .recommend__section.-sci .recommend__section-point::after {
      background-color: #464c8e; }
  .recommend__section.-sci .slick-prev:before, .recommend__section.-sci .slick-next:before,
  .recommend__section.-sci .card_style1-image::after {
    color: #464c8e; }
  .recommend__section.-iss::before {
    background-color: #1586ac; }
  .recommend__section.-iss .recommend__section-point {
    color: #1586ac; }
    .recommend__section.-iss .recommend__section-point::before, .recommend__section.-iss .recommend__section-point::after {
      background-color: #1586ac; }
  .recommend__section.-iss .slick-prev:before, .recommend__section.-iss .slick-next:before,
  .recommend__section.-iss .card_style1-image::after {
    color: #1586ac; }

/* キャンパス紹介 */
.campuslife__map {
  padding-top: 6rem;
  margin-bottom: 10rem; }
  @media (max-width: 768.98px) {
    .campuslife__map {
      padding-top: 4rem; } }
  .campuslife__map-main {
    margin-bottom: 6rem; }
    @media (max-width: 768.98px) {
      .campuslife__map-main {
        overflow-x: auto; } }
    @media (max-width: 768.98px) {
      .campuslife__map-main img {
        min-width: 500px; } }
    .campuslife__map-main map area {
      cursor: pointer !important; }
    @media (max-width: 768.98px) {
      .campuslife__map-main map {
        display: none; } }
  .campuslife__map-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px; }
    @media (max-width: 768.98px) {
      .campuslife__map-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px; } }
    .campuslife__map-list_image {
      position: relative;
      margin-bottom: 1.8rem; }
    .campuslife__map-list_title {
      margin-bottom: .5em;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.5; }
      .campuslife__map-list_title small {
        font-size: 1.4rem;
        font-weight: inherit; }
    .campuslife__map-list_num {
      display: inline-block;
      transform: translateY(-1px);
      min-width: 28px;
      margin-right: 10px;
      padding: 0 2px;
      background-color: #323232;
      color: #fff;
      font-size: 1.8rem;
      line-height: 28px;
      text-align: center; }
    .campuslife__map-list_text {
      margin-bottom: 0; }

.campuslife__season {
  padding-top: 6rem; }
  .campuslife__season-section {
    position: relative;
    margin-bottom: 10rem; }
    .campuslife__season-section::before {
      position: absolute;
      left: 50%;
      top: 300px;
      transform: translateX(-100%);
      z-index: -1;
      content: "";
      display: block;
      width: 50vw;
      height: calc(100% - 300px); }
      @media (max-width: 768.98px) {
        .campuslife__season-section::before {
          top: 230px;
          height: calc(100% - 230px); } }
    .campuslife__season-section.-reverse::before {
      right: 50%;
      transform: translateX(0); }
    .campuslife__season-section.-reverse .campuslife__season-container {
      flex-direction: row-reverse; }
    .campuslife__season-section.-reverse .campuslife__season-title {
      flex-direction: row-reverse; }
      .campuslife__season-section.-reverse .campuslife__season-title_en {
        margin: 0 0 0 1rem; }
      .campuslife__season-section.-reverse .campuslife__season-title_ja {
        flex-direction: row-reverse; }
    .campuslife__season-section.-spring::before {
      background-color: #ffb3d5; }
    .campuslife__season-section.-spring .campuslife__season-schedule_title {
      border-color: #ffb3d5;
      color: #ffb3d5; }
    .campuslife__season-section.-summer::before {
      background-color: #12a3fc; }
    .campuslife__season-section.-summer .campuslife__season-schedule_title {
      border-color: #12a3fc;
      color: #12a3fc; }
    .campuslife__season-section.-autumn::before {
      background-color: #922c16; }
    .campuslife__season-section.-autumn .campuslife__season-schedule_title {
      border-color: #922c16;
      color: #922c16; }
    .campuslife__season-section.-winter::before {
      background-color: #736b68; }
    .campuslife__season-section.-winter .campuslife__season-schedule_title {
      border-color: #736b68;
      color: #736b68; }
  .campuslife__season-inner {
    padding: 0 6rem 10rem;
    background-color: #fff; }
    @media (max-width: 768.98px) {
      .campuslife__season-inner {
        padding: 0 2rem 8rem; } }
  .campuslife__season-title {
    display: flex;
    align-items: flex-start;
    margin-bottom: 2rem;
    font-weight: bold;
    line-height: 1; }
    @media (max-width: 768.98px) {
      .campuslife__season-title {
        justify-content: space-between;
        margin: 0 -2rem 1.5rem; } }
    .campuslife__season-title_en {
      margin-right: 1rem;
      font-size: 8rem;
      font-family: "Lato", sans-serif;
      font-weight: 700; }
      @media (max-width: 768.98px) {
        .campuslife__season-title_en {
          font-size: 5rem; } }
    .campuslife__season-title_ja {
      display: flex;
      align-items: center;
      margin-top: .5em;
      font-size: 2.4rem; }
      @media (max-width: 768.98px) {
        .campuslife__season-title_ja {
          font-size: 2rem; } }
      .campuslife__season-title_ja::before {
        content: "";
        display: inline-block;
        width: 40px;
        height: 1px;
        margin: 0 10px;
        background-color: #323232; }
  .campuslife__season-mv {
    margin: 0 -6rem 8rem; }
    @media (max-width: 768.98px) {
      .campuslife__season-mv {
        margin: 0 -2rem 4rem; } }
  @media (min-width: 769px) {
    .campuslife__season-container {
      display: flex;
      margin: 0 -30px; } }
  .campuslife__season-schedule {
    margin-bottom: 4rem; }
    @media (min-width: 769px) {
      .campuslife__season-schedule {
        width: 50%;
        margin: 0;
        padding: 0 30px; } }
    .campuslife__season-schedule_item {
      display: flex; }
      .campuslife__season-schedule_item:not(:last-child) {
        margin-bottom: 4rem; }
    .campuslife__season-schedule_title {
      width: 66px;
      margin: 0 30px 0 0;
      border-right: 1px solid #323232;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.5; }
      .campuslife__season-schedule_title.-wide {
        width: 90px; }
    .campuslife__season-schedule_list {
      flex: 1 0 0%;
      margin: 0;
      padding: 0;
      list-style: none;
      font-weight: bold; }
  @media (min-width: 769px) {
    .campuslife__season-main {
      width: 50%;
      padding: 0 30px; } }
  .campuslife__season-main_block + .campuslife__season-main_block {
    margin-top: 6rem; }

/* 学習院大学のここが好き */
@media (min-width: 769px) {
  .like__header {
    padding-top: 80px; } }

.like__mv {
  position: relative;
  position: relative; }
  @media (min-width: 769px) {
    .like__mv-content {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center; } }
  .like__mv-title {
    margin-bottom: 2.5rem;
    text-align: center; }
    @media (min-width: 769px) {
      .like__mv-title {
        max-width: 47%;
        margin: 0 auto 3.7%; } }
  .like__mv-image {
    margin: 3rem 0 0; }
    @media (min-width: 769px) {
      .like__mv-image {
        margin: 0 -8px 0 0; } }

.like__section {
  background-color: #e6f0f2; }
  .like__section-block:not(:last-child) {
    margin-bottom: 6rem; }
  .like__section-header {
    position: relative;
    z-index: 0;
    height: 137px;
    margin: 0 6rem 5rem; }
    @media (max-width: 768.98px) {
      .like__section-header {
        height: 148px;
        margin: 0 2rem 4rem; } }
    .like__section-header::before, .like__section-header::after {
      content: "";
      position: absolute;
      bottom: -20px;
      z-index: -1;
      display: inline-block;
      width: 80px;
      height: 100%; }
      @media (max-width: 768.98px) {
        .like__section-header::before, .like__section-header::after {
          width: 60px; } }
    .like__section-header::before {
      left: 20px;
      transform: translateX(-100%);
      background: url(../campuslife/img/like/ribbon_bg_left.svg) no-repeat right bottom/contain; }
      @media (max-width: 768.98px) {
        .like__section-header::before {
          background-image: url(../campuslife/img/like/ribbon_bg_left-sp.svg); } }
    .like__section-header::after {
      right: 20px;
      transform: translateX(100%);
      background: url(../campuslife/img/like/ribbon_bg_right.svg) no-repeat left bottom/contain; }
      @media (max-width: 768.98px) {
        .like__section-header::after {
          background-image: url(../campuslife/img/like/ribbon_bg_right-sp.svg); } }
  .like__section-title {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #003c87;
    color: #fff;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.4;
    text-align: center; }
    @media (max-width: 768.98px) {
      .like__section-title {
        font-size: 2.2rem; } }
    .like__section-title span {
      color: #ffe033;
      font-size: 4rem;
      font-family: "Lato", sans-serif; }
  .like__section-subtitle {
    margin-bottom: 4rem;
    color: #003c87;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: center; }
    @media (max-width: 768.98px) {
      .like__section-subtitle {
        font-size: 2.2rem; } }
  .like__section-main {
    position: relative;
    z-index: 0;
    padding: 6rem;
    background-color: #fff; }
    @media (max-width: 768.98px) {
      .like__section-main {
        padding: 3rem 2rem 4rem; } }
    .like__section-main::before, .like__section-main::after {
      content: "";
      position: absolute;
      z-index: 1;
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid; }
    .like__section-main::before {
      left: 0;
      top: 0;
      border-color: #e6f0f2 transparent transparent transparent;
      border-width: 60px 60px 0px 0px; }
      @media (max-width: 768.98px) {
        .like__section-main::before {
          border-width: 30px 30px 0px 0px; } }
    .like__section-main::after {
      right: 0;
      bottom: 0;
      border-color: transparent transparent #e6f0f2 transparent;
      border-width: 0px 0px 60px 60px; }
      @media (max-width: 768.98px) {
        .like__section-main::after {
          border-width: 0px 0px 30px 30px; } }

.like__point {
  padding: 6rem 0; }
  .like__point-ranking {
    margin: -17px -17px 4rem; }
    @media (min-width: 769px) {
      .like__point-ranking_container {
        display: flex;
        justify-content: space-around; } }
    .like__point-ranking_item {
      position: relative;
      padding: 17px; }
      @media (min-width: 769px) {
        .like__point-ranking_item {
          display: inline-block; } }
      .like__point-ranking_item.-rank1 .like__point-ranking_rank {
        background-image: url(../campuslife/img/like/ranking_ico01.svg); }
      .like__point-ranking_item.-rank2 .like__point-ranking_rank {
        background-image: url(../campuslife/img/like/ranking_ico02.svg); }
      .like__point-ranking_item.-rank3 .like__point-ranking_rank {
        background-image: url(../campuslife/img/like/ranking_ico03.svg); }
    .like__point-ranking_inner {
      display: flex;
      align-items: flex-end;
      padding-bottom: 1rem;
      border-bottom: 1px solid #323232; }
    .like__point-ranking_rank {
      width: 100px;
      height: 90px;
      margin: 0 2rem 0 0;
      padding-top: 43px;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
      color: #fff;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1;
      text-align: center; }
      @media (max-width: 768.98px) {
        .like__point-ranking_rank {
          width: 80px;
          height: 72px;
          margin: 0 2rem 3rem 0;
          padding-top: 36px;
          font-size: 1.7rem; } }
      .like__point-ranking_rank > span {
        font-size: 4rem;
        font-family: "Lato", sans-serif; }
        @media (max-width: 768.98px) {
          .like__point-ranking_rank > span {
            font-size: 3.1rem; } }
    .like__point-ranking_title {
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.2; }
      @media (max-width: 768.98px) {
        .like__point-ranking_title {
          flex: 1 0 0%;
          font-size: 2.6rem; } }
    .like__point-ranking_num {
      display: inline-block;
      margin-left: 1rem;
      font-size: 2.2rem; }
      @media (max-width: 768.98px) {
        .like__point-ranking_num {
          display: block;
          margin: .5rem 0 0;
          text-align: right;
          font-size: 2rem; } }
  .like__point-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 80px 60px; }
    @media (max-width: 768.98px) {
      .like__point-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 40px 0; } }
    .like__point-list_item {
      position: relative; }
      .like__point-list_item.-color1 .like__point-list_message {
        border-color: #589e57; }
      .like__point-list_item.-color1 .like__point-list_point {
        color: #589e57; }
        .like__point-list_item.-color1 .like__point-list_point::before, .like__point-list_item.-color1 .like__point-list_point::after {
          background-color: #589e57; }
      .like__point-list_item.-color2 .like__point-list_message {
        border-color: #0060af; }
      .like__point-list_item.-color2 .like__point-list_point {
        color: #0060af; }
        .like__point-list_item.-color2 .like__point-list_point::before, .like__point-list_item.-color2 .like__point-list_point::after {
          background-color: #0060af; }
      .like__point-list_item.-color3 .like__point-list_message {
        border-color: #ba233c; }
      .like__point-list_item.-color3 .like__point-list_point {
        color: #ba233c; }
        .like__point-list_item.-color3 .like__point-list_point::before, .like__point-list_item.-color3 .like__point-list_point::after {
          background-color: #ba233c; }
      .like__point-list_item.-color4 .like__point-list_message {
        border-color: #464c8e; }
      .like__point-list_item.-color4 .like__point-list_point {
        color: #464c8e; }
        .like__point-list_item.-color4 .like__point-list_point::before, .like__point-list_item.-color4 .like__point-list_point::after {
          background-color: #464c8e; }
      .like__point-list_item.-color5 .like__point-list_message {
        border-color: #1586ac; }
      .like__point-list_item.-color5 .like__point-list_point {
        color: #1586ac; }
        .like__point-list_item.-color5 .like__point-list_point::before, .like__point-list_item.-color5 .like__point-list_point::after {
          background-color: #1586ac; }
    .like__point-list_image {
      margin-bottom: 3rem; }
    .like__point-list_message {
      position: relative;
      z-index: 0;
      padding: 1.5rem 2rem;
      background-color: #fff;
      border: 2px solid #323232;
      border-radius: 10px; }
      .like__point-list_message::before, .like__point-list_message::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%); }
      .like__point-list_message::before {
        top: -23px;
        border: 12px solid transparent;
        border-bottom: 12px solid #fff;
        z-index: -1; }
      .like__point-list_message::after {
        top: -28px;
        border: 14px solid transparent;
        border-bottom: 14px solid #323232;
        border-bottom-color: inherit !important;
        z-index: -2; }
    .like__point-list_point {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      font-weight: bold;
      line-height: 1.4;
      text-align: center; }
      @media (min-width: 769px) {
        .like__point-list_point {
          position: absolute;
          top: 0;
          left: 0;
          transform: translateY(-100%);
          width: 100%;
          margin: 0;
          padding-bottom: 1.2rem; } }
      .like__point-list_point::before, .like__point-list_point::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 1px;
        background-color: #323232; }
      .like__point-list_point::before {
        transform: rotate(45deg) translateY(-5px);
        transform-origin: right center;
        margin-right: 10px; }
      .like__point-list_point::after {
        transform: rotate(-45deg) translateY(-5px);
        transform-origin: left center;
        margin-left: 10px; }

.like__spot {
  padding: 0 0 12rem; }
  .like__spot-ranking {
    margin-bottom: 8rem; }
    .like__spot-ranking_item {
      position: relative; }
      @media (min-width: 769px) {
        .like__spot-ranking_item {
          display: flex;
          align-items: center; } }
      .like__spot-ranking_item + .like__spot-ranking_item {
        margin-top: 4rem; }
      .like__spot-ranking_item.-rank1 .like__spot-ranking_rank {
        background-image: url(../campuslife/img/like/ranking_ico01.svg); }
      .like__spot-ranking_item.-rank2 .like__spot-ranking_rank {
        background-image: url(../campuslife/img/like/ranking_ico02.svg); }
      .like__spot-ranking_item.-rank3 .like__spot-ranking_rank {
        background-image: url(../campuslife/img/like/ranking_ico03.svg); }
    .like__spot-ranking_rank {
      display: inline-block;
      vertical-align: bottom;
      width: 100px;
      height: 90px;
      margin: 0 2rem 0 0;
      padding-top: 43px;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
      color: #fff;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1;
      text-align: center; }
      @media (max-width: 768.98px) {
        .like__spot-ranking_rank {
          width: 80px;
          height: 72px;
          margin: 0 0 7rem;
          padding-top: 36px;
          font-size: 1.7rem; } }
      .like__spot-ranking_rank > span {
        font-size: 4rem;
        font-family: "Lato", sans-serif; }
        @media (max-width: 768.98px) {
          .like__spot-ranking_rank > span {
            font-size: 3.1rem; } }
    @media (min-width: 769px) {
      .like__spot-ranking_content {
        flex: 1 0 0%; } }
    @media (min-width: 769px) {
      .like__spot-ranking_image {
        width: 20%;
        margin-right: 4rem; } }
    .like__spot-ranking_title {
      padding-bottom: 1rem;
      border-bottom: 1px solid #323232;
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.2; }
      @media (max-width: 768.98px) {
        .like__spot-ranking_title {
          position: absolute;
          top: 9rem;
          width: 100%;
          padding: 0;
          border-bottom: none;
          font-size: 2.6rem; } }
    .like__spot-ranking_num {
      margin-left: 1rem;
      font-size: 2.2rem; }
      @media (max-width: 768.98px) {
        .like__spot-ranking_num {
          position: absolute;
          right: 0;
          top: -4rem;
          font-size: 2rem; } }

.like__sakuma {
  margin-top: 6rem;
  padding-bottom: 6rem; }
  @media (max-width: 768.98px) {
    .like__sakuma {
      padding-bottom: 12rem; } }
  .like__sakuma-text {
    position: relative;
    margin-bottom: 3.8rem;
    padding: 6rem;
    background-color: #e95377;
    border-radius: 20px;
    color: #fff;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.7;
    text-align: center; }
    @media (max-width: 768.98px) {
      .like__sakuma-text {
        padding: 3rem 2rem 4rem;
        font-size: 2.2rem; } }
    @media (max-width: 575.98px) {
      .like__sakuma-text {
        font-size: 5.8vw; } }
    .like__sakuma-text::before {
      position: absolute;
      left: 50%;
      bottom: 1px;
      transform: translate(-50%, 100%);
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0 20px;
      border-color: #e95377 transparent transparent transparent; }
  .like__sakuma-image {
    width: 120px;
    margin: 0 auto; }

/* がんばれ受験生 */
.study__header {
  background: linear-gradient(#26bcff 0%, #8afaed 100%); }
  .study__header .breadcrumb {
    padding-bottom: 30px;
    z-index: 1;
    position: relative; }

.study__mv {
  max-width: 1400px;
  margin: -50px auto 6rem; }

.study__movie {
  margin-bottom: 100px; }
  .study__movie .box {
    display: flex;
    gap: 60px; }
    @media (max-width: 768.98px) {
      .study__movie .box {
        flex-direction: column;
        gap: 30px; } }

.study__accordion {
  position: relative;
  z-index: 1; }
  .study__accordion::before {
    content: "";
    background-image: url(/campuslife/img/study/parts-1.svg);
    width: 556px;
    height: 558px;
    position: absolute;
    top: -150px;
    right: -100px;
    z-index: -1; }
    @media (max-width: 768.98px) {
      .study__accordion::before {
        right: -300px; } }
  .study__accordion::after {
    content: "";
    background-image: url(/campuslife/img/study/parts-1.svg);
    width: 556px;
    height: 558px;
    position: absolute;
    top: 350px;
    left: -150px;
    z-index: -1; }
    @media (max-width: 768.98px) {
      .study__accordion::after {
        top: 550px;
        left: -300px; } }
  @media (max-width: 1079.98px) {
    .study__accordion .inner, .study__accordion .header_category__wrapper {
      padding-left: 70px; } }
  @media (max-width: 768.98px) {
    .study__accordion .inner, .study__accordion .header_category__wrapper {
      padding-left: 2rem; } }
  .study__accordion-item {
    border-radius: 10px;
    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    margin-bottom: 40px;
    background-color: #fff; }
    @media (max-width: 768.98px) {
      .study__accordion-item {
        margin-bottom: 80px; } }
    .study__accordion-item.item-1 .accordion-head .icon {
      background: linear-gradient(45deg, #A0EBFF 0%, #8AFAED 100%); }
    .study__accordion-item.item-1 .content-text {
      border: 2px solid #A0EBFF; }
    .study__accordion-item.item-2 .accordion-head .icon {
      background: linear-gradient(45deg, #C6B5F4 0%, #849AE8 100%); }
    .study__accordion-item.item-2 .content-text {
      border: 2px solid #849AE8; }
    .study__accordion-item.item-3 .accordion-head .icon {
      background: linear-gradient(45deg, #003C87 0%, #5513AF 100%); }
    .study__accordion-item.item-3 .content-text {
      border: 2px solid #5513AF; }
    .study__accordion-item .accordion-head {
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 20px 70px 20px 20px;
      font-size: 2.8rem;
      font-weight: bold;
      position: relative;
      line-height: 1.2; }
      @media (max-width: 768.98px) {
        .study__accordion-item .accordion-head {
          display: block;
          position: relative;
          padding: 70px 70px 20px 20px;
          font-size: 2.4rem; } }
      .study__accordion-item .accordion-head .icon {
        width: 100px;
        height: 100px;
        color: #fff;
        font-family: "Lato", sans-serif;
        line-height: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: -65px; }
        @media (max-width: 768.98px) {
          .study__accordion-item .accordion-head .icon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -50px;
            margin: 0; } }
        .study__accordion-item .accordion-head .icon .en {
          font-size: 2rem; }
        .study__accordion-item .accordion-head .icon .math {
          font-size: 4rem;
          display: block; }
      .study__accordion-item .accordion-head::before {
        content: "";
        height: 30px;
        width: 2px;
        background: #003c87;
        position: absolute;
        right: 45px;
        top: 50%;
        transform: translateY(-50%); }
      .study__accordion-item .accordion-head::after {
        content: "";
        width: 30px;
        height: 2px;
        background: #003c87;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%); }
      .study__accordion-item .accordion-head[aria-expanded="true"]::before {
        content: none; }
    .study__accordion-item .accordion-content {
      background: #e6f0f2;
      position: relative;
      overflow: hidden;
      border-radius: 0 0 10px 10px; }
      .study__accordion-item .accordion-content::after {
        content: "";
        width: 100%;
        top: -10px;
        bottom: 0px;
        position: absolute;
        box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3); }
      .study__accordion-item .accordion-content .content {
        padding: 40px 20px; }
        .study__accordion-item .accordion-content .content .box {
          display: flex;
          gap: 30px;
          align-items: center;
          margin-bottom: 20px; }
          @media (max-width: 768.98px) {
            .study__accordion-item .accordion-content .content .box {
              display: block;
              margin-bottom: 40px; } }
          .study__accordion-item .accordion-content .content .box:nth-of-type(odd) .content-text::before {
            left: -23px;
            border-right: 12px solid #fff; }
          .study__accordion-item .accordion-content .content .box:nth-of-type(odd) .content-text::after {
            left: -28px;
            border: 14px solid transparent;
            border-right: 14px solid #323232;
            border-right-color: inherit !important; }
          .study__accordion-item .accordion-content .content .box:nth-of-type(even) {
            flex-direction: row-reverse; }
            .study__accordion-item .accordion-content .content .box:nth-of-type(even) .content-text::before {
              right: -23px;
              border-left: 12px solid #fff; }
            .study__accordion-item .accordion-content .content .box:nth-of-type(even) .content-text::after {
              right: -28px;
              border: 14px solid transparent;
              border-left: 14px solid #323232;
              border-left-color: inherit !important; }
        .study__accordion-item .accordion-content .content-img {
          flex: 0 0 200px; }
          @media (max-width: 768.98px) {
            .study__accordion-item .accordion-content .content-img {
              text-align: center;
              margin-bottom: 30px; } }
        .study__accordion-item .accordion-content .content-text {
          position: relative;
          z-index: 0;
          padding: 1.5rem 4rem;
          background-color: #fff;
          border-radius: 10px; }
          .study__accordion-item .accordion-content .content-text::before, .study__accordion-item .accordion-content .content-text::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%); }
          .study__accordion-item .accordion-content .content-text::before {
            border: 12px solid transparent;
            z-index: -1; }
            @media (max-width: 768.98px) {
              .study__accordion-item .accordion-content .content-text::before {
                top: -23px;
                left: 50% !important;
                transform: translateX(-50%) rotate(95deg) !important; } }
          .study__accordion-item .accordion-content .content-text::after {
            border: 14px solid transparent;
            z-index: -2; }
            @media (max-width: 768.98px) {
              .study__accordion-item .accordion-content .content-text::after {
                top: -28px;
                left: 50% !important;
                transform: translateX(-50%) rotate(95deg) !important; } }

/* キャンパススナップ */
.snap__header .breadcrumb {
  padding-bottom: 30px;
  z-index: 1;
  position: relative; }

.snap__mv {
  max-width: 1400px;
  margin: 0 auto 60px; }

.snap__slide {
  margin-top: 30px; }
  .snap__slide .slick-track {
    margin: 0 !important; }
  .snap__slide .slick-prev, .snap__slide .slick-next {
    top: 200px;
    width: 35px;
    height: 35px; }
  .snap__slide .slick-prev {
    left: -30px; }
    @media (max-width: 768.98px) {
      .snap__slide .slick-prev {
        left: -20px; } }
  .snap__slide .slick-next {
    right: -30px; }
    @media (max-width: 768.98px) {
      .snap__slide .slick-next {
        right: -20px; } }
  .snap__slide .slick-prev:before, .snap__slide .slick-next:before {
    color: #003c87;
    font-size: 3.5rem;
    font-family: 'Line Awesome Free';
    font-weight: 900; }
  .snap__slide .slick-prev:before {
    content: "\f104"; }
  .snap__slide .slick-next:before {
    content: "\f105"; }
  .snap__slide .slider-snap {
    border-bottom: 1px dashed #323232;
    margin-bottom: 60px;
    padding-bottom: 60px; }
    @media (max-width: 768.98px) {
      .snap__slide .slider-snap {
        margin-bottom: 30px;
        padding-bottom: 20px; } }
    .snap__slide .slider-snap:last-of-type {
      border-bottom: none;
      margin-bottom: 0px;
      padding-bottom: 0px; }
  .snap__slide .item {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 10px 20px; }
    @media (max-width: 768.98px) {
      .snap__slide .item {
        display: block; } }
    .snap__slide .item .left {
      width: 57%;
      padding: 15px 20px;
      box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
      position: relative; }
      @media (max-width: 768.98px) {
        .snap__slide .item .left {
          width: 100%; } }
      .snap__slide .item .left .profile {
        margin-top: 10px;
        text-align: right; }
        .snap__slide .item .left .profile-name {
          font-weight: bold;
          font-size: 1.4rem;
          margin-bottom: 5px; }
        .snap__slide .item .left .profile-detail {
          font-size: 1.3rem; }
      .snap__slide .item .left .icon {
        font-size: 2.8rem;
        font-family: "Lato", sans-serif;
        font-weight: bold;
        position: absolute;
        top: 0px;
        left: 5px;
        margin: 0.5rem;
        padding: 2px 10px;
        outline-offset: -5px;
        background-image: var(--x-gradient), var(--y-gradient), var(--x-gradient), var(--y-gradient);
        background-repeat: no-repeat;
        background-size: 100% 5px, 5px 100%, 100% 5px, 5px 100%;
        background-position: top, right, bottom, left; }
    .snap__slide .item .right {
      width: 43%;
      padding: 10px 0; }
      @media (max-width: 768.98px) {
        .snap__slide .item .right {
          width: 100%;
          margin-top: 20px; } }
      .snap__slide .item .right .comment-title {
        font-weight: bold;
        line-height: 1.2; }
      .snap__slide .item .right .comment-text {
        font-size: 1.4rem;
        line-height: 1.6; }
      .snap__slide .item .right .box {
        padding-bottom: 15px; }
        .snap__slide .item .right .box:last-of-type::after {
          content: none; }
        .snap__slide .item .right .box::after {
          content: "";
          width: 30px;
          height: 5px;
          display: block;
          margin-top: 15px; }
    .snap__slide .item.color-1 .left .icon {
      color: #78E3FD;
      --x-gradient: linear-gradient(90deg, #78E3FD 0 15px, transparent 0 calc(100% - 15px), #78E3FD calc(100% - 15px));
      --y-gradient: linear-gradient(#78E3FD 0 15px, transparent 0 calc(100% - 15px), #78E3FD calc(100% - 15px)); }
    .snap__slide .item.color-1 .right .box::after {
      background-color: #78E3FD; }
    .snap__slide .item.color-2 .left .icon {
      color: #78A1FD;
      --x-gradient: linear-gradient(90deg, #78A1FD 0 15px, transparent 0 calc(100% - 15px), #78A1FD calc(100% - 15px));
      --y-gradient: linear-gradient(#78A1FD 0 15px, transparent 0 calc(100% - 15px), #78A1FD calc(100% - 15px)); }
    .snap__slide .item.color-2 .right .box::after {
      background-color: #78A1FD; }
    .snap__slide .item.color-3 .left .icon {
      color: #00FFD8;
      --x-gradient: linear-gradient(90deg, #00FFD8 0 15px, transparent 0 calc(100% - 15px), #00FFD8 calc(100% - 15px));
      --y-gradient: linear-gradient(#00FFD8 0 15px, transparent 0 calc(100% - 15px), #00FFD8 calc(100% - 15px)); }
    .snap__slide .item.color-3 .right .box::after {
      background-color: #00FFD8; }
    .snap__slide .item.color-4 .left .icon {
      color: #7A62FF;
      --x-gradient: linear-gradient(90deg, #7A62FF 0 15px, transparent 0 calc(100% - 15px), #7A62FF calc(100% - 15px));
      --y-gradient: linear-gradient(#7A62FF 0 15px, transparent 0 calc(100% - 15px), #7A62FF calc(100% - 15px)); }
    .snap__slide .item.color-4 .right .box::after {
      background-color: #7A62FF; }

/* 桜凛祭 */
.fes__about {
  padding-top: 4rem;
  padding-bottom: 10rem; }

.fes__program {
  padding: 8rem 0 12rem;
  background-color: #e6f0f2; }
  .fes__program-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px; }
    @media (max-width: 768.98px) {
      .fes__program-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px; } }
    .fes__program-list_item {
      padding: 2.2rem 4rem 3rem;
      background-color: #fff;
      border-top: 5px solid #003c87; }
    .fes__program-list_title {
      margin-bottom: .8em;
      color: #003c87;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.36;
      text-align: center; }
      @media (max-width: 768.98px) {
        .fes__program-list_title {
          font-size: 2rem; } }

.fes__gallery {
  padding: 8rem 0 12rem; }
  .fes__gallery-main {
    margin-bottom: 2rem; }
    .fes__gallery-main figure {
      margin: 0; }
  .fes__gallery-thumb {
    margin: -7.5px;
    padding: 0;
    list-style: none; }
    @media (max-width: 768.98px) {
      .fes__gallery-thumb {
        margin: -5px; } }
    .fes__gallery-thumb_item {
      width: 10% !important;
      cursor: pointer; }
      @media (max-width: 768.98px) {
        .fes__gallery-thumb_item {
          width: 20% !important; } }
      .fes__gallery-thumb_item.slick-current {
        opacity: .6; }
      .fes__gallery-thumb_item figure {
        margin: 0;
        padding: 7.5px; }
        @media (max-width: 768.98px) {
          .fes__gallery-thumb_item figure {
            padding: 5px; } }
        .fes__gallery-thumb_item figure img {
          object-fit: cover;
          aspect-ratio: 3/2; }
    .fes__gallery-thumb .slick-track {
      width: 100% !important;
      transform: unset !important;
      /*画面幅サイズ変更に伴うサムネイル固定*/ }

/* キャリアサポート */
.career__point {
  padding-top: 4rem; }
  .career__point-title {
    margin-bottom: 6rem;
    color: #003c87;
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 1.35;
    text-align: center; }
    @media (max-width: 768.98px) {
      .career__point-title {
        font-size: 2rem; } }
    .career__point-title strong {
      display: inline-block;
      padding: 0 .2em;
      background: linear-gradient(transparent 70%, #e6f0f2 70%); }
    .career__point-title_num {
      font-size: 3.6rem; }
      @media (max-width: 768.98px) {
        .career__point-title_num {
          font-size: 2.6rem; } }
    .career__point-title_en {
      font-weight: bold;
      font-family: "Lato", sans-serif; }
  .career__point-block {
    position: relative;
    z-index: 0;
    margin-bottom: 12rem;
    padding: 0 6rem; }
    @media (max-width: 768.98px) {
      .career__point-block {
        padding: 0; } }
    .career__point-block_mv {
      margin: 0 -6rem; }
    .career__point-block_content {
      position: relative;
      z-index: 1;
      margin: -6rem 0 0;
      padding: 0 3rem;
      background-color: #fff; }
      @media (max-width: 768.98px) {
        .career__point-block_content {
          margin: 0 -2rem;
          padding: 0 2rem; } }
    .career__point-block_header {
      margin-bottom: 6rem; }
    .career__point-block_point {
      width: 100px;
      height: 100px;
      margin: 0 0 -2rem;
      transform: translateY(-4rem);
      padding-top: 1em;
      background-color: #003c87;
      border-radius: 50%;
      color: #fff;
      font-size: 2rem;
      font-family: "Lato", sans-serif;
      font-weight: bold;
      line-height: 1.04;
      text-align: center;
      white-space: nowrap; }
      .career__point-block_point span {
        display: block;
        font-size: 4rem; }

.career__program {
  padding: 8rem 0 12rem;
  background-color: #e6f0f2; }
  .career__program-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px; }
    @media (max-width: 768.98px) {
      .career__program-list {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px; } }
    .career__program-list_item {
      position: relative;
      padding: 2.5rem 2rem;
      background-color: #fff;
      border: 1px solid #ccc;
      border-top: none; }
      .career__program-list_item:before {
        position: absolute;
        left: -1px;
        top: 0;
        content: "";
        width: calc(100% + 2px);
        height: 5px;
        background-color: #003c87; }
    .career__program-list_title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.72em;
      margin-bottom: .8em;
      color: #003c87;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 1.36;
      text-align: center; }
      @media (max-width: 768.98px) {
        .career__program-list_title {
          font-size: 2rem; } }

/* 卒業生紹介・内定者の声 */
.graduate .page__fv {
  background-color: #e6f0f2; }

@media (min-width: 769px) {
  .graduate__pagelink {
    flex-wrap: nowrap; }
    .graduate__pagelink:nth-child(1) {
      padding-left: 0; }
    .graduate__pagelink li {
      width: 140px; }
      .graduate__pagelink li.-all {
        flex: none;
        width: 140px; }
      .graduate__pagelink li.-long {
        width: 200px; }
      .graduate__pagelink li.-sub {
        flex: none;
        width: 160px; } }

.graduate__list-item.-color1 .card_style1-title {
  color: #589e57; }

.graduate__list-item.-color2 .card_style1-title {
  color: #0060af; }

.graduate__list-item.-color3 .card_style1-title {
  color: #ba233c; }

.graduate__list-item.-color4 .card_style1-title {
  color: #464c8e; }

.graduate__list-item.-color5 .card_style1-title {
  color: #1586ac; }

.finance__tab .tab-content {
  background-color: #e6f0f2;
  padding: 80px 0; }

.finance__tab .nav {
  gap: 40px; }
  @media (max-width: 768.98px) {
    .finance__tab .nav {
      gap: 10px; } }

.finance__tab .nav-item {
  flex: 1;
  border: 2px solid #003c87;
  border-radius: 5px 5px 0 0; }
  .finance__tab .nav-item a {
    display: block;
    color: #003c87;
    text-align: center;
    padding: 10px 0;
    font-size: 2.0rem;
    font-weight: bold;
    position: relative; }
    @media (max-width: 768.98px) {
      .finance__tab .nav-item a {
        font-size: 1.6rem;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        padding: 10px 30px 10px 10px;
        line-height: 1.4; } }
    .finance__tab .nav-item a:hover {
      opacity: 1; }
    .finance__tab .nav-item a::after {
      content: "\f13a" !important;
      display: inline-block;
      color: inherit;
      font-size: 2.6rem;
      font-family: 'Line Awesome Free';
      font-weight: 900;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 768.98px) {
        .finance__tab .nav-item a::after {
          right: 5px; } }
  .finance__tab .nav-item .active {
    background: #003c87;
    color: #fff; }

.finance__tab .box {
  margin-bottom: 120px; }
  .finance__tab .box:last-of-type {
    margin-bottom: 0; }

.finance__tab .box2 {
  background: #fff;
  padding: 50px 30px;
  margin-bottom: 120px; }
  @media (max-width: 768.98px) {
    .finance__tab .box2 {
      margin-bottom: 80px;
      padding: 50px 20px; } }
  .finance__tab .box2:last-of-type {
    margin-bottom: 0; }

.finance__tab .label {
  flex: 1;
  border: 2px solid #003c87;
  color: #003c87;
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
  padding: 5px; }
  .finance__tab .label.active {
    color: #fff;
    background-color: #003c87; }
  .finance__tab .label-box {
    margin-top: 30px; }
  .finance__tab .label-wrap {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px; }

.finance__point {
  display: flex;
  align-items: flex-start;
  gap: 30px;
  background-color: #fff;
  padding: 50px 30px; }
  @media (max-width: 768.98px) {
    .finance__point {
      flex-direction: column;
      padding: 50px 20px; } }
  .finance__point .item {
    list-style: none;
    border-right: 1px dashed #ccc;
    padding-right: 30px;
    text-align: justify;
    flex: 1; }
    @media (max-width: 768.98px) {
      .finance__point .item {
        border-right: none;
        border-bottom: 1px dashed #ccc;
        padding-right: 0px;
        padding-bottom: 30px; } }
    .finance__point .item:last-of-type {
      border-right: none;
      padding-right: 0; }
    .finance__point .item .ttl_style4 {
      margin-bottom: 20px; }
      .finance__point .item .ttl_style4::after {
        content: "";
        width: 30px;
        height: 3px;
        background-color: #323232;
        display: block;
        margin: 5px auto 0; }
    .finance__point .item .icon {
      text-align: center;
      min-height: 120px;
      margin: 20px 0; }
      @media (max-width: 768.98px) {
        .finance__point .item .icon {
          min-height: none; } }

.finance__scholarship {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 50px 30px; }
  @media (max-width: 768.98px) {
    .finance__scholarship {
      display: block;
      padding: 30px 0px; } }
  .finance__scholarship .item {
    flex: 0 0 calc(100% / 3 - 20px);
    list-style: none;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (max-width: 768.98px) {
      .finance__scholarship .item {
        margin-bottom: 20px; } }
    .finance__scholarship .item .ttl_style4 {
      min-height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }

.finance__sumai {
  background-color: #fff;
  padding: 40px 50px; }
  @media (max-width: 768.98px) {
    .finance__sumai {
      padding: 30px 20px; } }
  .finance__sumai-box {
    display: flex;
    gap: 50px;
    margin-top: 40px; }
    @media (max-width: 768.98px) {
      .finance__sumai-box {
        display: block; } }
    .finance__sumai-box .wrap {
      flex: 1; }
      @media (max-width: 768.98px) {
        .finance__sumai-box .wrap {
          margin-bottom: 20px; } }

.finance__community-box {
  margin: 30px 0 60px; }
  @media (max-width: 768.98px) {
    .finance__community-box {
      display: block; } }
  .finance__community-box .item {
    flex: 1; }
    @media (max-width: 768.98px) {
      .finance__community-box .item {
        margin-bottom: 20px; } }
    .finance__community-box .item.map {
      display: flex;
      align-items: center;
      margin-top: 30px;
      gap: 40px; }
      @media (max-width: 768.98px) {
        .finance__community-box .item.map {
          display: block; } }
      .finance__community-box .item.map span {
        display: block; }
      .finance__community-box .item.map .images {
        position: relative;
        flex: 1; }
        .finance__community-box .item.map .images .all {
          position: absolute;
          top: 30px;
          border: 1px solid #f0f0f0;
          border-radius: 9999px;
          width: 180px;
          height: 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column; }
          @media (max-width: 1079.98px) {
            .finance__community-box .item.map .images .all {
              width: 150px;
              height: 150px; } }
          @media (max-width: 768.98px) {
            .finance__community-box .item.map .images .all {
              top: 0;
              width: 130px;
              height: 130px; } }
          .finance__community-box .item.map .images .all-text {
            font-size: 1.6rem;
            color: #003c87;
            font-weight: bold;
            text-align: center; }
            @media (max-width: 768.98px) {
              .finance__community-box .item.map .images .all-text {
                font-size: 1.4rem; } }
            .finance__community-box .item.map .images .all-text2 {
              font-size: 4rem;
              color: #003c87;
              font-weight: bold;
              font-family: "Lato", sans-serif;
              text-align: center;
              line-height: 1.2; }
              @media (max-width: 1079.98px) {
                .finance__community-box .item.map .images .all-text2 {
                  font-size: 3.5rem; } }
              @media (max-width: 768.98px) {
                .finance__community-box .item.map .images .all-text2 {
                  font-size: 3rem; } }
              .finance__community-box .item.map .images .all-text2 span {
                font-size: 2rem;
                display: inline; }
      .finance__community-box .item.map .local-list {
        flex: 1;
        list-style: none;
        padding-left: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px; }
        @media (max-width: 768.98px) {
          .finance__community-box .item.map .local-list {
            margin-top: 30px; } }
        .finance__community-box .item.map .local-list .place {
          font-size: 1.6rem;
          border-radius: 30px;
          width: 100%;
          max-width: 140px;
          text-align: center;
          color: #fff;
          font-weight: bold;
          margin: 0 auto; }
          @media (max-width: 1079.98px) {
            .finance__community-box .item.map .local-list .place {
              font-size: 1.2rem; } }
          .finance__community-box .item.map .local-list .place.hokkaido {
            background: #57B3DB; }
          .finance__community-box .item.map .local-list .place.tohoku {
            background: #3BB3AA; }
          .finance__community-box .item.map .local-list .place.kanto {
            background: #64BC6E; }
          .finance__community-box .item.map .local-list .place.chubu {
            background: #D9DE58; }
          .finance__community-box .item.map .local-list .place.kinki {
            background: #F4BB65; }
          .finance__community-box .item.map .local-list .place.chugoku {
            background: #E97951; }
          .finance__community-box .item.map .local-list .place.kyusyu {
            background: #FDAEBF; }
        .finance__community-box .item.map .local-list .text {
          font-size: 4rem;
          color: #323232;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          text-align: center; }
          @media (max-width: 1079.98px) {
            .finance__community-box .item.map .local-list .text {
              font-size: 3.5rem; } }
          @media (max-width: 768.98px) {
            .finance__community-box .item.map .local-list .text {
              font-size: 2.6rem; } }
          .finance__community-box .item.map .local-list .text span {
            font-size: 2rem;
            display: inline; }
            @media (max-width: 768.98px) {
              .finance__community-box .item.map .local-list .text span {
                font-size: 1.6rem; } }
      .finance__community-box .item.map .local-item2 {
        border-top: 1px dashed #ccc;
        border-left: 1px dashed #ccc;
        grid-area: 3 / 2 / 4 / 4;
        padding: 10px; }
        .finance__community-box .item.map .local-item2 .place2 {
          text-align: right;
          font-size: 1.6rem;
          font-weight: bold; }
          @media (max-width: 768.98px) {
            .finance__community-box .item.map .local-item2 .place2 {
              font-size: 1rem; } }
        .finance__community-box .item.map .local-item2 .text2 {
          text-align: right;
          font-size: 3.5rem;
          font-weight: bold;
          font-family: "Lato", sans-serif;
          line-height: 1; }
          @media (max-width: 768.98px) {
            .finance__community-box .item.map .local-item2 .text2 {
              font-size: 2.6rem; } }
          .finance__community-box .item.map .local-item2 .text2 span {
            font-size: 2rem;
            display: inline; }
            @media (max-width: 768.98px) {
              .finance__community-box .item.map .local-item2 .text2 span {
                font-size: 1.6rem; } }

.finance__community-card {
  display: flex;
  gap: 40px;
  margin-bottom: 60px; }
  .finance__community-card:last-child {
    margin-bottom: 0; }
  @media (max-width: 768.98px) {
    .finance__community-card {
      display: block; } }
  .finance__community-card:nth-child(odd) {
    flex-direction: row-reverse; }
  .finance__community-card .item-img {
    flex: 0 0 30%; }
    @media (max-width: 768.98px) {
      .finance__community-card .item-img {
        margin-bottom: 20px; } }
  .finance__community-card .btn_block.point3 {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768.98px) {
      .finance__community-card .btn_block.point3 {
        display: block; } }
    .finance__community-card .btn_block.point3 .btn_default {
      min-width: auto; }
      @media (max-width: 768.98px) {
        .finance__community-card .btn_block.point3 .btn_default {
          margin-bottom: 20px; } }
      .finance__community-card .btn_block.point3 .btn_default:first-child {
        flex: 0 0 42%;
        display: flex;
        align-items: center; }
        @media (max-width: 768.98px) {
          .finance__community-card .btn_block.point3 .btn_default:first-child {
            display: block; } }
      .finance__community-card .btn_block.point3 .btn_default:nth-child(2) {
        flex: 0 0 55%; }

.finance__local-box {
  display: flex;
  gap: 60px; }
  @media (max-width: 768.98px) {
    .finance__local-box {
      display: block; } }
  .finance__local-box .item {
    border: 1px solid #ccc;
    padding: 30px; }
    @media (max-width: 768.98px) {
      .finance__local-box .item {
        padding: 30px 15px;
        margin-bottom: 30px; } }

.finance__local-table {
  margin-top: 40px; }
  .finance__local-table th {
    background-color: #EEEEEE;
    color: #323232;
    width: 30%;
    padding: 0 20px; }
  .finance__local-table th, .finance__local-table td {
    border: 1px solid #ccc; }

.data-mv {
  background-image: url(/campuslife/img/data/mv-wave-sp.svg);
  background-repeat: no-repeat;
  background-position: bottom; }
  @media (min-width: 769px) {
    .data-mv {
      background-size: cover;
      background-image: url(/campuslife/img/data/mv-wave.svg); } }
  @media (min-width: 1367px) {
    .data-mv {
      background-size: auto 372px;
      background-repeat: repeat-x; } }
  .data-mv__inner {
    position: relative;
    padding-bottom: 265px; }
    @media (min-width: 769px) {
      .data-mv__inner {
        padding-bottom: 85px;
        max-width: calc(1080px + (10vw * 4)) !important;
        margin: auto; } }
  .data-mv__ttl {
    text-align: center;
    margin-bottom: 30px; }
    .data-mv__ttl_black {
      font-size: 2.1rem;
      display: block;
      font-weight: bold; }
      @media (min-width: 769px) {
        .data-mv__ttl_black {
          font-size: 2.8rem; } }
      .data-mv__ttl_black::before {
        display: inline-block;
        content: '';
        width: 20px;
        height: 17px;
        position: relative;
        background-image: url(/campuslife/img/data/line-left.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: -5px; }
        @media (min-width: 769px) {
          .data-mv__ttl_black::before {
            width: 30px;
            height: 25px; } }
      .data-mv__ttl_black::after {
        display: inline-block;
        content: '';
        width: 20px;
        height: 17px;
        position: relative;
        background-image: url(/campuslife/img/data/line-right.svg);
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: -5px; }
        @media (min-width: 769px) {
          .data-mv__ttl_black::after {
            width: 30px;
            height: 25px; } }
    .data-mv__ttl_big {
      font-size: 2.8rem;
      display: block;
      font-weight: bold; }
      @media (min-width: 769px) {
        .data-mv__ttl_big {
          font-size: 3.7rem; } }
    .data-mv__ttl_en {
      font-size: 6.2rem;
      font-family: "Lato", sans-serif;
      color: #fff;
      -webkit-text-stroke: 2px #1C456E; }
      @media (min-width: 769px) {
        .data-mv__ttl_en {
          font-size: 8.2rem; } }
    .data-mv__ttl_blue {
      font-size: 3.7rem;
      color: #003c87; }
      @media (min-width: 769px) {
        .data-mv__ttl_blue {
          font-size: 4.8rem; } }
  .data-mv__sub {
    text-align: center; }
  .data-mv__deco {
    position: absolute; }
    .data-mv__deco_01 {
      bottom: 0;
      display: none; }
      @media (min-width: 769px) {
        .data-mv__deco_01 {
          display: block; } }
    .data-mv__deco_02 {
      bottom: 0;
      width: 80px;
      right: 0; }
      @media (min-width: 769px) {
        .data-mv__deco_02 {
          right: calc(50% - 540px);
          width: auto; } }
    .data-mv__deco_03 {
      bottom: 0;
      width: 50%; }
      @media (min-width: 576px) {
        .data-mv__deco_03 {
          width: 35%; } }
      @media (min-width: 769px) {
        .data-mv__deco_03 {
          width: auto;
          left: calc(50% - 590px); } }
    .data-mv__deco_04 {
      width: 50%;
      bottom: 0;
      right: 20px; }
      @media (min-width: 576px) {
        .data-mv__deco_04 {
          width: 35%; } }
      @media (min-width: 769px) {
        .data-mv__deco_04 {
          width: auto;
          right: calc(50% - 550px); } }

.data-body {
  background: linear-gradient(to bottom right, #A0EBFF, #8AFAED);
  padding: 60px 0 140px 0; }
  .data-body__bnr {
    margin-bottom: 40px; }
    .data-body__bnr img {
      margin: auto; }

.data-anchor {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media (min-width: 769px) {
    .data-anchor {
      margin-top: 40px; } }
  .data-anchor__box {
    width: 100%;
    background-color: #fff;
    margin-top: 20px;
    text-align: center;
    position: relative;
    border-radius: 5px;
    border: 5px solid #003c87; }
    @media (min-width: 576px) {
      .data-anchor__box {
        width: 48%; } }
    @media (min-width: 769px) {
      .data-anchor__box {
        width: 31%; } }
    .data-anchor__box a {
      display: block;
      height: 100%;
      padding: 20px 20px 40px 20px; }
    .data-anchor__box:nth-child(1) .data-anchor__data {
      color: #003c87; }
    .data-anchor__box:nth-child(2) .data-anchor__data {
      color: #F59B21; }
    .data-anchor__box:nth-child(3) .data-anchor__data {
      color: #07831F; }
    .data-anchor__box:nth-child(4) .data-anchor__data {
      color: #B1A275; }
    .data-anchor__box:nth-child(5) .data-anchor__data {
      color: #FD8080; }
    .data-anchor__box:nth-child(6) .data-anchor__data {
      color: #26A4AF; }
  .data-anchor__data {
    font-weight: bold;
    font-family: "Lato", sans-serif; }
  .data-anchor__q {
    color: #003c87;
    font-weight: bold;
    font-size: 2.0rem;
    line-height: 30px; }
  .data-anchor__arrow {
    font-size: 2.6rem;
    color: #003c87;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); }

.data-box {
  background-color: #fff;
  margin-top: 60px;
  border-radius: 30px 0 30px 0;
  box-shadow: 6px 6px 4px rgba(40, 40, 160, 0.1); }
  @media (min-width: 769px) {
    .data-box {
      margin-top: 120px;
      position: relative; } }
  .data-box__top {
    padding: 0 20px 30px 20px;
    text-align: center; }
    @media (min-width: 769px) {
      .data-box__top {
        padding: 0 60px 250px 60px; } }
  .data-box img {
    margin-top: 40px; }
  @media (min-width: 769px) {
    .data-box__in {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .data-box__pink {
    position: relative;
    margin: 0 auto 60px auto;
    width: 306px; }
    @media (min-width: 1080px) {
      .data-box__pink {
        margin-bottom: 0;
        width: calc(50% - 30px); } }
    .data-box__pink__ttl {
      font-weight: bold;
      font-size: 1.8rem;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 100px;
      width: 100%; }
      @media (min-width: 769px) {
        .data-box__pink__ttl {
          font-size: 2.1rem;
          top: 100px; } }
      @media (min-width: 1080px) {
        .data-box__pink__ttl {
          font-size: 3.0rem;
          top: 90px; } }
      @media (min-width: 1367px) {
        .data-box__pink__ttl {
          top: 100px; } }
    .data-box__pink__txt {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: #FFB5B5;
      border-radius: 10px;
      color: #fff;
      top: 150px;
      width: 260px;
      padding: 20px;
      text-align: left; }
      @media (min-width: 769px) {
        .data-box__pink__txt {
          width: 60%;
          top: 130px;
          border-radius: unset;
          color: #323232;
          background-color: unset;
          padding: 0; } }
      @media (min-width: 1080px) {
        .data-box__pink__txt {
          top: 150px; } }
      @media (min-width: 1367px) {
        .data-box__pink__txt {
          top: 160px; } }
    .data-box__pink__vote {
      text-align: center;
      font-size: 2.2rem;
      background-color: #fff;
      color: #FFB5B5;
      width: fit-content;
      padding: 0 10px;
      border-radius: 10px;
      margin: auto; }
      @media (min-width: 769px) {
        .data-box__pink__vote {
          display: none; } }
    .data-box__pink:first-child {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-bottom: 100px; }
      .data-box__pink:first-child .data-box__pink__txt {
        background-color: #fff;
        color: #323232;
        border: 2px solid #FFB5B5; }
        @media (min-width: 769px) {
          .data-box__pink:first-child .data-box__pink__txt {
            border: unset;
            background-color: unset; } }
      @media (min-width: 769px) {
        .data-box__pink:first-child {
          width: calc(50% + 30px);
          margin-left: auto; } }
      .data-box__pink:first-child .data-box__pink__vote {
        background-color: #FFB5B5;
        color: #fff; }
  @media (min-width: 1080px) {
    .data-box__d4 {
      display: flex;
      justify-content: space-between; } }
  .data-box__item {
    padding: 0 20px 0 20px;
    width: 300px;
    margin: auto; }
    @media (min-width: 769px) {
      .data-box__item {
        width: 300px;
        padding: 0 50px 0 30px; } }
    @media (min-width: 1080px) {
      .data-box__item {
        width: calc(50% - 30px); } }
    .data-box__item:first-child {
      background-image: url(/campuslife/img/data/data04-1-sp.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 870px;
      padding-top: 180px; }
      @media (min-width: 769px) {
        .data-box__item:first-child {
          padding-top: 180px; } }
      @media (min-width: 1080px) {
        .data-box__item:first-child {
          background-image: url(/campuslife/img/data/data04-1.svg);
          height: 1141px;
          padding-top: 20vw; } }
      @media (min-width: 1367px) {
        .data-box__item:first-child {
          padding-top: 270px; } }
    .data-box__item:nth-child(2) {
      background-image: url(/campuslife/img/data/data04-2-sp.svg);
      background-size: contain;
      background-repeat: no-repeat;
      height: 570px;
      padding-top: 160px; }
      @media (min-width: 1080px) {
        .data-box__item:nth-child(2) {
          background-image: url(/campuslife/img/data/data04-2.svg);
          padding-top: 35vw;
          height: 1141px; } }
      @media (min-width: 1367px) {
        .data-box__item:nth-child(2) {
          padding-top: 450px; } }
  .data-box__no {
    color: #E3D8B3;
    font-size: 2.1rem;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
    @media (min-width: 769px) {
      .data-box__no {
        font-size: 2.8rem; } }
  .data-box__num {
    color: #E3D8B3;
    font-size: 4rem;
    font-weight: bold;
    font-family: "Lato", sans-serif; }
    @media (min-width: 769px) {
      .data-box__num {
        font-size: 6rem; } }
  .data-box__main {
    font-size: 1.8rem;
    font-weight: bold;
    margin-left: 10px; }
    @media (min-width: 769px) {
      .data-box__main {
        font-size: 2.1rem; } }
    @media (min-width: 1080px) {
      .data-box__main {
        font-size: 3rem;
        margin-left: 20px; } }
  .data-box__v {
    text-align: left;
    line-height: 1;
    border-bottom: 2px dashed #E3D8B3;
    margin-bottom: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: flex-end; }
    @media (min-width: 769px) {
      .data-box__v {
        margin-bottom: 10px; } }
    @media (min-width: 1080px) {
      .data-box__v {
        margin-bottom: 20px; } }
    @media (min-width: 1367px) {
      .data-box__v {
        margin-bottom: 40px; } }
  .data-box__small {
    text-align: left; }

.data-tag {
  margin: auto;
  font-family: "Lato", sans-serif;
  font-size: 2.2rem;
  font-weight: bold;
  padding: 10px 60px;
  color: #fff;
  width: fit-content; }
  @media (min-width: 769px) {
    .data-tag {
      padding: 5px 50px;
      font-size: 2.8rem; } }

.data-p {
  font-weight: bold;
  font-size: 2.2rem;
  text-align: center;
  margin-top: 20px; }
  @media (min-width: 769px) {
    .data-p {
      font-size: 2.8rem; } }

.data-sub {
  font-size: 1.8rem;
  font-weight: bold; }
  @media (min-width: 769px) {
    .data-sub {
      font-size: 2rem; } }

.data-btm {
  position: relative;
  display: inline-block;
  margin: 1.5em 0 0 0;
  padding: 30px 20px;
  min-width: 120px;
  width: 100%;
  color: #fff;
  font-size: 16px;
  background: #003c87;
  border-radius: 0 0 30px 0; }
  @media (min-width: 769px) {
    .data-btm {
      padding: 45px 75px;
      position: absolute;
      bottom: -40px;
      max-width: 700px;
      left: 50%;
      transform: translateX(-50%); } }
  .data-btm::before {
    content: "";
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-bottom: 15px solid #003c87; }
  .data-btm__ttl {
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    line-height: 38px; }
    @media (min-width: 769px) {
      .data-btm__ttl {
        font-size: 2.8rem; } }
  .data-btm__btn {
    background-color: #003c87;
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 10px 30px;
    text-align: center;
    margin: 30px auto 0 auto;
    display: block;
    width: fit-content;
    position: relative; }
    @media (min-width: 769px) {
      .data-btm__btn {
        padding: 17px 85px;
        font-size: 2rem; } }
    .data-btm__btn::after {
      position: absolute;
      content: "\f138";
      font-family: 'Line Awesome Free';
      font-weight: 900;
      right: 10px;
      top: 50%;
      transform: translateY(-50%); }

.data-01 .data-tag {
  background-color: #003c87; }

@media (min-width: 769px) {
  .data-02 .data-box__top {
    padding-bottom: 190px; } }

.data-02 .data-tag {
  background-color: #F59B21; }

.data-02 .data-btm {
  background-color: #F59B21; }
  .data-02 .data-btm::before {
    border-bottom: 15px solid #F59B21; }

@media (min-width: 769px) {
  .data-03 .data-box__top {
    padding-bottom: 300px; } }

.data-03 .data-tag {
  background-color: #07831F; }

.data-03 .data-btm {
  background-color: #07831F; }
  .data-03 .data-btm::before {
    border-bottom: 15px solid #07831F; }

@media (min-width: 769px) {
  .data-04 .data-box__top {
    padding-bottom: 320px; } }

@media (min-width: 1080px) {
  .data-04 .data-box__top {
    padding-bottom: 280px; } }

.data-04 .data-tag {
  background-color: #B1A275; }

.data-04 .data-btm {
  background-color: #B1A275; }
  .data-04 .data-btm::before {
    border-bottom: 15px solid #B1A275; }

.data-05 .data-box__top {
  padding-bottom: 40px; }
  @media (min-width: 769px) {
    .data-05 .data-box__top {
      padding-bottom: 50px; } }

.data-05 .data-tag {
  background-color: #FD8080; }

.data-05 .data-btm {
  background-color: #FD8080; }
  .data-05 .data-btm::before {
    border-bottom: 15px solid #FD8080; }

@media (min-width: 769px) {
  .data-06 .data-box__top {
    padding-bottom: 420px; } }

.data-06 .data-tag {
  background-color: #26A4AF; }

.data-06 .data-btm {
  background-color: #26A4AF; }
  .data-06 .data-btm::before {
    border-bottom: 15px solid #26A4AF; }

.search #___gcse_0 * {
  box-sizing: content-box; }

.search .gsc-input-box {
  height: auto; }

.search .gsc-result .gs-title {
  overflow: visible; }

.search .gsc-table-result,
.search .gsc-thumbnail-inside,
.search .gsc-url-top {
  padding-left: 0; }

.search .gsc-cursor-box.gs-bidi-start-align {
  text-align: center; }
  .search .gsc-cursor-box.gs-bidi-start-align .gsc-cursor-page {
    padding: 5px 10px;
    display: inline-block;
    background-color: #eee;
    border: 1px #ccc solid;
    color: #323232; }
  .search .gsc-cursor-box.gs-bidi-start-align .gsc-cursor-current-page {
    background-color: #003c87;
    color: #fff; }

.search .gsc-control-wrapper-cse form {
  background-color: #eee;
  padding: 12px 20px;
  box-sizing: border-box !important; }

.guideline .item__color {
  display: flex; }
  .guideline .item__color .color {
    display: flex;
    align-items: center;
    flex: 1 0 50%;
    justify-content: center;
    min-height: 2em;
    color: #fff; }
    .guideline .item__color .color.txt__left {
      justify-content: flex-start; }

.guideline .clr_sub_1_l {
  background-color: #aacea9; }

.guideline .clr_sub_2_l {
  background-color: #74a8d3; }

.guideline .clr_sub_3_l {
  background-color: #db8c99; }

.guideline .clr_sub_4_l {
  background-color: #9ea1c3; }

.guideline .grid_item {
  text-align: center;
  border: 1px #ccc solid;
  background-color: #eee; }

.guideline .label_id {
  display: inline-block !important;
  padding: 2px 10px !important;
  color: #fff !important;
  background-color: #323232 !important; }
  .guideline .label_id.txt_id {
    background-color: #900 !important; }

.guideline pre {
  padding: 0;
  border: 0;
  border-radius: 0;
  background-color: #fff; }

.guideline code {
  margin: 0;
  padding: 10px; }

@media print {
  html {
    width: 1280px;
    transform: scale(0.8);
    transform-origin: left top; }
  body {
    padding-left: 0; }
    body::before, body::after {
      display: none; }
  .box__fixed_top {
    position: absolute; }
  .contents main {
    width: 100%; }
  .contents .box__section {
    margin-bottom: 40px; }
    .contents .box__section > .box__section {
      margin-bottom: 30px; }
  *[class^=col-] {
    float: left; }
  .row > *[class^=col-xs-1],
  .row > *[class^=col-sm-1],
  .row > *[class^=col-md-1],
  .row > *[class^=col-lg-1] {
    width: 8.33333%; }
  .row > *[class^=col-xs-2],
  .row > *[class^=col-sm-2],
  .row > *[class^=col-md-2],
  .row > *[class^=col-lg-2] {
    width: 16.66667%; }
  .row > *[class^=col-xs-3],
  .row > *[class^=col-sm-3],
  .row > *[class^=col-md-3],
  .row > *[class^=col-lg-3] {
    width: 25%; }
  .row > *[class^=col-xs-4],
  .row > *[class^=col-sm-4],
  .row > *[class^=col-md-4],
  .row > *[class^=col-lg-4] {
    width: 33.33333%; }
  .row > *[class^=col-xs-5],
  .row > *[class^=col-sm-5],
  .row > *[class^=col-md-5],
  .row > *[class^=col-lg-5] {
    width: 41.66667%; }
  .row > *[class^=col-xs-6],
  .row > *[class^=col-sm-6],
  .row > *[class^=col-md-6],
  .row > *[class^=col-lg-6] {
    width: 50%; }
  .row > *[class^=col-xs-7],
  .row > *[class^=col-sm-7],
  .row > *[class^=col-md-7],
  .row > *[class^=col-lg-7] {
    width: 58.33333%; }
  .row > *[class^=col-xs-8],
  .row > *[class^=col-sm-8],
  .row > *[class^=col-md-8],
  .row > *[class^=col-lg-8] {
    width: 66.66667%; }
  .row > *[class^=col-xs-9],
  .row > *[class^=col-sm-9],
  .row > *[class^=col-md-9],
  .row > *[class^=col-lg-9] {
    width: 75%; }
  .row > *[class^=col-xs-10],
  .row > *[class^=col-sm-10],
  .row > *[class^=col-md-10],
  .row > *[class^=col-lg-10] {
    width: 83.33333%; }
  .row > *[class^=col-xs-11],
  .row > *[class^=col-sm-11],
  .row > *[class^=col-md-11],
  .row > *[class^=col-lg-11] {
    width: 91.66667%; }
  .row > *[class^=col-xs-12],
  .row > *[class^=col-sm-12],
  .row > *[class^=col-md-12],
  .row > *[class^=col-lg-12] {
    width: 100%; }
  .mh {
    height: auto !important; }
  .pagetop {
    display: none !important; }
  .toggle_target {
    display: block !important; }
  .table-responsive .table tbody tr td,
  .table-responsive .table tbody tr th,
  .table-responsive .table tfoot tr td,
  .table-responsive .table tfoot tr th,
  .table-responsive .table thead tr td,
  .table-responsive .table thead tr th {
    white-space: normal; }
  .table-responsive .table tbody th,
  .table-responsive .table tfoot th,
  .table-responsive .table thead th {
    white-space: normal; } }
