// ---------------------------------------------
//  card
// ---------------------------------------------
.card {
	&_style1 {
		display: flex;
		flex-wrap: wrap;
		margin: -16px 0;
		@include media-breakpoint-up(md) {
			margin: -30px -15px;
		}
		&-item {
			padding: 16px 0;
			line-height: 1.5;
			@include media-breakpoint-up(md) {
				width: 33.3333%;
				padding: 30px 0;
			}
			&.-noarrow {
				.card_style1-image::after {
					content: none;
				}
			}
			&.-color1 {
				.card_style1-image::after {
					color: $color-sub1;
				}
				.card_style1-tag {
					background-color: $color-sub1;
				}
			}
			&.-color2 {
				.card_style1-image::after {
					color: $color-sub2;
				}
				.card_style1-tag {
					background-color: $color-sub2;
				}
			}
			&.-color3 {
				.card_style1-image::after {
					color: $color-sub3;
				}
				.card_style1-tag {
					background-color: $color-sub3;
				}
			}
			&.-color4 {
				.card_style1-image::after {
					color: $color-sub4;
				}
				.card_style1-tag {
					background-color: $color-sub4;
				}
			}
			&.-color5 {
				.card_style1-image::after {
					color: $color-sub5;
				}
				.card_style1-tag {
					background-color: $color-sub5;
				}
			}
		}
		&-link {
			display: block;
			@include media-breakpoint-up(md) {
				padding: 0 15px;
			}
			.card_style1-image::after {
				position: absolute;
				right: 0;
				bottom: 0;
				content: "\f061";
				display: flex;
				align-items: center;
				justify-content: center;
				width: 30px;
				height: 30px;
				background-color: $color-white;
				color: $color-main;
				font-size: 1.6rem;
				font-family: 'Line Awesome Free';
				font-weight: 900;
			}
		}
		&-inner {
			@include media-breakpoint-up(md) {
				padding: 0 15px;
			}
		}
		&-image {
			position: relative;
			margin-bottom: 1rem;
			img{
				width: 100%;
				height: auto;
				aspect-ratio: 3/2;
				object-fit: cover;
			}
		}
		&-video {
			margin-bottom: 1rem;
			iframe {
				width: 100%;
				height: auto;
				aspect-ratio: 16 / 9;
				vertical-align: bottom;
			}
		}
		&-title {
			margin-bottom: .2em;
			font-weight: bold;
		}
		&-name {
			margin-bottom: 0;
			font-weight: bold;
		}
		&-text {
			margin-bottom: 0;
		}
		&-date {
			font-size: 1.4rem;
		}
		&-tags {
			display: flex;
			flex-wrap: wrap;
			gap: 8px;
			margin-bottom: 8px;
		}
		&-tag {
			display: inline-block;
			padding: 7px 15px;
			background-color: $color-main;
			border: 1px solid transparent;
			color: $color-white;
			font-size: 1.5rem;
			font-weight: bold;
			line-height: 1;
			white-space: nowrap;
			&.-border {
				background-color: $color-white!important;
				border: 1px solid $color-main;
				color: $color-main;
			}
		}
		&.slider-col3 {
			opacity: 0;
			transition: opacity .3s;
			&.slick-initialized {
				opacity: 1;
			}
		}
		.slick-track {
			margin: 0 !important;
		}
		.slick-prev, .slick-next {
			top: 115px;
			width: 35px;
			height: 35px;
		}
		.slick-prev {
			left: -30px;
			@include media-breakpoint-down(sm) {
				left: -20px;
			}
		}
		.slick-next {
			right: -30px;
			@include media-breakpoint-down(sm) {
				right: -20px;
			}
		}
		.slick-prev:before, .slick-next:before {
			color: $color-main;
			font-size: 3.5rem;
			font-family: 'Line Awesome Free';
			font-weight: 900;
		}
		.slick-prev:before {
			content: "\f104";
		}
		.slick-next:before {
			content: "\f105";
		}
	}
}
