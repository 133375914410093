// ---------------------------------------------
//  Pagination
// --------------------------------------------
.pagination {
	border-radius: 0;
	justify-content: center;
	.page-link,.link_page,.current_page {
		min-width: 28px;
		padding: 0 5px;
		background-color: $color-white;
		border: 1px solid $color-black;
		color: $color-black;
		font-size: 1.4rem;
		font-family: $font-family-en;
		font-weight: bold;
		line-height: 26px;
		text-align: center;
		display: inline-block;
	}
	.current_page{
		background-color: $color-black;
		border: 1px solid $color-black;
		color: $color-white;
	}
	.page-prev,
	.page-next {
		position: relative;
		z-index: 0;
		.page-link {
			width: 20px;
			min-width: 20px;
			border: none;
    	font-size: 0px;
		}
		&::before {
			content: "";
			position: absolute;
			z-index: 1;
			left: 0;
			top: 10px;
			display: block;
			width: 7px;
			height: 7px;
			border-top: 2px solid $color-black;
			border-right: 2px solid $color-black;
			transform: rotate(45deg);
		}
	}
	.page-prev::before {
		left: auto;
		right: 0;
		transform: rotate(-135deg);
	}
	.page-item.active {
		.page-link {
			background-color: $color-black;
			border: 1px solid $color-black;
			color: $color-white;
		}
	}
	.link_last{
		font-weight: bold;
		margin-left: 5px;
	}
	.link_first{
		font-weight: bold;
		margin-right: 5px;
	}
}
