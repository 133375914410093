@charset 'utf-8';
.guideline {
	.item__color {
		display: flex;
		.color {
			display: flex;
			align-items: center;
			flex: 1 0 50%;
			justify-content: center;
			min-height: 2em;
			color: #fff;
			&.txt__left {
				justify-content: flex-start;
			}
		}
	}
	.clr_sub_1_l {
		background-color: $color-sub1-light;
	}
	.clr_sub_2_l {
		background-color: $color-sub2-light;
	}
	.clr_sub_3_l {
		background-color: $color-sub3-light;
	}
	.clr_sub_4_l {
		background-color: $color-sub4-light;
	}
	.grid_item {
		text-align: center;
		border: 1px #ccc solid;
		background-color: #eee;
	}
	.label_id {
		display: inline-block !important;
		padding: 2px 10px !important;
		color: #fff !important;
		background-color: $color-black !important;
		&.txt_id {
			background-color: #900 !important;
		}
	}
	pre {
		padding: 0;
		border: 0;
		border-radius: 0;
		background-color: #fff;
	}
	code {
		margin: 0;
		padding: 10px;
	}
}
