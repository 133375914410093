// ---------------------------------------------
//  Accodion
// ---------------------------------------------
.panel-group {
	border-bottom: 1px map-get($grays,'300') solid;
	.panel {
		border-radius: 0;
		border: 0;
		box-shadow: none;
		.panel-heading {
			padding: 0;
			background-color: transparent;
			border-top: 1px map-get($grays,'300') solid;
			border-radius: 0;
			.panel-title {
				position: relative;
				a {
					position: relative;
					display: block;
					padding: 14px 1.8em 14px 0;
					color: $color-black;
					text-decoration: none;
					.icon {
						display: flex;
						align-items: center;
						justify-content: center;
						position: absolute;
						top: 50%;
						right: 2px;
						margin-top: -.5em;
						width: 1.3em;
						height: 1.3em;
						line-height: 1;
						border-radius: 50%;
						background-color: $color-sub4;
						&::before {
							content: '\f107';
							font-family: 'FontAwesome';
							color: #fff;
							font-size: .9em;
						}
					}
				}
			}
		}
		.panel-body {
			padding: 10px;
			// border-top: 0 !important;
		}
	}
	.collapse_show {
		& > .panel-heading {
			& > .panel-title {
				& > a {
					color: $color-sub4;
					.icon {
						background-color: map-get($grays,'500');
						&::before {
							content: '\f106';
						}
					}
				}
			}
		}
	}
	//collapse_show
}
