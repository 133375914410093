@charset 'utf-8';
// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }

.home {
	.gheader {
		position: absolute !important;
		background-color: transparent !important;
	}

	&__lead {
		line-height: 1.88;
		font-weight: bold;
	}

	&__title {
		margin-bottom: .5em;
		font-size: 3vw;
		font-weight: bold;
		@include media-breakpoint-up(xl) {
			font-size: 4rem;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 3rem;
			font-size: 2.6rem;
		}
		&-en {
			position: absolute;
			top: 0;
			left: 50%;
			z-index: -1;
			transform: translateX(-50%);
			width: 100%;
			margin: 0;
			@include media-breakpoint-down(sm) {
				left: 0;
				transform: translate(0, -37%)
			}
			img {
				width: 100%;
			}
		}
	}

	&__button {
		position: relative;
		display: block;
		color: $color-white !important;
		font-size: 2vw;
		font-weight: bold;
		line-height: 1.4;
		text-align: left;
		@include media-breakpoint-up(xl) {
			font-size: 2.2rem;
		}
		@include media-breakpoint-down(sm) {
			font-size: 2rem;
		}
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
			content: "";
			display: block;
			width: 100%;
			height: 100%;
			box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.16), inset 3px 3px 6px 0 rgba(255, 255, 255, 0.16);
			border: solid 1px transparent;
			border-radius: 5px;
			mix-blend-mode: overlay;
		}
		&::after {
			position: absolute;
			right: 2.5vw;
			top: 50%;
			transform: translateY(-50%);
			transition: all .2s;
			content: "\f138";
			display: inline-block;
			color: inherit;
			font-size: 3.2vw;
			font-family: 'Line Awesome Free';
			font-weight: 900;
			@include media-breakpoint-up(xl) {
				right: 3rem;
				font-size: 3.5rem;
			}
			@include media-breakpoint-down(sm) {
				right: 1rem;
				font-size: 3.5rem;
			}
		}
		&-inner {
			position: relative;
			z-index: 2;
			display: block;
			padding: 2.2vw 6vw 2.2vw 3.3vw;
			@include media-breakpoint-up(xl) {
				padding: 2.5rem 7rem 2.5rem 3.6rem;
			}
			@include media-breakpoint-down(sm) {
				padding: 2.6rem 5rem 2.4rem 2rem;
			}
		}
		&:hover {
			opacity: 1;
			&::after {
				transform: translate(10px, -50%);
			}
		}

		&-list {
			margin-top: 4.5rem;
			&_item {
				margin-bottom: 1rem;

			}
		}
	}

	&__fv {
		position: relative;
		z-index: 1;
		padding: 3rem 0 0;
		background: #a0ebff url(../img/home/fv_bg.jpg) no-repeat left top / 100% auto;
		@include media-breakpoint-down(sm) {
			padding: 8rem 0 0;
			background-image: url(../img/home/fv_bg-sp.jpg);
		}

		&-title {
			max-width: 1400px;
			margin: 0 auto;
		}

		&-pict {
			position: absolute;
			margin: 0;
			pointer-events: none;
			@include media-breakpoint-down(sm) {
				display: none;
			}
			&.-pict1 {
				right: -94px;
				top: 50px;
				transform: translateY(-100%);
				width: 93px;
				@include media-breakpoint-down(md) {
					right: -20px;
					width: 80px;
				}
			}
			&.-pict2 {
				left: -116px;
				bottom: -86px;
				width: 163px;
				@include media-breakpoint-down(md) {
					left: -35px;
					width: 150px;
				}
			}
		}
	}

	&__news {
		position: relative;
		z-index: 2;
		padding: 0 4rem 7rem;
		@include media-breakpoint-down(md) {
			padding: 0 10rem 7rem;
		}
		@include media-breakpoint-down(sm) {
			padding: 0 0 6rem;
		}

		&-title {
			position: absolute;
			z-index: 1;
			left: 50%;
			top: -100px;
			transform: translateX(-50%);
			padding-top: 24px;
    	line-height: 1.4;
			text-align: center;
			@include media-breakpoint-down(sm) {
				top: -90px;
			}
			&::before {
				position: absolute;
				left: 50%;
				top: 0;
				z-index: -1;
				transform: translateX(-50%);
				content: "";
				display: block;
				width: 112px;
				height: 124px;
				background: url(../img/home/news_ttl_bg.svg) no-repeat center center / contain;
			}
			&_en {
				display: block;
				color: $color-main;
				font-size: 2.5rem;
				font-family: $font-family-en;
				font-weight: 900;
			}
			&_ja {
				display: block;
				font-size: 1.4rem;
			}
		}

		&-list {
			margin: 0;
			padding: 0;
			list-style: none;
			border-top: 3px solid $color-white;
			&_item {
				margin-top: 1rem;
				border-bottom: 3px solid $color-white;
				line-height: 1.5;
				@include media-breakpoint-down(sm) {
					margin: 0;
				}
			}
			&_link {
				position: relative;
				display: flex;
				padding: 1.2rem 10% 1.2rem 5%;
				@include media-breakpoint-down(sm) {
					display: block;
					padding: 1.5rem 2.5rem 1.8rem 0;
				}
				&::before {
					position: absolute;
					right: 5%;
					top: 50%;
					transform: translateY(-50%);
					content: "\f138";
					display: inline-block;
					color: $color-main;
					font-size: 2rem;
					font-family: 'Line Awesome Free';
					font-weight: 900;
					@include media-breakpoint-down(sm) {
						right: 0;
						top: 50%;
						transform: translate(0, -50%);
					}
				}
			}
			&_date {
				display: block;
				width: 115px;
				color: $color-main;
				font-family: $font-family-en;
				font-weight: 900;
				@include media-breakpoint-down(sm) {
					width: 100%;
					margin-bottom: .5rem;
				}
			}
			&_title {
				flex: 1 0 0%;
			}
		}
	}

	&__pickup {
		position: relative;
		z-index: 3;
		background-color: $color-white;

		&-wrapper {
			position: relative;
			padding: 8rem 0 20rem;
			@include media-breakpoint-down(sm) {
				padding: 7rem 0 16.5rem;
			}
		}

		&-title {
			position: relative;
			padding-bottom: 3.4rem;
			margin-bottom: 2rem;
			font-size: 2.4rem;
			font-family: $font-family-en;
			font-weight: 900;
			line-height: 1.2;
			text-align: center;
			&::after {
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				content: "";
				display: inline-block;
				width: 1px;
				height: 25px;
				background-color: $color-main;
			}
		}

		&-list {
			.slick-track {
				margin: 0 !important;
			}
			.slick-prev, .slick-next {
				top: 145px;
				width: 35px;
				height: 35px;
			}
			.slick-prev {
				left: -20px;
			}
			.slick-next {
				right: -20px;
			}
			.slick-prev:before, .slick-next:before {
				color: $color-main;
				font-size: 3.5rem;
				font-family: 'Line Awesome Free';
				font-weight: 900;
			}
			.slick-prev:before {
				content: "\f104";
			}
			.slick-next:before {
				content: "\f105";
			}
			&_item {
				margin: 0 10px;
				padding-bottom: 20px;
			}
			&_link {
				position: relative;
				display: block;
				height: 100%;
				padding: 2rem 2rem 5rem;
				background-color: $color-white;
				border-radius: 5px;
				box-shadow: 5px 5px 15px 0 rgba(28, 69, 110, 0.2);
				&::after {
					position: absolute;
					right: 1rem;
					bottom: 1rem;
					content: "\f35d";
					display: flex;
					align-items: center;
					justify-content: center;
					width: 36px;
					height: 36px;
					background-color: $color-main;
					border-radius: 50%;
					color: $color-white;
					font-size: 2rem;
					font-family: 'Line Awesome Free';
					font-weight: 900;
				}
			}
			&_image {
				margin-bottom: 1.5rem;
			}
			&_text {
				font-weight: bold;
				line-height: 1.7;
			}
		}

		&-pict {
			position: absolute;
			margin: 0;
			pointer-events: none;
			&.-pict1 {
				right: -200px;
				top: -157px;
				width: 206px;
				@include media-breakpoint-down(md) {
					right: -60px;
					width: 160px;
				}
				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
			&.-pict2 {
				left: -145px;
				bottom: -320px;
				width: 341px;
				@include media-breakpoint-down(md) {
					left: -40px;
					width: 330px;
				}
				@include media-breakpoint-down(sm) {
					left: 50%;
					bottom: -180px;
					transform: translateX(-46%);
					width: 200px;
				}
			}
		}
	}

	&__about {
		position: relative;
		z-index: 4;
		color: $color-white;

		&-bg {
			height: 94vw;
			max-height: 1300px;
			min-height: 1000px;
			background-color: $color-main;
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 17.5vw));
			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				max-height: initial;
				min-height: initial;
			}
			&::before, &::after {
				position: absolute;
				left: 0;
				z-index: -1;
				content: "";
				display: block;
				width: 100%;
				opacity: 0.5;
				mix-blend-mode: multiply;
			}
			&::before {
				top: 0;
				height: 440px;
				background-image: linear-gradient(to top, #fff 47%, #1c456e);
			}
			&::after {
				bottom: 0;
				height: 370px;
				background-image: linear-gradient(185deg, #fff 28%, #acc0cc);
			}
		}

		&-inner {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 2;
			width: 100%;
			@include media-breakpoint-down(sm) {
				transform: translateY(0);
				position: static;
			}
		}

		&-title {
			position: relative;
			margin-bottom: 1em;
			padding-top: 13rem;
			line-height: 1.2;
			text-align: center;
			@include media-breakpoint-down(sm) {
				padding-top: 6.5rem;
			}
			&::before {
				position: absolute;
				left: 50%;
				top: 0;
				transform: translateX(-50%);
				content: "";
				display: inline-block;
				width: 3px;
				height: 120px;
				background-color: $color-white;
				@include media-breakpoint-down(sm) {
					width: 1px;
					height: 60px;
				}
			}
			&_en {
				display: block;
				margin-bottom: 5rem;
				font-size: 5rem;
				font-family: $font-family-en;
				font-weight: 900;
				@include media-breakpoint-down(sm) {
					margin-bottom: 3rem;
					font-size: 4rem;
				}
			}
			&_ja {
				display: block;
				font-size: 3.6rem;
				@include media-breakpoint-down(sm) {
					font-size: 2.6rem;
				}
			}
		}

		&-content {
			@include media-breakpoint-up(md) {
				padding-left: 55%;
			}
		}

		&-lead {
			margin-bottom: 8rem;
			@include media-breakpoint-down(sm) {
				margin-bottom: 5rem;
			}
		}

		&-image {
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 50%;
				bottom: -1%;
				z-index: 1;
				transform: translateX(-90%);
				width: 50.3%;
				max-width: 704px;
			}
			@include media-breakpoint-down(sm) {
				margin: 6rem -2vw -36vw 2rem;
				text-align: center;
			}
		}

		.home__button {
			background-color: $color-main;
			&::before {
				background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.25), rgba(255, 255, 255, 0.25));
				@include media-breakpoint-down(sm) {
					background-image: linear-gradient(103deg, rgba(165, 234, 255, 0.25), rgba(255, 255, 255, 0.25));
				}
			}
			&:hover::before {
  			background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.5), rgba(255, 255, 255, 0.5));
			}
		}

		&-sakuma {
			margin-top: 5vw;
			@include media-breakpoint-up(xl) {
				margin-top: 7rem;
			}
			@include media-breakpoint-down(sm) {
				margin-top: 2rem;
			}
			&_link {
				display: flex;
				flex-direction: row-reverse;
				align-items: flex-end;
				justify-content: center;
				&:hover {
					opacity: 1;
					.home__about-sakuma_image::before {
						opacity: 0;
						visibility: hidden;
					}
					.home__about-sakuma_image::after {
						opacity: 1;
						visibility: visible;
					}
				}
			}
			&_text {
				position: relative;
				display: inline-block;
				width: 250px;
				margin: 0;
				padding: 2rem .5rem 2rem 2rem;
				background-color: $color-main2;
				border-radius: 10px;
				font-size: 1.8rem;
				font-weight: bold;
				line-height: 1.7;
				@include media-breakpoint-down(sm) {
					width: 240px;
					font-size: 1.6rem;
				}
				&::before {
					position: absolute;
					left: 1px;
					top: 50%;
					transform: translate(-100%, -50%);
					content: "";
					display: inline-block;
					width:0;
					height:0;
					border-style:solid;
					border-width: 10px 10px 10px 0;
					border-color: transparent $color-main2 transparent transparent;
				}
			}
			&_image {
				position: relative;
				width: 68px;
				height: 123px;
				margin: 0 3rem .5rem 0;
				@include media-breakpoint-down(sm) {
					width: 64px;
				}
				&::before, &::after {
					position: absolute;
					left: 0;
					top: 0;
					transition: all .3s;
					content: "";
					display: block;
					width: 100%;
					height: 100%;
				}
				&::before {
					background: url(../img/home/about_sakumasan.svg) no-repeat center center / contain;
				}
				&::after {
					opacity: 0;
					visibility: hidden;
					background: url(../img/home/about_sakumasan_on.svg) no-repeat center center / contain;
				}
			}
		}
	}

	&__learning {
		position: relative;
		z-index: 5;
    margin-top: -17.5vw;
		color: $color-white;

		&-bg {
			height: 78.5vw;
			max-height: 1100px;
			min-height: 800px;
			background-image: linear-gradient(60deg, #c6b5f4 0%, #849ae8 67%);
			clip-path: polygon(0 0, 100% 17.5vw, 100% calc(100% - 17.5vw), 0 100%);
			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				max-height: initial;
				min-height: initial;
			}
			&::after {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: -1;
				content: "";
				display: block;
				width: 100%;
				height: 585px;
				opacity: 0.5;
				mix-blend-mode: multiply;
				background-image: linear-gradient(175deg, #fff 28%, #dfd1ff);
			}
		}

		&-inner {
			position: absolute;
			right: 0;
			top: 12vw;
			z-index: 2;
			width: 100%;
			@include media-breakpoint-down(sm) {
				transform: translateY(0);
				position: static;
				padding: 10rem 0 0;
			}
		}

		&-content {
			@include media-breakpoint-up(md) {
				padding-right: 55%;
			}
		}

		&-image {
			@include media-breakpoint-up(md) {
				position: absolute;
				right: 50%;
				bottom: -15%;
				z-index: 1;
				transform: translateX(50vw);
				width: 80%;
				max-width: 1130px;
			}
			@include media-breakpoint-down(sm) {
				margin: 2rem -4vw -30vw 2rem;
				text-align: center;
			}
		}

		.home__button {
			&::before {
				background-image: linear-gradient(99deg, rgba(165, 196, 255, 0.25), rgba(255, 255, 255, 0.25));
			}
			&:hover::before {
  			background-image: linear-gradient(99deg, rgba(165, 189, 255, 0.5), rgba(255, 255, 255, 0.5));
			}
		}
	}

	&__campuslife {
		position: relative;
		z-index: 6;
    margin-top: -17.5vw;
		color: $color-main;

		&-bg {
			height: 88vw;
			max-height: 1360px;
			min-height: 900px;
			background-image: linear-gradient(140deg, #a0ebff 29%, #8afaed 109%);
			clip-path: polygon(0 17.5vw, 100% 0, 100% 100%, 0 calc(100% - 17.5vw));
			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				max-height: initial;
				min-height: initial;
			}
			&::after {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: -1;
				content: "";
				display: block;
				width: 100%;
				height: 384px;
				opacity: 0.5;
				mix-blend-mode: multiply;
				background-image: linear-gradient(185deg, #fff 28%, #c6e8ed);
			}
		}

		&-inner {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			width: 100%;
			@include media-breakpoint-down(sm) {
				transform: translateY(0);
				position: static;
				padding: 10rem 0 0;
			}
		}

		&-content {
			@include media-breakpoint-up(md) {
				padding-left: 55%;
			}
		}

		&-image {
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 50%;
				bottom: 6vw;
				z-index: 1;
				transform: translateX(-82%);
				width: 62%;
				max-width: 868px;
			}
			@include media-breakpoint-down(sm) {
				margin: 2rem 0 -30vw 2rem;
				text-align: center;
			}
		}

		.home__button {
			color: $color-main !important;
			&::before {
				background-image: linear-gradient(279deg, #e2fcff 99%, rgba(255, 255, 255, 0.8));
				border-color: $color-white;
				box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.2), inset 3px 3px 6px 0 rgba(255, 255, 255, 0.16);
			}
			&:hover::before {
  			background-image: linear-gradient(279deg, #e5fcff 99%, #fff);
			}
		}
	}

	&__experience {
		position: relative;
		z-index: 7;
    margin-top: -17.5vw;
		color: $color-main;

		&-bg {
			height: 90vw;
			max-height: 1400px;
			min-height: 800px;
			background-color: $color-white;
			clip-path: polygon(0 0, 100% 17.5vw, 100% calc(100% - 17.5vw), 0 100%);
			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				max-height: initial;
				min-height: initial;
			}
		}

		&-inner {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			width: 100%;
			@include media-breakpoint-down(sm) {
				transform: translateY(0);
				position: static;
				padding: 10rem 0 0;
			}
		}

		&-content {
			@include media-breakpoint-up(md) {
				padding-right: 55%;
			}
		}

		&-image {
			@include media-breakpoint-up(md) {
				position: absolute;
				right: 50%;
				bottom: 13.5vw;
				z-index: 1;
				transform: translateX(100%);
				width: 51.4%;
				max-width: 718px;
			}
			@include media-breakpoint-down(sm) {
				margin: 6rem 0 24vw .4rem;
				text-align: center;
			}
		}

		.home__button {
			color: $color-main !important;
			&::before {
  			background-image: linear-gradient(279deg, #fcffff 99%, #fff);
				box-shadow: 3px 3px 6px 0 rgba(28, 69, 110, 0.2), inset 3px 3px 6px 0 rgba(255, 255, 255, 0.16);
				mix-blend-mode: normal;
			}
			&:hover::before {
  			background-image: linear-gradient(279deg, rgba(252, 252, 255, 0.8) 99%, rgba(255, 255, 255, 0.9));
			}
		}
	}

	&__career {
		position: relative;
		z-index: 8;
    margin-top: -17.5vw;
		color: $color-white;

		&-bg {
			height: 90vw;
			max-height: 1300px;
			min-height: 800px;
			background-image: linear-gradient(145deg, #003c87 23%, #5513af 80%);
			clip-path: polygon(0 17.5vw, 100% 0, 100% 100%, 0 calc(100% - 17.5vw));
			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				max-height: initial;
				min-height: initial;
				background-image: linear-gradient(168deg, #003c87 9%, #5513af 75%);
			}
			&::after {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: -1;
				content: "";
				display: block;
				width: 100%;
				height: 394px;
				opacity: 0.5;
				mix-blend-mode: multiply;
				background-image: linear-gradient(175deg, #fff 28%, #a9bcc9);
			}
		}

		&-inner {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 2;
			width: 100%;
			@include media-breakpoint-down(sm) {
				transform: translateY(0);
				position: static;
				padding: 10rem 0 0;
			}
		}

		&-content {
			@include media-breakpoint-up(md) {
				padding-left: 55%;
			}
		}

		&-image {
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 50%;
				bottom: 0;
				z-index: 1;
				transform: translateX(-100%);
				width: 47.7%;
				max-width: 668px;
			}
			@include media-breakpoint-down(sm) {
				margin: 6rem -2vw -36vw 2rem;
				text-align: center;
			}
		}

		.home__button {
			background-image: linear-gradient(145deg, #3621A0 23%, #4B18AA 80%);
			@include media-breakpoint-down(sm) {
				background-image: linear-gradient(145deg, #2C289C 23%, #3D1FA3 80%);
			}
			&::before {
  			background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.25), rgba(255, 255, 255, 0.25));
			}
			&:hover::before {
  			background-image: linear-gradient(99deg, rgba(165, 234, 255, 0.5), rgba(255, 255, 255, 0.5));
			}
		}
	}

	&__admissions {
		position: relative;
		z-index: 9;
    margin-top: -17.5vw;
		color: $color-white;

		&-bg {
			height: 70vw;
			max-height: 1000px;
			min-height: 700px;
			background-color: #849ae8;
			clip-path: polygon(0 0, 100% 17.5vw, 100% 100%, 0 100%);
			@include media-breakpoint-down(sm) {
				position: absolute;
				left: 0;
				top: 0;
				z-index: -1;
				width: 100%;
				height: 100%;
				max-height: initial;
				min-height: initial;
			}
			&::after {
				position: absolute;
				left: 0;
				bottom: 0;
				z-index: -1;
				content: "";
				display: block;
				width: 100%;
				height: 105px;
				opacity: 0.5;
				mix-blend-mode: multiply;
				background-image: linear-gradient(to bottom, #fff, #4d8ac7);
			}
		}

		&-inner {
			position: absolute;
			right: 0;
			top: 12vw;
			z-index: 2;
			width: 100%;
			@include media-breakpoint-down(sm) {
				transform: translateY(0);
				position: static;
				padding: 10rem 0 0;
			}
		}

		&-content {
			@include media-breakpoint-up(md) {
				padding-right: 55%;
			}
		}

		&-image {
			@include media-breakpoint-up(md) {
				position: absolute;
				right: 50%;
				bottom: -160px;
				z-index: 1;
				transform: translateX(63%);
				width: 96%;
				max-width: 1342px;
			}
			@include media-breakpoint-down(sm) {
				margin: 2rem 0 0;
				text-align: center;
			}
		}

		.home__button {
			&::before {
  			background-image: linear-gradient(99deg, rgba(165, 196, 255, 0.25), rgba(255, 255, 255, 0.25));
			}
			&:hover::before {
  			background-image: linear-gradient(99deg, rgba(165, 189, 255, 0.5), rgba(255, 255, 255, 0.5));
			}
		}

	}
}
